import React from 'react';
import styled from 'styled-components';

import { BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';

import corporateIcon from '../../images/onboarding/corporate-icon.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
`;

const SubscriptionIcon = styled.img`
  margin-top: 1.375rem;
  width: 4.625rem;
`;

const BodyText = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-top: ${props => (props.noMargin ? '0rem' : '1.625rem')};
`;

const PoweredText = styled(BoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  opacity: 0.5;
  margin-top: 2rem;
`;

const SponsorLogo = styled.img`
  margin-top: 1rem;
  max-width: 10rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1.5rem;
`;

const ButtonText = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
  text-transform: uppercase;
`;

const CorporateBenefitsActivated = ({ location, history }) => {
  const { state } = location;
  return (
    <FadeInTransition in={true} timeout={200} mountOnEnter unmountOnExit appear>
      <OuterContainer>
        <SubscriptionIcon src={corporateIcon} />
        <BodyText>Organization Benefits Activated</BodyText>
        <BodyText noMargin>Successfully!</BodyText>
        <PoweredText>Benefits are sponsored by</PoweredText>
        <SponsorLogo src={state.sponsorImage} />
        <StyledButton onTap={() => history.push('/one-last-step')}>
          <ButtonText>continue</ButtonText>
        </StyledButton>
      </OuterContainer>
    </FadeInTransition>
  );
};

export default CorporateBenefitsActivated;
