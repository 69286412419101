import React from 'react';
import { useState } from 'react';

const Clipboard = ({ code, onClick }) => {
  const [copied, setCopied] = useState(false);

  return (
    <div className="w-full flex justify-between p-10px px-16px border-dotted border-2 rounded-full border-sub text-sub text-body-l">
      <p className=" font-semibold">
        CODE: <span className="ml-2px">{code}</span>
      </p>
      <button
        onClick={() => {
          navigator.clipboard.writeText(code);
          onClick();
          setCopied(true);
          setTimeout(() => setCopied(false), 5000);
        }}
        className="text-primary font-bold"
      >
        {copied ? <span className="text-sub">COPIED</span> : 'COPY'}
      </button>
    </div>
  );
};

export default Clipboard;
