import React, { useRef, useState, useEffect } from 'react';
// import { Replay } from 'vimond-replay';
// import 'vimond-replay/index.css';
// import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
import styled from 'styled-components';
// import arrowBack from '../../images/arrow_back.svg';
// import { sendActivityTracking } from '../../services/health-content';
import ReactHlsPlayer from '@gumlet/react-hls-player';

const OuterDiv = styled.div`
  background-image: url(${(props) => props.image});
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  display: flex;
  width: 440px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px 12px 0 0;
  @media (max-width: 440px) {
    width: 100%;
    background-size: cover;
  }
`;

// const HeaderNew = styled.button`
//   padding: 2px;
//   display: flex;
//   background: white;
//   align-self: stretch;
//   align-items: center;
//   background: #413958;
//   opacity: 0.5;
//   border-radius: 8px;
// `;

// const CardTitle = styled.div`
//   font-weight: 700;
//   font-size: 14px;
//   line-height: 17px;
//   display: flex;
//   align-items: center;
//   color: #ffffff;
// `;

// const CardDescription = styled.div`
//   font-weight: 600;
//   font-size: 10px;
//   line-height: 12px;
//   display: flex;
//   align-items: center;
//   color: #e6e6e6;
//   margin-top: 2px;
// `;

export default function FitnessPlayer({ album, url, logTime = () => {} }) {
  const playerRef = useRef();

  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     audioLoading: false,
  //     duration: 0,
  //   };
  //   this.currentTime = React.createRef();
  //   this.currentTime.current = 0;
  // }

  // componentDidMount() {
  //   // this.trackAcitivity();
  //   // this.timer = setInterval(() => {
  //   //   if (!this.state.audioLoading) {
  //   //     return this.trackAcitivity();
  //   //   }
  //   // }, 10000);
  //   this.playerRef = React.createRef();
  // }

  // componentWillUnmount() {
  //   clearInterval(this.timer);
  // }

  //   trackAcitivity = () => {
  //     sendActivityTracking(
  //       this.props.authToken,
  //       this.props.album.albumsSessionsId,
  //       this.currentTime.current
  //     )
  //       .then((res) => {
  //         console.log('tracking', res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  // return {
  // const  = this.props;
  // let position = 0;

  // function playVideo() {
  //   playerRef.current.play();
  // }

  // function pauseVideo() {
  //   playerRef.current.pause();
  // }

  // function toggleControls() {
  //   playerRef.current.controls = !playerRef.current.controls;
  // }

  useEffect(() => {
    function onVideoPause() {
      logTime && logTime(duration, position);
    }

    playerRef.current.addEventListener('pause', onVideoPause);

    return playerRef.current.removeEventListener('pause', onVideoPause);
  }, []);

  return (
    <OuterDiv className="cursor-pointer" onClick={(e) => e.stopPropagation()}>
      {/* <div>
          <div
            style={{ position: 'absolute', left: '5%', top: '5%', zIndex: 999 }}
            onClick={() => this.props.hideModal()}
          >
            <HeaderNew>
              <img src={arrowBack} style={{ width: '36px' }} />
            </HeaderNew>
          </div>
        </div> */}
      {/* <div>
          <img
            src={album.albumSponsorUrl || albumSponsorUrl}
            width={'128px'}
            height={'24px'}
          />
        </div>
        <div className="flex flex-col items-center pt-24px">
          <CardTitle>{album.albumTitle || album.title}</CardTitle>
          <CardDescription>{album.coachName}</CardDescription>
        </div> */}
      <div className="" style={{ width: '100%' }}>
        <ReactHlsPlayer
          src={url ?? album?.videoUrl}
          autoPlay={true}
          controls={true}
          width="100%"
          playerRef={playerRef}
        />
        {/* <Replay
            source={(url ?? album?.videoUrl) + '?playsinline=1'}
            initialPlaybackProps={{ isPaused: false, isPipAvailable: false }}
            onError={(err) => {
              console.log(err);
            }}
            onPlaybackActionsReady={(e) => console.log(e)}
            onStreamStateChange={(props) => {
              console.log(props);
              if (props.duration) {
                this.setState({ duration: props.duration });
              } else if (props.position) position = props.position;
              if (
                (props.playState === 'paused' ||
                  props.playState === 'inactive') &&
                this.props.logTime
              )
                this.props.logTime(this.state.duration, position);
            }}
          >
            <HlsjsVideoStreamer />
          </Replay> */}
      </div>
    </OuterDiv>
  );
  // }
}
