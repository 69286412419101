import { Component } from 'react';
import styled from 'styled-components';
import D100ML from '../../images/water-log/disable-icons/100_ml.svg';
import D200ML from '../../images/water-log/disable-icons/200_ml.svg';
import D250ML from '../../images/water-log/disable-icons/250_ml.svg';
import D300ML from '../../images/water-log/disable-icons/300_ml.svg';
import D400ML from '../../images/water-log/disable-icons/400_ml.svg';
import D500ML from '../../images/water-log/disable-icons/500_ml.svg';

import E100ML from '../../images/water-log/enable-icons/100_ml_e.svg';
import E200ML from '../../images/water-log/enable-icons/200_ml_e.svg';
import E250ML from '../../images/water-log/enable-icons/250_ml_e.svg';
import E300ML from '../../images/water-log/enable-icons/300_ml_e.svg';
import E400ML from '../../images/water-log/enable-icons/400_ml_e.svg';
import E500ML from '../../images/water-log/enable-icons/500_ml_e.svg';
import { updateDefaults } from '../../services/water-log';

const ModalBoundary = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 8px;
  padding: 18px;
`;

const GoalLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  width: 100%;
`;

const RowTags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 12px;
  width: 100%;
`;

const Image = styled.img`
  diplay: flex;
  width: 64px;
  height: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: scale-down;
`;

const Tags = styled.p`
  text-align: center;
  font-size: 14px;
`;

const Recommended = styled.p`
  font-size: 12px;
  font-weight: 600;
`;

const AddButton = styled.button`
  margin-top: 10px;
  width: 90%;
  padding: 14px;
  color: #ffffff;
  border-radius: 8px;
`;

const BreakPointContainer = styled.div`
  position: absolute;
  width: 96%;
  top: 20px;
  margin-left: 2%;
  margin-right: 2%;
  height: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const BigBreak = styled.div`
  height: 8px;
  border-left: 2px solid #0095df;
`;

const SmallBreak = styled.div`
  height: 4px;
  border-left: 2px solid #0095df;
`;

const LitersContainer = styled.div`
  display: flex;
  margin-top: 18px;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  height: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LiterItems = styled.div`
  font-weight: 600px;
  font-size: 18px;
`;

export default class EditWaterGoal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVessel: this.props.vesselSize,
      goal: parseInt(this.props.goalLabel.charAt(0)),
      vesselKey: this.props.selectedVesselKey,
      defaultValue: this.setDefault(parseInt(this.props.goalLabel.charAt(0))),
    };
    console.log(this.state.defaultValue);
  }
  //   componentDidMount() {
  //     this.setDefault();
  //   }
  updateDefaults = () => {
    updateDefaults(
      this.props.authToken,
      this.state.goal,
      this.state.vesselKey
    ).then((res) => this.props.hideModalWithUpdate());
  };

  updateGoal = (value) => {
    switch (value) {
      case 0:
        return this.setState({ goal: 2 });
      case 1:
        return this.setState({ goal: 2.5 });
      case 2:
        return this.setState({ goal: 3 });
      case 3:
        return this.setState({ goal: 3.5 });
      case 4:
        return this.setState({ goal: 4 });
      case 5:
        return this.setState({ goal: 4.5 });
      case 6:
        return this.setState({ goal: 5 });
      case 7:
        return this.setState({ goal: 5.5 });
      case 8:
        return this.setState({ goal: 6 });
    }
  };

  setDefault = (value) => {
    switch (value) {
      case 2:
        return '0';
      case 2.5:
        return '12.5';
      case 3:
        return '25';
      case 3.5:
        return '37.5';
      case 4:
        return '50';
      case 4.5:
        return '62.5';
      case 5:
        return '75';
      case 5.5:
        return '87.5';
      case 6:
        return '100';
    }
  };

  render() {
    console.log(this.state.defaultValue);
    return (
      <ModalBoundary>
        <HeaderContainer>
          <Title>Set Goal</Title>
          <GoalLabel className="text-primary">
            <b className="mr-2px text-primary">{this.state.goal}</b>liters
          </GoalLabel>
          <div
            style={{
              width: '100%',
              marginTop: '12px',
              marginBottom: '12px',
              position: 'relative',
            }}
          >
            <div>
              <input
                type="range"
                className="slider w-full"
                onChange={(e) => this.updateGoal(e.target.value / 12.5)}
                step="12.5"
                defaultValue={this.state.defaultValue}
              />
            </div>
            <BreakPointContainer>
              <BigBreak />
              <SmallBreak />
              <BigBreak />
              <SmallBreak />
              <BigBreak />
              <SmallBreak />
              <BigBreak />
              <SmallBreak />
              <BigBreak />
            </BreakPointContainer>
            <LitersContainer>
              <LiterItems style={{ marginLeft: '2%' }}>2L</LiterItems>
              <LiterItems>3L</LiterItems>
              <LiterItems>4L</LiterItems>
              <LiterItems>5L</LiterItems>
              <LiterItems style={{ marginRight: '2%' }}>6L</LiterItems>
            </LitersContainer>
            <Recommended style={{ marginTop: '12px' }}>Recommended</Recommended>
          </div>
        </HeaderContainer>
        <InnerBoundary>
          <GoalLabel>Choose Your Vessel</GoalLabel>
          <Row>
            <RowTags
              onClick={() =>
                this.setState({ selectedVessel: 200, vesselKey: 'coffee-cup' })
              }
            >
              <Image
                src={this.state.selectedVessel === 200 ? E100ML : D100ML}
              />
              <Tags>200ml</Tags>
            </RowTags>
            <RowTags
              onClick={() =>
                this.setState({ selectedVessel: 250, vesselKey: 'water-glass' })
              }
            >
              <Image
                src={this.state.selectedVessel === 250 ? E200ML : D200ML}
              />
              <Tags>250ml</Tags>
            </RowTags>
            <RowTags
              style={{ marginTop: '-15px' }}
              onClick={() =>
                this.setState({
                  selectedVessel: 300,
                  vesselKey: 'water-glass-1',
                })
              }
            >
              <Recommended>Recommended</Recommended>
              <Image
                src={this.state.selectedVessel === 300 ? E250ML : D250ML}
              />
              <Tags>300ml</Tags>
            </RowTags>
          </Row>
          <Row>
            <RowTags
              onClick={() =>
                this.setState({ selectedVessel: 350, vesselKey: 'tea-cup' })
              }
            >
              <Image
                src={this.state.selectedVessel === 350 ? E300ML : D300ML}
              />
              <Tags>350ml</Tags>
            </RowTags>
            <RowTags
              onClick={() =>
                this.setState({ selectedVessel: 400, vesselKey: 'beer' })
              }
            >
              <Image
                src={this.state.selectedVessel === 400 ? E400ML : D400ML}
              />
              <Tags className="pr-8px">400ml</Tags>
            </RowTags>
            <RowTags
              onClick={() =>
                this.setState({
                  selectedVessel: 500,
                  vesselKey: 'water-bottle',
                })
              }
            >
              <Image
                src={this.state.selectedVessel === 500 ? E500ML : D500ML}
              />
              <Tags>500ml</Tags>
            </RowTags>
          </Row>
          <AddButton className="bg-primary" onClick={this.updateDefaults}>
            Add
          </AddButton>
        </InnerBoundary>
      </ModalBoundary>
    );
  }
}
