import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { BalanceBadge } from './Fitcoin';
import { BoldText, LinkText } from './Text';
import { PrimaryButton } from './Buttons';
import { getMemberBalanceForTataUser } from '../../services/tata-aig';
import Tappable from 'react-tappable/lib/Tappable';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 0.625rem;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderText = styled(BoldText)`
  font-size: 1.125rem;
`;

const HeaderButton = styled(PrimaryButton)`
  background: none;
  padding: 0.25rem 1rem;
  border: 1px solid #303e4f;
`;

const ButtonText = styled(LinkText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const BackContainer = styled(Tappable)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.Tappable-active {
    opacity: 0.2;
  }
  padding: 0.5rem 1rem;
  margin-top: -0.25rem;
  margin-left: -1rem;
`;

const BackArrow = () => {
  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2.35117L2.67342 6.96667L7 11.5822L5.66802 13L1.49449e-06 6.96667L5.66802 0.933335L7 2.35117Z"
        fill="#0F0B28"
      />
    </svg>
  );
};

const RewardsHeader = ({ balance, history }) => {
  const [tataAigBalance, setTataAigBalance] = useState(0);

  const { memberId } = useSelector((state) => state.user);

  async function getTataAigBalance() {
    try {
      const { points } = await getMemberBalanceForTataUser(memberId);
      setTataAigBalance(points);
    } catch (error) {
      console.error(error);
      setTataAigBalance(0);
    }
  }

  function navigateBack() {
    if (window?.Android?.closeView) {
      window.Android.closeView(true);
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'closeView',
          tataUser: true,
        })
      );
    } else {
      history.goBack();
    }
  }

  useEffect(() => {
    getTataAigBalance();
  }, []);

  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <BackContainer onTap={navigateBack}>
          <BackArrow />
        </BackContainer>
        <BalanceBadge balance={tataAigBalance} />
      </HeaderTitleContainer>
      <HeaderButton onTap={() => history.push('/rewards/history')}>
        <ButtonText>My purchases</ButtonText>
      </HeaderButton>
    </HeaderContainer>
  );
};

RewardsHeader.propTypes = {
  balance: PropTypes.string.isRequired,
};

export default RewardsHeader;
