import React, { useEffect, useState } from 'react';
import ErrorModal from '../../containers/common/ErrorModal';
import styled from 'styled-components';
import { BoldText, RegularText } from '../../components/common/Text';
import errorModalBg from '../../images/common/error-modal-bg.svg';
import { PrimaryButton } from '../../components/common/Buttons';
import { validateKLIUserId } from '../../services';
import { useLocation } from 'react-router-dom';
import Tappable from 'react-tappable/lib/Tappable';

const SecondaryTappable = styled(Tappable)`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px 28px;
  color: #68bfc9 !important;
  border: 1px solid rgba(230, 233, 236, 1);
  background: white;
  &.Tappable-active {
    opacity: 0.2;
  }
`;
const NewButtonText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  padding: 6px;
  color: #68bfc9;
  text-align: center;
`;

const ModalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.7);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 335px;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 12px;
  margin: 0 1rem;
  text-align: center;
  position: relative;
`;
const ModalIcon = styled.img`
  width: 5.7rem;
`;
const ModalText = styled(RegularText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #302e6b;
`;

const ModalTitle = styled(BoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  line-height: 20px;
  color: #131836;
  margin: 0.9375rem 0rem 0.375rem;
`;

const ButtonText = styled(RegularText)`
  font-size: 16px;
  color: white;
  font-weight: 600;
`;
const CloseButton = styled.button`
  position: absolute;
  right: 20px;
`;

export default function SurveyModal({
  policyDetailsModal,
  hideModal,
  modalHeading,
  modalTitle,
  buttonText,
  modalImg,
  aTag,
  onClose,
}) {
  return (
    <ErrorModal>
      <ModalContainer>
        <ModalBody>
          <CloseButton onClick={onClose} type="button">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5859 12L12 10.5859L7.41407 5.99998L12 1.41405L10.5859 -6.18103e-08L6.00002 4.58593L1.41405 -4.62726e-07L-6.18103e-08 1.41405L4.58593 5.99998L-4.62725e-07 10.5859L1.41405 12L6.00002 7.41407L10.5859 12Z"
                fill="#585969"
              />
            </svg>
          </CloseButton>
          <div
            style={{
              backgroundImage: `url(${errorModalBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '114px 114px',
              backgroundPosition: 'center center',
              textAlign: 'center',
            }}
          >
            <ModalIcon
              src={modalImg}
              style={{ width: '164px', height: '116px' }}
            />
          </div>
          <ModalTitle>{modalHeading}</ModalTitle>
          <ModalText>{modalTitle}</ModalText>
          <PrimaryButton
            className={`mt-24px ${aTag ? 'mb-20px' : ''} w-full`}
            onTap={hideModal}
            //color={'#714FFF'}
          >
            <ButtonText className="text-[14px]">{buttonText}</ButtonText>
          </PrimaryButton>
        </ModalBody>
      </ModalContainer>
    </ErrorModal>
  );
}
