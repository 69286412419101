import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import { login, setFitBitConnected, setFitcoinsBalance } from '../../actions/index';
import { BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';

import contactGraphic from '../../images/onboarding/contact.svg';
import { logEvent } from '../../utils/logEvent';
import { userInfo } from '../../services';
import config from '../../config';
import { getMemberBalanceForTataUser } from '../../services/tata-aig';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  word-break: break-all;
`;

const InputContainer = styled.div`
  padding: 0.33rem 0;
  border-bottom: 1px solid black;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const CountryCodeText = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 1.625rem;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
`;

class OnboardingStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneValue: '',
      loading: false,
      phonePeToken: '',
    };
  }

  componentDidMount = () => {
    const { history, loginState, service } = this.props;
    console.log(loginState);
    console.log(this.props.location.state);
    if (loginState === 'LOGGED_IN') {
      if (service) {
        this.updateApiBaseUrl();
        this.getBalanceForMemberId();
        history.push(service);
      } else {
        history.push('/home');
      }
    } else if (loginState === 'ONE_LAST_STEP') {
      history.push('/one-last-step');
    } else if (loginState === 'SPONSOR_EMAIL') {
      history.push('/corporate-onboard');
      return false;
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      logEvent('Onboarding Phone Number Screen');
    }
  };

  updateApiBaseUrl = () => {
    const { authToken, memberId, dispatch } = this.props;
    console.log(authToken, 'The users token');
    if (authToken) {
      userInfo(authToken)
        .then((response) => {
          console.log(
            'The details of updateApiBaseUrl',
            response.gFitSync.googleFitLastSync,
            response.gFitSync.gfHourlyLastSync
          );
          dispatch(setFitBitConnected(Boolean(response.fitbitUser)));
          const data = response.gFitSync;
          let googleFitLastSyncDate = data.googleFitLastSync;
          let gfHourlyLastSync = data.gfHourlyLastSync;
          if (googleFitLastSyncDate === 0 || googleFitLastSyncDate === '0') {
            googleFitLastSyncDate = new Date().getTime();
          }
          if (gfHourlyLastSync === 0 || gfHourlyLastSync === '0') {
            gfHourlyLastSync = new Date().getTime();
          }
            if (window.Android !== undefined) {
              window.Android.updateApiBaseUrlV2 &&
                window.Android.updateApiBaseUrlV2(
                  String(`${config.apiBaseUrl}`),
                  String(authToken),
                  Number(googleFitLastSyncDate),
                  Number(gfHourlyLastSync),
                  String(memberId),
                  Boolean(response.fitbitUser)
                );
              window.Android.updateApiBaseUrl &&
                window.Android.updateApiBaseUrl(
                  String(`${config.apiBaseUrl}`),
                  String(authToken),
                  Number(googleFitLastSyncDate),
                  Number(gfHourlyLastSync),
                  String(memberId)
                );
            } else if (
              window.webkit &&
              window.webkit.messageHandlers.visitIosView
            ) {
              window.webkit.messageHandlers.visitIosView.postMessage(
                JSON.stringify({
                  method: 'updateApiBaseUrlV2',
                  apiBaseUrl: `${config.apiBaseUrl}`,
                  authtoken: authToken,
                  googleFitLastSync: googleFitLastSyncDate,
                  gfHourlyLastSync: gfHourlyLastSync,
                  memberId: memberId,
                  fitbitUser: response.fitbitUser,
                })
              );
              window.webkit.messageHandlers.visitIosView.postMessage(
                JSON.stringify({
                  method: 'updateApiBaseUrl',
                  apiBaseUrl: `${config.apiBaseUrl}`,
                  authtoken: authToken,
                  googleFitLastSync: googleFitLastSyncDate,
                  gfHourlyLastSync: gfHourlyLastSync,
                  memberId: memberId,
                })
              );
            }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

getBalanceForMemberId = () => {
    const { memberId, dispatch } = this.props;
    getMemberBalanceForTataUser(memberId)
    .then((response) => {
      if(response.action === 'SUCCESS') {
        dispatch(setFitcoinsBalance(response.balance_points));
        console.log('The fitcoin balance got updated', response);
      } else {
        throw new Error('Invalid response');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  componentWillUpdate = () => {
    // otp error then change loading back to false
    if (this.state.loading && this.props.appError.hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  submitPhoneNumber = () => {
    this.setState({
      loading: true,
    });
    console.log('Phone number submitted ', this.state.phoneValue);
    const { dispatch, history, location } = this.props;
    let encData;
    if (location.state) {
      encData = location.state.encData;
    }
    console.log(history);
    dispatch(
      login(this.state.phoneValue, 91, encData, history, false, location.search)
    );
  };

  render() {
    const { phonePeToken } = this.state;
    const phoneValueLink = Link.state(this, 'phoneValue');
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon src={contactGraphic} alt="Phone" />
          </HeaderContainer>
          <TitleText>
            Enter your
            <br />
            Phone Number
          </TitleText>
          <TitleText>{phonePeToken}</TitleText>
          <InputContainer>
            <CountryCodeText>+91</CountryCodeText>
            <StyledInputBox
              type="tel"
              className="visit-input"
              maxLength={10}
              valueLink={phoneValueLink}
              placeholder="Enter Your Phone Number"
            />
          </InputContainer>
          <PrimaryButton
            onTap={this.submitPhoneNumber}
            disabled={this.state.phoneValue.length !== 10}
            loading={this.state.loading}
          >
            <StyledButtonText>next</StyledButtonText>
          </PrimaryButton>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
  service: state.app.service,
  authToken: state.user.authToken,
  memberId: state.app.memberId,
});

export default connect(mapStateToProps)(OnboardingStart);
