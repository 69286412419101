import Shield from '../../images/health-content/shield.svg';

export default function ShieldText() {
  return (
    <div className="flex mt-35">
      <img src={Shield} alt="note" />
      <p className="font-medium text-chip">
        Your answers will remain confidential
      </p>
    </div>
  );
}
