import React, { Component } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../utils';
import StyledTappable from '../common/StyledTappable';

import { BoldText, Text } from '../common/Text';

const OuterContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OptionContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
  font-size: ${fontSize(1)};
  min-height: 2rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #dadada;
`;

const OptionText = styled(Text)`
  font-size: ${fontSize(1)};
  color: 'black';
`;

const ChoicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const ChoiceContainer = styled(StyledTappable)`
  flex: 1;
  background: ${(props) => (props.selected ? '#303E4F' : '#f5f5f5')};
  display: flex;
  margin: 0 6px;
  padding: 6px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

const ChoiceText = styled(Text)`
  font-size: ${fontSize(0.8)};
  color: ${props => (props.selected ? 'white' : 'black')};
`;

const SubmitContainer = styled(StyledTappable)`
  align-self: stretch;
  text-align: center;
  padding: 14px 0;
`;

const ButtonText = styled(BoldText)`
  color: ${(props) => (!props.disabled ? '#303E4F' : 'rgba(0,0,0,0.5)')};
`;

class MultiListInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: [],
    };
  }

  sendMessage = () => {
    const { sendMessage } = this.props;
    const { selectedOptions } = this.state;
    const text = selectedOptions.reduce(
      (agg, ele, idx) => (idx === 0 ? ele.name : `${agg},${ele.name}`),
      '',
    );
    const msg = {
      text,
      cardType: 'text',
      flowDirective: {
        evidences: selectedOptions.map(ele => ({
          evidenceId: ele.evidenceId,
          choiceId: ele.choiceId,
        })),
      },
    };
    sendMessage(msg);
  };

  onOptionTapped(option) {
    const { selectedOptions } = this.state;
    const findIndex = selectedOptions.findIndex(
      ele => ele.evidenceId === option.evidenceId,
    );
    if (findIndex === -1) {
      // Add the option
      this.setState({
        selectedOptions: selectedOptions.concat([
          {
            name: option.label,
            evidenceId: option.evidenceId,
            choiceId: option.choiceId,
          },
        ]),
      });
    } else {
      // Update the option
      const updatedSelectedOptions = Array.from(selectedOptions);
      updatedSelectedOptions[findIndex].choiceId = option.choiceId;
      updatedSelectedOptions[findIndex].name = option.label;
      this.setState({
        selectedOptions: updatedSelectedOptions,
      });
    }
  }

  render() {
    const { selectedOptions } = this.state;
    const isChoiceSelected = (evidenceId, choiceId) => {
      const findIndex = selectedOptions.findIndex(
        ele => ele.evidenceId === evidenceId,
      );
      if (findIndex === -1) {
        return false;
      }
      return selectedOptions[findIndex].choiceId === choiceId;
    };
    const renderChoices = (ele, idx) => {
      return (
        <ChoiceContainer
          key={idx}
          onTap={() => this.onOptionTapped(ele)}
          selected={isChoiceSelected(ele.evidenceId, ele.choiceId)}
        >
          <ChoiceText selected={isChoiceSelected(ele.evidenceId, ele.choiceId)}>
            {ele.label}
          </ChoiceText>
        </ChoiceContainer>
      );
    };
    const renderListOptions = (ele, index) => {
      const findIndex = selectedOptions.findIndex(
        ite =>
          ite.evidenceId === ele.evidenceId && ite.choiceId === ele.choiceId,
      );
      return (
        <OptionContainer key={ele.evidenceId + index}>
          <OptionText selected={findIndex !== -1}>{ele.name}</OptionText>
          <ChoicesContainer>{ele.buttons.map(renderChoices)}</ChoicesContainer>
        </OptionContainer>
      );
    };

    return (
      <OuterContainer>
        {this.props.options.map(renderListOptions)}
        <SubmitContainer
          disabled={selectedOptions.length !== this.props.options.length}
          onTap={this.sendMessage}
        >
          <ButtonText
            disabled={selectedOptions.length !== this.props.options.length}
          >
            Submit
          </ButtonText>
        </SubmitContainer>
      </OuterContainer>
    );
  }
}

export default MultiListInput;
