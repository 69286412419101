import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import withAuth from '../common/withAuth';
import Header from '../../components/common/Header';
import HraEndContainer from './HraEndContainer';
import HraQuestionContainer from './HraQuestionContainer';
import { connect } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
`;

const HraNavigator = (props) => {
  console.log(props);
  const { config } = props;
  let showHeader = config.showHraHeader;
  return (
    <Container>
      {/* {showHeader ?  */}
      {/* <Header
        title="Health Risk Assessment"
        onTap={() => {
          console.log('closeView');
          if (window.Android && window.Android.closeView) {
            window.Android.closeView(true);
          } else if (
            window.webkit &&
            window.webkit.messageHandlers.visitIosView
          ) {
            window.webkit.messageHandlers.visitIosView.postMessage(
              JSON.stringify({
                method: 'closeView',
                tataUser: true,
              })
            );
          }
        }}
      /> */}
      {/*  }}/> : ''} */}
      <Switch>
        <Route path="/hra" exact component={HraQuestionContainer} />
        <Route path="/hra/question" component={HraQuestionContainer} />
        <Route path="/hra/end" component={HraEndContainer} />
      </Switch>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.app.config,
  };
};

export default withAuth(connect(mapStateToProps)(HraNavigator));
