import React, { Component } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const transitionDuration = 500;

const OuterContainer = styled.div`
  position: relative;
  align-self: stretch;
  background: #2a3aca;
  height: ${(props) => props.height || '8px'};
  border-radius: ${(props) => props.height || '8px'};
`;

const ProgressContainer = styled.div.attrs({
  style: (props) => ({
    width: props.width || '1%',
  }),
})`
  transition: ${() => `width ${transitionDuration}ms ease-in-out`};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${(props) =>
    `linear-gradient(to left, ${props.left || `#73ca78`}, ${
      props.right || `#c5e492`
    })`};
  border-radius: ${(props) => props.height || '10px'};
`;

export default class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transition: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transition: true });
    }, 500);
  }

  render() {
    const { width, height, className, gradient } = this.props;
    const { transition } = this.state;
    const transitionWidth = {
      entering: '1%',
      entered: width || '1%',
      exiting: '0%',
      exited: '0%',
    };
    return (
      <OuterContainer className={className} height={height}>
        <Transition in={transition} timeout={transitionDuration}>
          {(state) => (
            <ProgressContainer
              width={transitionWidth[state]}
              height={height}
              left={gradient && gradient[0]}
              right={gradient && gradient[1]}
            />
          )}
        </Transition>
      </OuterContainer>
    );
  }
}
