import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { FlyInTransition } from '../../components/transitions/transitions';
import arrowBack from '../../images/arrow_back.svg';
import Modal from '../common/Modal';
import Player from './PodcastPlayer';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  background: white;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  padding: 0px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f3f6;
`;

const HeaderText = styled(Text)`
  margin-left: 0.625rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #464548;
`;

const HeaderNew = styled.button`
  padding: 30px 01px 0px 0px;
  display: flex;
  background: white;
  align-self: stretch;
  align-items: center;
`;

const BodyContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  margin-top: -9px;
`;

const AlbumImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 18px;
  @media screen and (max-width: 440px) {
    width: 150px;
    height: 150px;
  }
`;

const HoverContent = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 12px 11px;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 0px 18px 18px;
  width: 180px;
  color: #ffffff;
  @media screen and (max-width: 440px) {
    width: 150px;
  }
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const CardDescription = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #e6e6e6;
  margin-top: 2px;
`;

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

const SeeAllPodcasts = function (props) {
  const { state } = props.location;
  const card = state.card;
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const { authToken } = useSelector((state) => state.user);
  const history = useHistory();

  const playPodcast = (album) => {
    if (album.showAudioPlayer) {
      setShowPlayerModal(true);
      setSelectedAlbum(album);
    } else {
      history.push('/health-content/sub-podcasts', {
        albumId: album.albumId,
      });
    }
  };

  const hideModal = () => {
    setShowPlayerModal(false);
  };

  const renderPodcastCards = (card) => {
    const albums = card.albums;
    return (
      <div className="p-24px pt-4px">
        {albums && albums.length > 0 && (
          <div className="flex flex-row flex-wrap justify-between px-10px">
            {albums.map((album) => {
              return (
                <button
                  onClick={() => playPodcast(album)}
                  style={{ marginTop: '25px', position: 'relative' }}
                  className="text-left"
                >
                  <AlbumImage src={album.imageUrl} />
                  <HoverContent>
                    <CardTitle>{album.albumTitle}</CardTitle>
                    <CardDescription>{album.coachName}</CardDescription>
                  </HoverContent>
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };
  return (
    <OuterContainer>
      <HeaderContainer>
        <HeaderNew>
          <img
            src={arrowBack}
            className="cursor-pointer"
            onClick={props.history.goBack}
            style={{ width: '36px' }}
          />
          <HeaderText>{card.title}</HeaderText>
        </HeaderNew>
      </HeaderContainer>
      <BodyContainer>
        <div>{renderPodcastCards(card)}</div>
      </BodyContainer>
      {showPlayerModal && (
        <Modal>
          <ModalContainer onClick={hideModal}>
            <FlyInTransition
              in={showPlayerModal}
              mountOnEnter
              unmountOnExit
              appear
            >
              <Player
                album={selectedAlbum}
                authToken={authToken}
                hideModal={hideModal}
              />
            </FlyInTransition>
          </ModalContainer>
        </Modal>
      )}
    </OuterContainer>
  );
};

export default SeeAllPodcasts;
