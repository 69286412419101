import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';
import TimeCircle from '../../images/home/Time-Circle.png';
import Step from '../../images/home/Step.svg';
import MealCalories from '../../images/home/Meal_Calorie.svg';
import SleepTracker from '../../images/home/Sleep_Tracker.svg';
import { Text } from '../common/Text';
import { useSelector } from 'react-redux';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { appConfig, revokeWearablesFitbit } from '../../services';
import {
  showError,
  setFitBitConnected,
  setGoogleFitPermission,
} from '../../actions';
import Modal from '../../containers/common/Modal';
import { store } from '../../store';
import DisconnectMainIcon from '../../images/detailed-graph/Disconnect.svg';
import CloseButton from '../../images/detailed-graph/CloseButton.svg';

const OuterTappable = styled.div`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 24px;
  height: 52px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
`;

const TitleText = styled(Text)`
  font-size: 14px;
  float: left;
  margin-right: 0.5rem;
  color: #21243c;
  margin-left: 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: inline-block;
  color: #21243c;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #21243c;
  font-size: 14px;
`;

const ProgressBarsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
`;

const ProgressBar = styled.div`
  margin: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Achieved = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #21243c;
`;

const Target = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #767787;
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #303e4f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  border-top: 1px solid rgba(165, 165, 165, 0.1);
`;

const GoalProgressCard = (props) => {
  const [distanceObj, setDistanceObj] = useState({
    distanceGoal: 0,
    stepsPerKm: 0,
  });
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { fitbitUser, authToken } = useSelector((state) => state.user);

  function showConfirmationModal(e) {
    e?.stopPropagation();
    setIsConfirmationModalOpen(true);
  }

  function disconnectFitnessSyncing() {
    if (fitbitUser) {
      disconnectFitbit();
      store.dispatch(setFitBitConnected(false));
    } else {
      disconnectFromGoogleFit();
    }
    store.dispatch(setGoogleFitPermission({ permissionForGoogleFit: false }));
    closeConfirmationModal();
  }

  function closeConfirmationModal(e) {
    e?.stopPropagation();
    setIsConfirmationModalOpen(false);
  }

  async function disconnectFitbit() {
    try {
      await revokeWearablesFitbit(authToken);
      disconnectFromFitbit();
    } catch (error) {
      console.log(error);
    }
  }

  async function disconnectFromFitbit() {
    if (window?.Android?.disconnectFromFitbit) {
      window.Android.disconnectFromFitbit();
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'disconnectFromFitbit',
        })
      );
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'disconnectFromFitbit',
        })
      );
    }
  }

  async function disconnectFromGoogleFit() {
    if (window?.Android?.disconnectFromGoogleFit) {
      window.Android.disconnectFromGoogleFit();
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'disconnectFromGoogleFit',
        })
      );
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'disconnectFromGoogleFit',
        })
      );
    }
  }

  useEffect(() => {
    appConfig(props.authToken)
      .then((response) => {
        setDistanceObj({
          distanceGoal: response.healthGoals.distanceGoal,
          stepsPerKm: response.healthGoals.stepsPerKm,
        });
      })
      .catch((err) => {
        showError(err);
        throw err;
      });
  }, []);

  const {
    steps,
    meals,
    sleep,
    currentSteps = 0,
    currentSleep = 0,
    currentCals = 0,
    totalCals,
  } = props;
  const slept = Math.round(parseInt(currentSleep, 10) / 60)
    .toFixed(2)
    .toString();
  const sleepValue = parseInt(slept, 10) / (parseInt(sleep.goalValue, 10) / 60);
  let sleepPercentage;
  if (sleepValue < 1) {
    sleepPercentage = sleepValue.toFixed(2).split('.')[1];
  } else {
    sleepPercentage = 100;
  }
  const stepsValue = parseInt(currentSteps, 10) / parseInt(steps.goalValue, 10);
  let stepsPercentage;
  if (stepsValue < 1) {
    stepsPercentage = stepsValue.toFixed(2).split('.')[1];
  } else {
    stepsPercentage = 100;
  }
  const mealValue = currentCals / 2400;
  let mealPercentage;
  if (mealValue < 1) {
    mealPercentage = mealValue.toFixed(2).toString().split('.')[1];
  } else {
    mealPercentage = 100;
  }
  const hours = parseInt(currentSleep, 10) / 60;
  const mins = parseInt(currentSleep, 10) % 60;
  let sleptTime;
  if (hours < 1) {
    sleptTime = mins + 'mins';
  } else {
    sleptTime = Math.floor(hours) + 'hr ' + mins + 'mins';
  }

  const sh = parseInt(sleep.goalValue, 10) / 60;
  const sm = parseInt(sleep.goalValue, 10) % 60;
  let sleptGoal;
  if (sh < 1) {
    sleptGoal = sm + 'mins';
  } else {
    sleptGoal = Math.floor(sh) + 'hr ' + sm + 'mins';
  }

  return (
    <OuterTappable
      onClick={() => {
        props.history.push('/health-data', {
          stepsGoal: parseInt(steps.goalValue),
          sleepGoal: sleptGoal,
          distanceObj: distanceObj,
          sleepGoalMin: sleep.goalValue,
          selectedItem: 'Step Count',
          currentSteps,
          redirectBackToPWA: true,
        });
        console.log('Whole component');
      }}
      className="rounded-12 border border-txtsecondary-shades7 shadow-card ios-fix"
    >
      <OuterContainer>
        <TitleContainer>
          <div className="flex-center mb-4">
            <img src={TimeCircle} alt="" width={'20px'} height={'20px'} />
            <TitleText>Today's Goal Progress</TitleText>
          </div>
        </TitleContainer>
        <div>
          <ArrowIcon />
        </div>
      </OuterContainer>
      <ProgressBarsContainer>
        <ProgressBar>
          <div style={{ width: '55px', height: '55px' }}>
            <CircularProgressbarWithChildren
              value={steps.goalValue != 0 ? stepsPercentage : 0}
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                pathColor: '#013ADE',
                width: 55,
                height: 55,
              })}
            >
              <img style={{ width: 20, height: 20 }} src={Step} alt="steps" />
            </CircularProgressbarWithChildren>
          </div>
          <div style={{ textAlign: 'center', marginTop: '9px' }}>
            <Achieved>{currentSteps}</Achieved>
            <Target>of {steps.goalValue} steps</Target>
          </div>
        </ProgressBar>
        <ProgressBar
          onClick={(e) => {
            props.history.push('/health-data', {
              stepsGoal: parseInt(steps.goalValue),
              sleepGoal: sleptGoal,
              distanceObj: distanceObj,
              sleepGoalMin: sleep.goalValue,
              selectedItem: 'Meal Coverage',
              redirectBackToPWA: true,
            });
            e.stopPropagation();
            console.log('Meal event');
          }}
        >
          <div style={{ width: '55px', height: '55px' }}>
            <CircularProgressbarWithChildren
              value={mealPercentage}
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                textColor: '#f88',
                pathColor: '#EE744D',
                width: 55,
                height: 55,
              })}
            >
              <img
                style={{ width: 20, height: 20 }}
                src={MealCalories}
                alt="fork knife"
              />
            </CircularProgressbarWithChildren>
          </div>
          <div style={{ textAlign: 'center', marginTop: '9px' }}>
            <Achieved>{currentCals}</Achieved>
            <Target>of 2400 calories</Target>
          </div>
        </ProgressBar>
        <ProgressBar
          onClick={(e) => {
            props.history.push('/health-data', {
              stepsGoal: parseInt(steps.goalValue),
              sleepGoal: sleptGoal,
              distanceObj: distanceObj,
              sleepGoalMin: sleep.goalValue,
              selectedItem: 'Sleep Hours',
              redirectBackToPWA: true,
            });
            e.stopPropagation();
            console.log('Meal event');
          }}
        >
          <div style={{ width: '55px', height: '55px' }}>
            <CircularProgressbarWithChildren
              value={sleep.goalValue != '0' ? sleepPercentage : 0}
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                pathColor: '#244cb2',
                width: 55,
                height: 55,
              })}
            >
              <img
                style={{ width: 20, height: 20 }}
                src={SleepTracker}
                alt="moon"
              />
            </CircularProgressbarWithChildren>
          </div>

          <div style={{ textAlign: 'center', marginTop: '9px' }}>
            <Achieved>{sleptTime}</Achieved>
            <Target>of {parseInt(sleep.goalValue, 10) / 60} hrs</Target>
          </div>
        </ProgressBar>
      </ProgressBarsContainer>
      {(fitbitUser || window?.Android) && (
        <div className="flex w-full" onClick={showConfirmationModal}>
          <Button>
            <p style={{ fontWeight: '600', fontSize: '14px' }}>Disconnect</p>
          </Button>
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal>
          <div
            className="h-full relative doctorProfileModal cancelModal w-full rounded-sm flex justify-center items-center"
            style={{
              background: 'rgba(0, 0, 0, 0.4)',
              overflow: 'none',
            }}
            onClick={closeConfirmationModal}
          >
            <div
              className="flex flex-col cancelModal w-full items-center justify-center"
              style={{
                width: '85%',
              }}
              onClick={closeConfirmationModal}
            >
              <div
                className="min-h-fit flex flex-col bg-textbox p-16px rounded-xl"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-end">
                  <button onClick={closeConfirmationModal}>
                    <img src={CloseButton} alt="close" />
                  </button>
                </div>
                <div className="p-10 flex flex-col items-center justify-between">
                  <img
                    src={DisconnectMainIcon}
                    alt="Disconnect"
                    className="mb-20"
                  />
                  <h1 className="text-title-m font-semibold text-center mb-10">
                    Are you sure you want to disconnect activity data syncing?
                  </h1>
                </div>
                <button
                  className="p-4 bg-primary text-textbox text-title-m font-semibold rounded-xl"
                  onClick={disconnectFitnessSyncing}
                >
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </OuterTappable>
  );
};

export default GoalProgressCard;
