import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.25rem 0;
`;

const DoctorImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  margin-right: 0.375rem;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  background: rgba(228, 220, 248, 0.2);

  padding: 10px;
  border-radius: 15px;
`;

const TypingAnimation = keyframes`
  0% {
  transform:translateY(0px)
  }
  28%{
    transform:translateY(-6px)
  }
  44%{
    transform:translateY(0px)
  }
`;

const Dot = styled.div`
  animation: 1.5s ${TypingAnimation} infinite ease-in-out;
  animation-delay: ${(props) => `${200 + props.index * 100}ms`};
  background: #303e4f;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  margin-right: 3px;
`;

const TypingIndicator = ({ doctorImage }) => (
  <OuterContainer>
    {doctorImage && <DoctorImage src={doctorImage} />}
    <BubbleContainer>
      <Dot index={0} />
      <Dot index={1} />
      <Dot index={2} />
    </BubbleContainer>
  </OuterContainer>
);

TypingIndicator.propTypes = {
  doctorImage: PropTypes.string,
};

export default TypingIndicator;
