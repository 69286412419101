import React, { Component } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';
import { FadeInTransition } from '../transitions/transitions';
import { PrimaryButton } from './Buttons';
import TappableCard from './TappableCard';
import { ButtonText, Text } from './Text';
import GoogleFitnessIcon from '../../images/common/google-fit.png';
import HealthKitIcon from '../../images/common/health-kit.svg';
import FitbitIcon from '../../images/health-data/fitbit-icon.svg';
import {
  setGoogleFitPermission,
  setIOSUser,
  setFitBitConnected,
} from '../../actions';
import { connect } from 'react-redux';
import { updateHraAndGoogleFitDetailsToTata } from '../../services/tata-aig';
import config from '../../config';
import { userInfo } from '../../services';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.7rem;
  z-index: 10;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
`;

const BackContainer = styled(TappableCard)`
  margin-right: 1.125rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #16307a;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
  color: #16307a;
  font-weight: 600;
`;

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.625rem 0rem 0.625rem;
  overflow-y: scrol;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
`;

const SelectText = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #2f3e50;
  width: 100%;
  margin-left: 5%;
`;

const FitnessOptions = styled.div`
  margin-left: 7%;
  margin-right: 7%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const NextButton = styled.div`
  position: absolute;
  bottom: 2px;
  width: 100%;
  background: #303e50;
  padding: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageCard = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 0.875rem;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeading = styled.div`
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
`;
class StayActive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFitnessDevice: null,
      permissionGranted: false,
      status: false,
      // isIosDataSyncing: false,
    };
  }

  componentDidMount() {
    const { location, dispatch } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);

      /**
       * Test Params if permission granted
       */
      const permissionForGoogleFit = [true, 'true'].includes(
        params.get('permissionGranted')
      );
      const isFitbitConnected = [true, 'true'].includes(params.get('fitbit'));
      dispatch(setGoogleFitPermission({ permissionForGoogleFit }));

      if (permissionForGoogleFit) {
        const body = {
          member_id: this.props.memberId,
          fit_integrated: permissionForGoogleFit,
        };
        this.updateHraAndGoogleFitDetailsToTata(body);
        this.updateApiBaseUrl();
      }
      if (isFitbitConnected) {
        dispatch(setFitBitConnected(true));
      } else {
        this.setState({
          permissionGranted: permissionForGoogleFit,
        });
      }
    } else if (this.props.fitbitUser) {
      // fetch data from API and show in PWA
      this.fetchCurrentFitbitStatus();
    } else if (
      window.Android !== undefined &&
      window.Android.inFitSelectScreen
    ) {
      window.Android.inFitSelectScreen();
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'inFitSelectScreen',
        })
      );
    }

    window.googleFitStatus = (value) => {
      this.setState({ status: value });
    };

    window.isIosUser = (value) => {
      dispatch(setIOSUser(true));
    };

    window.hardwareBackPressed = () => {
      if (window?.Android?.closeView) {
        window?.Android.closeView(true);
      }
    };

    window.syncingEnabled = (isIosDataSyncing) => {
      // this.setState({ isIosDataSyncing });
    };
  }

  updateApiBaseUrl = () => {
    const { authToken, memberId, dispatch } = this.props;
    console.log(authToken, 'The users token');
    if (authToken) {
      userInfo(authToken)
        .then((response) => {
          console.log(
            'The details of updateApiBaseUrl',
            response.gFitSync.googleFitLastSync,
            response.gFitSync.gfHourlyLastSync
          );
          dispatch(setFitBitConnected(Boolean(response.fitbitUser)));
          const data = response.gFitSync;
          let googleFitLastSyncDate = data.googleFitLastSync;
          let gfHourlyLastSync = data.gfHourlyLastSync;
          if (googleFitLastSyncDate === 0 || googleFitLastSyncDate === '0') {
            googleFitLastSyncDate = new Date().getTime();
          }
          if (gfHourlyLastSync === 0 || gfHourlyLastSync === '0') {
            gfHourlyLastSync = new Date().getTime();
          }
          if (window.Android !== undefined) {
            window.Android.updateApiBaseUrlV2 &&
              window.Android.updateApiBaseUrlV2(
                String(`${config.apiBaseUrl}`),
                String(authToken),
                Number(googleFitLastSyncDate),
                Number(gfHourlyLastSync),
                String(memberId),
                Boolean(response.fitbitUser)
              );
            window.Android.updateApiBaseUrl &&
              window.Android.updateApiBaseUrl(
                String(`${config.apiBaseUrl}`),
                String(authToken),
                Number(googleFitLastSyncDate),
                Number(gfHourlyLastSync),
                String(memberId)
              );
          } else if (
            window.webkit &&
            window.webkit.messageHandlers.visitIosView
          ) {
            window.webkit.messageHandlers.visitIosView.postMessage(
              JSON.stringify({
                method: 'updateApiBaseUrlV2',
                apiBaseUrl: `${config.apiBaseUrl}`,
                authtoken: authToken,
                googleFitLastSync: googleFitLastSyncDate,
                gfHourlyLastSync: gfHourlyLastSync,
                memberId: memberId,
                fitbitUser: response.fitbitUser,
              })
            );
            window.webkit.messageHandlers.visitIosView.postMessage(
              JSON.stringify({
                method: 'updateApiBaseUrl',
                apiBaseUrl: `${config.apiBaseUrl}`,
                authtoken: authToken,
                googleFitLastSync: googleFitLastSyncDate,
                gfHourlyLastSync: gfHourlyLastSync,
                memberId: memberId,
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  updateHraAndGoogleFitDetailsToTata = (body) => {
    updateHraAndGoogleFitDetailsToTata(body)
      .then(() => {
        console.log('Hra Completed and syncing to Tata Android');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchCurrentFitbitStatus = async () => {
    try {
      const response = await userInfo(this.props.authToken);

      this.props.dispatch(setFitBitConnected(response.fitbitUser));
    } catch (error) {
      console.log(error);
    }
  };

  parseJWTToken = (token) => token.split(' ')[1];

  getFitbitAuthURL = () =>
    `${config.apiBaseUrlOld}/wearables/fitbit/&userId=${
      this.props.userId
    }/authorize?auth=${this.parseJWTToken(this.props.authToken)}`;

  connectToFitbit = () => {
    if (window.Android !== undefined && window.Android.connectToFitbit) {
      window.Android.connectToFitbit(
        this.getFitbitAuthURL(),
        this.props.authToken
      );
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'connectToFitbit',
          url: this.getFitbitAuthURL(),
          authtoken: this.props.authToken,
        })
      );
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'connectToFitbit',
          url: this.getFitbitAuthURL(),
          authtoken: this.props.authToken,
        })
      );
    } else if (window.visitIosView) {
      window.visitIosView.postMessage(
        JSON.stringify({
          method: 'connectToFitbit',
          url: this.getFitbitAuthURL(),
          authtoken: this.props.authToken,
        })
      );
    }
  };

  connectToGoogleFit = () => {
    console.log('Connecting to Android SDK');
    if (window.Android !== undefined && window.Android.connectToGoogleFit) {
      window.Android.connectToGoogleFit();
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'connectToGoogleFit',
        })
      );
    }
  };

  connectToFitness = () => {
    if (this.state.selectedFitnessDevice === 'fitbit') {
      this.connectToFitbit();
    } else {
      this.connectToGoogleFit();
    }
  };

  goBack = () => {
    if (window?.Android?.closeView) {
      window.Android.closeView(true);
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'closeView',
          tataUser: true,
        })
      );
    } else {
      this.props.history.goBack();
    }
  };

  // toggleIOSDataSync = (event = {}) => {
  //   const value = event.target?.checked;

  //   if (window?.webkit?.messageHandlers?.visitIosView) {
  //     window.webkit.messageHandlers.visitIosView.postMessage(
  //       JSON.stringify({
  //         method: value ? 'enableDataSyncing' : 'disableDataSyncing',
  //       })
  //     );
  //   }
  // };

  render() {
    const {
      selectedFitnessDevice,
      permissionGranted,
      status,
      // isIosDataSyncing,
    } = this.state;

    const { isIOSUser } = this.props;
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            {!this.props.googleFitPermission && (
              <BackContainer onTap={this.goBack}>
                <StyledArrow />
              </BackContainer>
            )}
            <HeaderTitleContainer>
              <HeaderText
                style={
                  this.props.googleFitPermission
                    ? { marginLeft: '36px' }
                    : { margin: '0' }
                }
              >
                Stay Active
              </HeaderText>
            </HeaderTitleContainer>
          </HeaderContainer>
          <BodyContainer>
            <SelectText>Select Health App to Sync Data</SelectText>
            <div className="flex flex-wrap justify-around">
              <FitnessOptions>
                <div className="flex flex-col items-center justify-center">
                  <ImageCard
                    onClick={() => {
                      if (!status && !permissionGranted) {
                        this.setState({ selectedFitnessDevice: 'googleFit' });
                      }
                    }}
                    style={
                      !permissionGranted
                        ? {
                            border:
                              this.state.selectedFitnessDevice === 'googleFit'
                                ? '2px solid #0c2b75'
                                : 'none',
                            cursor: 'pointer',
                          }
                        : { border: 'none' }
                    }
                  >
                    <img
                      src={isIOSUser ? HealthKitIcon : GoogleFitnessIcon}
                      style={{ width: '64px', height: '50px' }}
                      alt="icon"
                    />
                  </ImageCard>
                  <CardHeading>
                    {isIOSUser ? 'Health Kit' : 'Google Fit'}
                  </CardHeading>
                </div>
              </FitnessOptions>
              <FitnessOptions>
                <div className="flex flex-col items-center justify-center">
                  <ImageCard
                    onClick={() => {
                      if (!status && !permissionGranted) {
                        this.setState({ selectedFitnessDevice: 'fitbit' });
                      }
                    }}
                    style={
                      !permissionGranted
                        ? {
                            border:
                              this.state.selectedFitnessDevice === 'fitbit'
                                ? '2px solid #0c2b75'
                                : 'none',
                            cursor: 'pointer',
                          }
                        : { border: 'none' }
                    }
                  >
                    <img
                      src={FitbitIcon}
                      style={{ width: '64px', height: '50px' }}
                      alt="icon"
                    />
                  </ImageCard>
                  <CardHeading>FitBit</CardHeading>
                </div>
              </FitnessOptions>
            </div>

            {permissionGranted && (
              <div
                className="mt-48px flex items-center justify-center"
                style={{ color: '#7bff9a', fontWeight: '600' }}
              >
                {isIOSUser ? 'Health Kit' : 'Google Fit'} Connected Successfully
              </div>
            )}
            {status && (
              <div
                className="mt-48px flex items-center justify-center"
                style={{ color: '#7bff9a', fontWeight: '600' }}
              >
                {isIOSUser ? 'Health Kit' : 'Google Fit'} Already Connected
              </div>
            )}
            {this.props.fitbitUser && (
              <div
                className="mt-48px flex items-center justify-center"
                style={{ color: '#7bff9a', fontWeight: '600' }}
              >
                Fitbit Connected
              </div>
            )}

            {/* {status && isIOSUser && (
              <div>
                <div
                  className="border-t border-txtsecondary-shades7 mt-30 mb-10 mx"
                  style={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
                <div className="flex items-center justify-between mx-5">
                  <p className="text-body-s font-semibold">Data Syncing</p>
                  <div class="relative inline-block w-12 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      name="toggle"
                      id="toggle"
                      class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-textbox appearance-none cursor-pointer m-0.5"
                      onChange={this.toggleIOSDataSync}
                      checked={isIosDataSyncing}
                    />
                    <label
                      for="toggle"
                      class="toggle-label block overflow-hidden h-7 rounded-full bg-primary-light cursor-pointer"
                    ></label>
                  </div>
                </div>
                <div
                  className="border-t border-txtsecondary-shades7 mt-10 mb-10"
                  style={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
              </div>
            )} */}
          </BodyContainer>

          {permissionGranted || status || this.props.fitbitUser ? (
            <NextButton
              style={{ color: '#ffffff', background: '#303e4f' }}
              onClick={() => {
                if (
                  window.Android !== undefined &&
                  window.Android.googleFitConnectedAndSavedInPWA
                ) {
                  window.Android.googleFitConnectedAndSavedInPWA();
                } else if (
                  window.webkit &&
                  window.webkit.messageHandlers.visitIosView
                ) {
                  window.webkit.messageHandlers.visitIosView.postMessage(
                    JSON.stringify({
                      method: 'googleFitConnectedAndSavedInPWA',
                    })
                  );
                }
              }}
            >
              Home
            </NextButton>
          ) : (
            <NextButton
              style={
                selectedFitnessDevice
                  ? { color: '#ffffff', background: '#303e4f' }
                  : { color: '#264a9f73', background: '#f0f4ff' }
              }
              onClick={this.connectToFitness}
            >
              Next
            </NextButton>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  googleFitPermission: state.app.fitnessPermissionGranted,
  memberId: state.app.memberId,
  fitbitUser: state.user.fitbitUser,
  userId: state.user.userId,
  authToken: state.user.authToken,
  isIOSUser: state.app.isIOSUser,
});

export default connect(mapStateToProps)(StayActive);
