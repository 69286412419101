const config = {
  pubnubPublishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
  pubnubSubscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
  rootUrl: 'https://tata-aig.getvisitapp.com/',
  apiBaseUrl: 'https://api.getvisitapp.com/v3',
  opdPurchaseUrl: 'https://buy.getvisitapp.com',
  websiteBaseUrl: 'https://getvisitapp.com/',
  pharmaServer: 'https://api.getvisitapp.com/pharmacy',
  surveyUrl: 'https://az1.survey2connect.com/R/32d1699f?langKey=eng_us',
  version: '161',
  amplitudeApiKey: 'c1ee11161ba6d54bb7df1b932c048dd0',
  opentokApiKey: '45440642',
  clevertapId: '485-5KR-KR6Z',
  tataApiUrl: 'https://healthvas.tataaig.com',
  tataToken: 'Basic Z2V0X3Zpc2l0OkpiJXRldTczNiQ5aFJlMQ==',
  apiBaseUrlOld: 'https://api.getvisitapp.com/v3',
  ticketingBaseUrl: 'https://help.getvisitapp.com/v4',
  freeVerticalsList: [11, 23, 24],
  localStorageEncryptionKey: 'visit-pwa@1.1',
  localStorageEncryptionSalt: '87e7c119e0abb08197327867035bd22e',
};
export default config;
