import WatchIcon from '../../images/health-content/watch-icon.svg';
import WeightIcon from '../../images/health-content/weight-sub-icon.svg';
import CalorieIcon from '../../images/health-content/calorie-icon.svg';

export default function WorkoutCard({
  index = 0,
  reverseHeader,
  heading,
  subHeading,
  label,
  targets,
}) {
  const gradients = {
    0: 'linear-gradient(301.54deg, #FFDEA7 0%, #EA6362 100%)',
    1: 'linear-gradient(171.98deg, #B293FF -22.57%, #7C58D6 35.35%)',
    2: 'linear-gradient(122.07deg, #23BAFF 0%, #5183F0 100.36%)',
  };

  const Heading = () => (
    <h2 className="text-title-m font-bold text-textbox mb-1">{heading}</h2>
  );
  const SubHeading = () => (
    <p className="text-caption-l text-textbox"> {subHeading} </p>
  );

  return (
    <div
      className="rounded-xl overflow-hidden w-full"
      style={{ boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)' }}
    >
      <div
        className="py-4 flex px-6"
        style={{
          background: gradients[index % 3],
        }}
      >
        {reverseHeader && <SubHeading />}
        <Heading />
        {!reverseHeader && <SubHeading />}
      </div>
      <div className="py-4 px-6 pt-10">
        <p className="text-chip font-medium mb-2">{label}</p>
        {targets.map((target) => (
          <div className="flex text-caption-l justify-between py-2">
            <span>
              <img
                height="16px"
                width="16px"
                src={target.icon}
                alt="time"
                className="inline mr-2"
              />
              {target.title}:
            </span>
            <span className="font-semibold">
              {target.value} {target.unit}
            </span>
          </div>
        ))}
        {/* <div className="flex text-caption-l justify-between py-2">
          <span>
            <img src={CalorieIcon} alt="heat" className="inline mr-2" />
            Calorie burn:
          </span>
          <span className="font-semibold">500kcal</span>
        </div>
        <div className="flex text-caption-l justify-between py-2">
          <span>
            <img src={WatchIcon} alt="time" className="inline mr-2" />
            Workout time:
          </span>
          <span className="font-semibold">3hr 45mins</span>
        </div>
        <div className="flex text-caption-l justify-between pt-2">
          <span>
            <img src={WeightIcon} alt="weight" className="inline mr-2" />
            Expected outcome:
          </span>
          <span className="font-semibold">Lose 1 Kg</span>
        </div> */}
      </div>
    </div>
  );
}
