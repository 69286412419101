import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PodcastAlbumPage from './PodcastAlbumPage';
import PodcastsHome from './PodcastsHome';
import SeeAllPodcasts from './SeeAllPodcasts';
import FitnessHome from './FitnessHome';
import SeeAllFitness from './SeeAllFitness';
import FitnessAlbumPage from './FitnessAlbumPage';
import MyFitnessOnboarding from './MyFitnessOnboarding';
import WeeklyPlan from './WeeklyPlan';
import StepWeeklyPlan from './StepWeeklyPlan';
import { WeightData } from './weight-data';
import { BloodPressure } from './blood-pressure-data';
import { BloodSugar } from './blood-sugar-data';
import { HbA1c } from './hbA1c-data';
import HealthData from './health-data';

class HealthContentNavigator extends Component {
  constructor(props) {
    super(props);

    if (!props.authToken || props.loginState !== 'LOGGED_IN') {
      this.props.history.push('/');
    }
  }
  render() {
    return (
      <Switch>
        <Route
          path="/health-content/podcasts-home/:type"
          component={SeeAllPodcasts}
        />
        <Route
          path="/health-content/fitness-home/:type"
          component={SeeAllFitness}
        />
        <Route path="/health-content/podcasts-home" component={PodcastsHome} />
        <Route
          path="/health-content/sub-podcasts"
          component={PodcastAlbumPage}
        />
        <Route
          path="/health-content/sub-fitness"
          component={FitnessAlbumPage}
        />
        <Route path="/health-content/fitness-home" component={FitnessHome} />
        <Route
          path="/health-content/my-fitness-onboarding"
          component={MyFitnessOnboarding}
        />
        <Route path="/health-content/weekly-plan" component={WeeklyPlan} />
        <Route
          path="/health-content/step-weekly-plan"
          component={StepWeeklyPlan}
        />
        <Route path="/health-content/weight-data" component={WeightData} />
        <Route
          path="/health-content/blood-pressure-data"
          component={BloodPressure}
        />
        <Route path="/health-content/blood-sugar-data" component={BloodSugar} />
        <Route path="/health-content/hbA1c-data" component={HbA1c} />
        <Route path="/health-content/health-data" component={HealthData} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(HealthContentNavigator);
