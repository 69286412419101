import Modal from '../../containers/common/Modal';
import CopyIcon from '../../images/rewards/copy-icon.svg';

const CopyModal = ({ hideModal }) => {
  return (
    <Modal>
      <div
        className="doctorProfileModal p-20px flex justify-center items-center"
        style={{
          background: 'rgba(0, 0, 0, 0.4)',
        }}
        onClick={hideModal}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-textbox flex flex-col items-center w-full rounded-12 p-20px text-center"
        >
          <img height="80px" width="80px" src={CopyIcon} alt="copy-icon" />
          <p className="mt-16px text-button-l font-bold">
            Copied Code to Clipboard
          </p>
          <p className="text-center text-body-l font-medium my-20px">
            You can paste it in checkout before you make the final payment.
          </p>
          <button
            onClick={hideModal}
            className="w-full rounded-12 py-12px bg-primary text-textbox text-body-l font-semibold"
          >
            Okay
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CopyModal;
