import React from 'react';
import styled from 'styled-components';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { fontSize } from '../../utils';
import StyledTappable from '../common/StyledTappable';

import { Text } from '../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OptionContainer = styled(StyledTappable)`
  justify-content: flex-start;
  font-size: 12px;
  min-height: 2rem;
  padding: 12px 0px;
  border-bottom: 1px solid #f0f3f6;
`;

const OptionSelectedIcon = styled(MdRadioButtonChecked)`
  color: #303e4f;
`;

const OptionUnselectedIcon = styled(MdRadioButtonUnchecked)`
  color: #666;
`;

const OptionText = styled(Text)`
  padding-left: 12px;
  font-size: 16px;
  color: #3a2ca0;
`;

const SingleSelect = (props) => {
  const { value, setResponse, options, disabled } = props;
  const renderListOptions = (ele, index) => {
    return (
      <OptionContainer
        key={index}
        onTap={() => {
          if (disabled) setResponse(value);
          else setResponse(ele);
        }}
      >
        {value === ele ? (
          <div
            className="mr-12px bg-txtsecondary-shades4 rounded-full"
            style={{ width: '22px', height: '22px', padding: '5px' }}
          >
            <div
              className="mr-12px rounded-full text-center"
              style={{ width: '12px', height: '12px', background: '#0b2f5e' }}
            ></div>
          </div>
        ) : (
          <div
            className="mr-12px bg-txtsecondary-shades4 rounded-full"
            style={{ width: '22px', height: '22px' }}
          ></div>
        )}
        <p
          className="text-title-m font-semibold"
          style={
            disabled && value === ele
              ? { color: '#0b2f5e', opacity: '50%' }
              : { color: '#0b2f5e' }
          }
        >
          {ele}
        </p>
      </OptionContainer>
    );
  };

  return (
    <OuterContainer className="font-pop">
      {options.map(renderListOptions)}
    </OuterContainer>
  );
};

export default SingleSelect;
