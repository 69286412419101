import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function getAllPodcasts(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/tik/podcast-dashboard`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllPodcastsByAlbumId(authToken, albumId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/tik/podcast-albums/${albumId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllFitnessByAlbumId(authToken, albumId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/tik/albums/${albumId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendActivityTracking(authToken, sessionId, duration) {
  const headers = {
    authorization: authToken,
  };
  const body = {
    albumsSessionsId: sessionId,
    timestamp: Math.floor(new Date().getTime() / 1000),
    duration: duration,
  };

  return axios
    .post(`${config.apiBaseUrl}/tik/track`, body, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateLikeStatus(authToken, sessionId, like) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    sessionId: sessionId,
    like: like,
  };

  return axios
    .post(`${config.apiBaseUrl}/tik/session/like`, body, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllWorkouts(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/tik/dashboard`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function getMyFitnessQueries(authToken) {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.post(
      `${config.apiBaseUrl}/rex/plan`,
      {},
      {
        headers,
      }
    );
    return data;
  } catch (err) {
    showError(err);
    throw err;
  }
}

export async function submitAnswerToQuery(authorization, answer) {
  const headers = {
    authorization,
  };

  try {
    const { data } = await axios.post(`${config.apiBaseUrl}/rex/plan`, answer, {
      headers,
    });
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
}

export const getFitnessProgram = async (authToken) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.get(`${config.apiBaseUrl}/rex/program`, {
      headers,
    });
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const getWeeklyPlan = async (authToken) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.get(`${config.apiBaseUrl}/rex/plan`, {
      headers,
    });
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const getCurrentWeight = async (authToken) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.get(
      `${config.apiBaseUrl}/chronic-care/health-data`,
      {
        headers,
      }
    );
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const postCurrentWeight = async (authToken, body) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.post(
      `${config.apiBaseUrl}/chronic-care/weight/log`,
      body,
      {
        headers,
      }
    );
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const postCurrentBP = async (authToken, body) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.post(
      `${config.apiBaseUrl}/chronic-care/blood-pressure/log`,
      body,
      {
        headers,
      }
    );
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const postCurrentBS = async (authToken, body) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.post(
      `${config.apiBaseUrl}/chronic-care/blood-sugar-profile/log`,
      body,
      {
        headers,
      }
    );
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const postCurrentHbA1c = async (authToken, body) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.post(
      `${config.apiBaseUrl}/chronic-care/hba1c/log`,
      body,
      {
        headers,
      }
    );
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};

export const postVideoLogTime = async (authToken, body) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const { data } = await axios.post(`${config.apiBaseUrl}/tik/track`, body, {
      headers,
    });
    if (data.message !== 'serverError') {
      return data;
    }
    throw data;
  } catch (err) {
    showError(err);
    throw err;
  }
};
