export const defaultState = {
  hraDetails: {},
  hraUpdatedToTataAig: false,
  googleFitnessConnected: false,
  updatedFitnessToTataAig: false,
  hraTaken: false,
};

export default function tataAig(state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_HRA_SCORE':
      return Object.assign({}, state, {
        hraDetails: action.payload,
      });
    case 'UPDATE_HRA_TO_TATA_AIG':
      return Object.assign({}, state, {
        hraUpdatedToTataAig: action.payload,
      });
    case 'UPDATE_GOOGLE_FITNESS_STATE':
      return Object.assign({}, state, {
        googleFitnessConnected: action.payload,
      });
    case 'UPDATE_FITNESS_TO_TATA_AIG':
      return Object.assign({}, state, {
        updatedFitnessToTataAig: action.payload,
      });
    case 'UPDATE_HRA_TAKEN': 
      return Object.assign({}, state, {
        hraTaken: action.payload,
      })
    default:
      return state;
  }
}
