import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../../../containers/common/Modal';
import { FadeInTransition } from '../../transitions/transitions';
import Tick from '../../../images/consultations/new-flow/tick.svg';
import Calendar from '../../../images/consultations/new-flow/calendar.svg';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const Sub1 = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: #585969;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const ConfirmText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #7d1414;
  padding-bottom: 60px;
  border-bottom: 1px solid #f0f3f6;
`;

const Buttons = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #0f0b28;
  cursor: pointer;
`;

export default class ConfirmModal extends Component {
  state = {
    showUploadOption: true,
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  hideModal = () => {
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  render() {
    const { showUploadOption } = this.state;
    const { reschedule, heading } = this.props;
    return (
      <Modal>
        <ModalContainer onClick={this.handleOutsideClick}>
          <FadeInTransition
            in={showUploadOption}
            mountOnEnter
            appear
            timeout={200}
          >
            <div
              className="bg-textbox flex flex-col items-center rounded-12 mx-12px"
              ref={(node) => (this.node = node)}
            >
              <div className="p-24px">
                <div className="flex flex-row ">
                  <img
                    src={reschedule ? Calendar : Tick}
                    style={{ borderRadius: '4px', marginRight: '4px' }}
                  />
                  <p>{reschedule ? 'Reschedule' : 'Reconfirmation'}</p>
                </div>
                <Sub1>
                  {reschedule
                    ? 'Are you sure that you want to reschedule this appointment to a different time slot?'
                    : heading
                    ? heading
                    : 'Patient reconfirmation is mandatory for payment purpose.'}
                </Sub1>
                {this.props.confirmText && (
                  <div className="w-full">
                    <Sub1>
                      Do not forget to carry the prescription after your
                      consultation with the doctor
                    </Sub1>
                    <ConfirmText
                      dangerouslySetInnerHTML={{
                        __html: this.props.confirmText,
                      }}
                    ></ConfirmText>
                  </div>
                )}
                <div className="flex flex-row justify-around items-center mt-12px">
                  <Buttons onClick={this.hideModal}>Cancel</Buttons>
                  <div
                    style={{
                      borderRight: '2px solid #f0f3f6',
                      height: '34px',
                    }}
                  ></div>
                  <Buttons
                    onClick={() => {
                      if (reschedule) {
                        this.props.openSlotSelection();
                      } else {
                        this.props.reconfirmRequest();
                      }
                    }}
                  >
                    Confirm
                  </Buttons>
                </div>
              </div>
            </div>
          </FadeInTransition>
        </ModalContainer>
      </Modal>
    );
  }
}
