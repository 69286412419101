import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { RegularText } from '../../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 0.625rem 0.75rem;
  background: rgba(228,220,248,0.2);
  border-radius: 0.875rem;
`;

const MessageText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #413958;
  word-break: break-word;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 0.25rem;
`;

const TimestampText = styled.p`
 font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #a5aab0;
`;

const UserMessage = ({ message, timestamp }) => (
  <OuterContainer>
    <BubbleContainer>
      <MessageText>{message}</MessageText>
      {timestamp && (
        <TimestampContainer>
          <TimestampText>
            {new Date(timestamp).toLocaleTimeString('nu', {
              hour: 'numeric',
              minute: '2-digit',
            })}
          </TimestampText>
        </TimestampContainer>
      )}
    </BubbleContainer>
  </OuterContainer>
);

UserMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserMessage;
