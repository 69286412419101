import React, { Component } from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import LoadingComponent from '../../components/common/LoadingComponent';
import { Text } from '../../components/common/Text';
// import CaloriesActive from '../../images/home/CaloriesActive.svg';
// import CaloriesNon from '../../images/home/CaloriesNon.svg';
// import MealActive from '../../images/home/MealActive.svg';
// import MealNon from '../../images/home/MealNon.svg';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import axios from 'axios';
import config from '../../config';
import { connect } from 'react-redux';
import Modal from '../common/Modal';
// import DetailedGraphs from './DetailedGraph';
// import * as d3 from 'd3';
import MealLogContainer from './MealLogContainer';
import TotalIcon from '../../images/meal-log/total-icon.svg';
import AverageIcon from '../../images/meal-log/average.svg';
import GoalIcon from '../../images/meal-log/goal.svg';
import HealthIcon from '../../images/health-content/health-icon.svg';
import CloseIcon from '../../images/health-content/close-icon.svg';
import LeftIcon from '../../images/health-content/left-arrow.svg';
import RightIcon from '../../images/health-content/right-arrow.svg';

const Container = styled.div`
  @media (max-width: 375px) {
    height: 400px;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  font-style: normal;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 0;
  z-index: 10;
  user-select: none;
  justify-content: space-between;
`;

const BackContainer = styled(Tappable)`
  margin-right: 12px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
  color: #ffffff;
  font-weight: 700px;
`;

const BodyContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
`;

const IconsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: -1px;
  user-select: none;
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #5a32e2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  cursor: pointer;
  opacity: ${(props) => (props.active ? '100%' : '70%')};
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
  padding: 12px;
  border-radius: 50%;
  object-fit: contain;
  cursor: pointer;
`;

const SelectedItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: -1px;
`;
const DatesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 6px;
  border-radius: 12px;
`;

const Dates = styled.div`
  width: 50%;
  color: #21243c;
  cursor: pointer;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => (props.active ? '#0F0B28' : '#FFFFFF')};
  padding: 7px;
  border-radius: 8px;
`;

const VisibleDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 24px;
  margin-top: -1px;
`;

const RightArrowIcon = styled(FaAngleRight)`
  color: #21243c;
  font-size: 26px;
  cursor: pointer;
`;

const LeftArrowIcon = styled(FaAngleLeft)`
  color: #21243c;
  font-size: 26px;
  cursor: pointer;
`;

const VisibleDate = styled.div`
  color: #21243c;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
`;

const LoadingModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
`;

export class NewMealLogContainer extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.state = {
      loading: false,
      selectedItemType: 'steps',
      selectedDateCondition: 'Daily',
      selectedDateType: 'day',
      date: new Date(),
      selectedDateTimestamp: today.valueOf(),
      weeklyStartDate: new Date(),
      weeklyEndDate: new Date(),
      month: new Date(),
      modalLoading: false,
      data: null,
      currentDate: new Date(),
      showRightArrow: false,
      stepsGoal: 0,
      sleepGoal: 0,
      sleepGoalMin: null,
      distanceObj: {
        distanceGoal: 0,
        distanceCovered: 0,
        stepsPerKm: 0,
      },
    };
    const { location } = this.props;
    if (location && location.state) {
      const obj = location.state;
      if (
        obj.selectedItem === 'Meal Coverage' &&
        obj.from &&
        obj.from === 'MealLog'
      ) {
        this.state.selectedItem = obj.selectedItem;
      } else {
        const distanceGoal = (
          obj.distanceObj.distanceGoal / obj.distanceObj.stepsPerKm
        ).toFixed(2);
        const distanceCovered = 0;
        this.state.distanceObj = {
          distanceGoal: distanceGoal,
          distanceCovered: distanceCovered,
          stepsPerKm: obj.distanceObj.stepsPerKm,
        };
        this.state.stepsGoal = obj.stepsGoal;
        this.state.sleepGoal = obj.sleepGoal;
        this.state.sleepGoalMin = obj.sleepGoalMin;
        if (obj.selectedItem === 'Sleep Hours') {
          this.state.selectedItem = obj.selectedItem;
          this.state.selectedItemType = 'sleep';
        } else {
          this.state.selectedItem = obj.selectedItem;
        }
      }
    }
  }

  componentDidMount() {
    this.generateWeeklyDates(new Date());
    this.checkDates(this.state.date);
    this.generateData(this.state.date);
    // window.DetailedGraph();
  }

  componentDidUpdate() {
    console.log(this.state.distanceObj);
  }

  getDataForGeneratingGraph = (type, frequency, timestamp) => {
    if (window.Android !== undefined && window.Android.getDataToGenerateGraph) {
      window.Android.getDataToGenerateGraph(
        String(type),
        String(frequency),
        Number(timestamp)
      );
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'getDataToGenerateGraph',
          type: type,
          frequency: frequency,
          timestamp: timestamp,
        })
      );
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'GET_DATA_TO_GENERATE_GRAPH',
          type: type,
          frequency: frequency,
          timestamp: Number(timestamp),
        })
      );
    }
  };

  checkDates = (latestDate) => {
    let p = this.state.currentDate;
    const c = latestDate;
    if (c >= p) {
      this.setState({ showRightArrow: false });
    } else {
      this.setState({ showRightArrow: true });
    }
  };

  checkWeeklyDates = (startDate, endDate) => {
    if (
      this.state.currentDate >= startDate &&
      this.state.currentDate <= endDate
    ) {
      this.setState({
        showRightArrow: false,
      });
    } else {
      this.setState({
        showRightArrow: true,
      });
    }
  };

  generateWeeklyDates = (date) => {
    let curr = date;
    let first = curr.getDate() - ((curr.getDay() + 6) % 7);
    let last = first + 6;
    let firstday = new Date(curr.setDate(first));
    let lastday = new Date(curr.setDate(last));
    this.setState({
      weeklyStartDate: firstday,
      weeklyEndDate: lastday,
    });
    this.checkWeeklyDates(firstday, lastday);
  };

  decreaseDate = () => {
    let presentDate = this.state.date;
    let decreasedDate = new Date(presentDate);
    decreasedDate.setDate(decreasedDate.getDate() - 1);
    this.setState({ date: decreasedDate });
    this.generateData(decreasedDate);
    this.checkDates(decreasedDate);
    const { selectedDateTimestamp } = this.state;
    this.setState({
      selectedDateTimestamp: selectedDateTimestamp - 1000 * 60 * 60 * 24,
    });
  };

  increaseDate = () => {
    let presentDate = this.state.date;
    let currentDate = new Date();
    const p_day = presentDate.getDate();
    const p_month = presentDate.getMonth();
    const p_year = presentDate.getFullYear();
    const p = p_day + '-' + p_month + '-' + p_year;
    const c_day = currentDate.getDate();
    const c_month = currentDate.getMonth();
    const c_year = currentDate.getFullYear();
    const c = c_day + '-' + c_month + '-' + c_year;
    let increasedDate = new Date(presentDate);
    increasedDate.setDate(increasedDate.getDate() + 1);
    this.setState({ date: increasedDate });
    this.generateData(increasedDate);
    this.checkDates(increasedDate);
    const { selectedDateTimestamp } = this.state;
    this.setState({
      selectedDateTimestamp: selectedDateTimestamp + 1000 * 60 * 60 * 24,
    });
  };

  increaseWeekly = () => {
    let presentStartDate = this.state.weeklyStartDate;
    let presentEndDate = this.state.weeklyEndDate;
    let increasedStartDate = new Date(presentStartDate);
    let increasedEndDate = new Date(presentEndDate);
    increasedStartDate.setDate(increasedStartDate.getDate() + 7);
    increasedEndDate.setDate(increasedEndDate.getDate() + 7);
    this.setState({
      weeklyStartDate: increasedStartDate,
      weeklyEndDate: increasedEndDate,
    });
    this.generateData(increasedStartDate);
    this.checkWeeklyDates(increasedStartDate, increasedEndDate);
  };

  decreaseWeekly = () => {
    let presentStartDate = this.state.weeklyStartDate;
    let presentEndDate = this.state.weeklyEndDate;
    let decreasedStartDate = new Date(presentStartDate);
    let decreasedEndDate = new Date(presentEndDate);
    decreasedStartDate.setDate(decreasedStartDate.getDate() - 7);
    decreasedEndDate.setDate(decreasedEndDate.getDate() - 7);
    this.setState({
      weeklyStartDate: decreasedStartDate,
      weeklyEndDate: decreasedEndDate,
    });
    this.generateData(decreasedStartDate);
    this.checkWeeklyDates(decreasedStartDate, decreasedEndDate);
  };

  increaseMonthly = () => {
    let currentMonth = this.state.month.getMonth();
    let nextMonth = new Date(this.state.month.setMonth(currentMonth + 1));
    this.setState({ month: nextMonth });
    this.generateData(nextMonth);
    this.checkDates(nextMonth);
  };

  decreaseMonthly = () => {
    let currentMonth = this.state.month.getMonth();
    let previousMonth = new Date(this.state.month.setMonth(currentMonth - 1));
    this.setState({ month: previousMonth });
    this.generateData(previousMonth);
    this.checkDates(previousMonth);
  };

  generateData = (date) => {
    // this.setState({ modalLoading: true });
    const type = this.state.selectedItemType;
    const frequency = this.state.selectedDateType;
    let timestamp;
    if (frequency === 'day') {
      timestamp = date;
    } else if (frequency === 'week') {
      timestamp = date;
    } else if (frequency === 'month') {
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      timestamp = firstDay;
    }
    console.log(type, frequency, timestamp);
    this.getDataForGeneratingGraph(type, frequency, timestamp);
  };

  // generateSleepData = async (date) => {
  //   const time = date.valueOf();
  //   console.log(time);
  //   const type = this.state.selectedDateType;
  //   const userId = this.props.userId;
  //   await axios
  //     .get(
  //       `${config.apiBaseUrl}/fitness/views/detailed-activity?t=sleep&f=${type}&st=${time}&userId=${userId}`
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       this.setState({ modalLoading: false, data: response.data });
  //     })
  //     .catch((err) => {
  //       this.setState({ modalLoading: false });
  //       console.log(err);
  //     });
  // };

  getBgColor = (type) => {
    switch (type) {
      case 'Meal Coverage':
        return '#06c2a9';
      case 'Sleep Hours':
        return '#3A2CA0';
      default:
        return '#4D75E7';
    }
  };

  getBgColorForIcon = (type) => {
    switch (type) {
      case 'Meal Coverage':
        return '#99E6DC';
      case 'Sleep Hours':
        return '#6d59ff';
      default:
        return '#013ADE';
    }
  };

  render() {
    return (
      <div className="overflow-auto w-full">
        {this.state.loading ? (
          <div className="p-20 center">
            <LoadingComponent />
          </div>
        ) : (
          <OuterContainer>
            <div
              className="background-transition"
              style={{ backgroundColor: '#1EB865' }}
            >
              <HeaderContainer>
                <div className="flex">
                  <img src={HealthIcon}></img>
                  <HeaderTitleContainer>
                    <p className="font-bold text-title-m text-textbox ml-12px">
                      Your Health Data
                    </p>
                  </HeaderTitleContainer>
                </div>
                <BackContainer onTap={() => this.props.history.goBack()}>
                  <img src={CloseIcon}></img>
                </BackContainer>
              </HeaderContainer>
              <div>
                <div className="p-20px px-30px">
                  <DatesContainer>
                    <Dates
                      style={
                        this.state.selectedDateCondition === 'Daily'
                          ? {
                              color: '#0F0B28',
                              background: '#ffffff',
                            }
                          : {
                              color: '#ffffff',
                            }
                      }
                      onClick={() => {
                        this.setState(
                          {
                            selectedDateCondition: 'Daily',
                            selectedDateType: 'day',
                          },
                          () => {
                            this.generateData(this.state.date);
                            this.checkDates(this.state.date);
                          }
                        );
                      }}
                    >
                      Daily
                    </Dates>
                    <Dates
                      style={
                        this.state.selectedDateCondition === 'Weekly'
                          ? {
                              color: '#0F0B28',
                              background: '#ffffff',
                            }
                          : {
                              color: '#ffffff',
                            }
                      }
                      onClick={() => {
                        this.setState(
                          {
                            selectedDateCondition: 'Weekly',
                            selectedDateType: 'week',
                          },
                          () => {
                            this.generateData(this.state.weeklyStartDate);
                            this.checkWeeklyDates(
                              this.state.weeklyStartDate,
                              this.state.weeklyEndDate
                            );
                          }
                        );
                      }}
                    >
                      Weekly
                    </Dates>
                  </DatesContainer>
                </div>
                <VisibleDateContainer>
                  {this.state.selectedDateCondition === 'Daily' && (
                    <>
                      <button onClick={this.decreaseDate}>
                        <img src={LeftIcon} alt="previous"></img>
                      </button>
                      {/* <LeftArrowIcon onClick={this.decreaseDate} /> */}
                      <VisibleDate>
                        {this.state.date.getDate() +
                          '   ' +
                          this.state.date.toLocaleString('default', {
                            month: 'long',
                          })}
                      </VisibleDate>
                      {this.state.showRightArrow ? (
                        <button onClick={this.increaseDate}>
                          <img src={RightIcon} alt="next"></img>
                        </button>
                      ) : (
                        <div style={{ visibility: 'hidden' }}>
                          <RightArrowIcon />
                        </div>
                      )}
                    </>
                  )}
                  {this.state.selectedDateCondition === 'Weekly' && (
                    <div className="w-full flex flex-col items-center">
                      <div className=" w-full justify-between flex">
                        <button onClick={this.decreaseWeekly}>
                          <img src={LeftIcon} alt="previous"></img>
                        </button>{' '}
                        <VisibleDate>
                          {this.state.weeklyStartDate.getDate() +
                            '   ' +
                            this.state.weeklyStartDate.toLocaleString(
                              'default',
                              {
                                month: 'long',
                              }
                            ) +
                            ' - ' +
                            this.state.weeklyEndDate.getDate() +
                            '   ' +
                            this.state.weeklyEndDate.toLocaleString('default', {
                              month: 'long',
                            })}
                        </VisibleDate>
                        {this.state.showRightArrow ? (
                          <button onClick={this.increaseWeekly}>
                            <img src={RightIcon} alt="next"></img>
                          </button>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>
                            <RightArrowIcon />
                          </div>
                        )}
                      </div>
                      <Container className="w-full h-full flex flex-col justify-between mt-12px overflow-y-auto items-center">
                        <iframe
                          frameborder="0"
                          title="weekly-meal-graph"
                          height="900px"
                          width="100%"
                          src={`${
                            config.apiBaseUrl
                          }/fitness/views/detailed-activity?t=nutrition&f=week&st=${+new Date(
                            this.state.weeklyStartDate
                          )}&userId=${this.props.userId}`}
                        ></iframe>
                      </Container>
                    </div>
                  )}
                </VisibleDateContainer>
              </div>
            </div>
            <BodyContainer className="p-0">
              {this.state.selectedDateCondition === 'Daily' ? (
                <div
                  className="w-full"
                  style={{ width: '100%', height: '100%' }}
                >
                  <MealLogContainer
                    history={this.props.history}
                    removeHeader={true}
                    selectedDateTimestamp={this.state.selectedDateTimestamp}
                  />
                </div>
              ) : (
                // <DetailedGraphs
                //   type={this.state.selectedItemType}
                //   frequency={this.state.selectedDateType}
                //   stepsGoal={this.state.stepsGoal}
                //   sleepGoal={this.state.sleepGoal}
                //   distanceObj={this.state.distanceObj}
                //   sleepGoalMin={this.state.sleepGoalMin}
                // />
                <></>
              )}
            </BodyContainer>
            {this.state.modalLoading && (
              <Modal>
                <LoadingModalContainer>
                  <div className="p-20 center">
                    <LoadingComponent />
                  </div>
                </LoadingModalContainer>
              </Modal>
            )}
          </OuterContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(NewMealLogContainer);
