import app from './app';
import user from './user';
import chat from './chat';
import botChat from './bot';
import video from './video';
import tataAig from './tata-aig';

export default {
  app,
  user,
  chat,
  botChat,
  video,
  tataAig
};
