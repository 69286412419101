import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import GoogleFitnessLogo from '../../images/common/google-fitness-logo.png';
import AppleHealthLogo from '../../images/detailed-graph/apple_fit.svg';
import BlueTickIcon from '../../images/home/blue-tick.svg';
import heroIcon from '../../images/home/tracker-hero.svg';
import FitbitIcon from '../../images/health-data/fitbit-icon.svg';

const OuterContainer = styled.div`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
`;

const ActivityTrackingContainer = styled.div`
  padding-bottom: 16px;
`;

const Header = styled.div`
  width: 100%;
`;

const HeaderName = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  display: inline-block;
  color: #0f0b28;
`;

const HeaderTag = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #585969;
`;

const Inspect = styled.p`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 4px;
  background: #f0f3f6;
  border-radius: 50%;
  cursor: pointer;
  color: #3f8cff;
  float: right;
`;

const Tick = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: #35488a;
  border-radius: 62px;
  color: #ffffff;
  margin-right: 8px;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #0f0b28;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  padding-bottom: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #303e4f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  border-top: 1px solid rgba(165, 165, 165, 0.1);
`;

const FitnessLogo = styled.img`
  width: 24px;
  height: 20px;
  margin-right: 10px;
`;

export class ActivityTracker extends Component {
  componentDidMount() {
    console.log(this.props);
  }
  // connectToFitness = (e) => {
  //   e.stopPropagation();
  //   console.log('Connecting to Android SDK');
  //   if (window.Android !== undefined && window.Android.connectToGoogleFit) {
  //     window.Android.connectToGoogleFit();
  //   } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
  //     window.webkit.messageHandlers.visitIosView.postMessage(
  //       JSON.stringify({
  //         method: 'connectToGoogleFit',
  //       })
  //     );
  //   } else if (window.ReactNativeWebView) {
  //     console.log('Connecting to Android SDK Fitness APP');
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         method: 'CONNECT_TO_GOOGLE_FIT',
  //       })
  //     );
  //   } else {
  //     this.props.history.push('/stay-active');
  //   }
  // };
  // connectToFitbits = (e) => {
  //   e.stopPropagation();
  //   console.log('Connecting to Android SDK');
  //   if (window.Android !== undefined && window.Android.connectToFitbit) {
  //     window.Android.connectToFitbit();
  //   } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
  //     window.webkit.messageHandlers.visitIosView.postMessage(
  //       JSON.stringify({
  //         method: 'connectToFitbit',
  //       })
  //     );
  //   } else if (window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         method: 'connectToFitbit',
  //       })
  //     );
  //   } else if (window.visitIosView) {
  //     window.visitIosView.postMessage(
  //       JSON.stringify({
  //         method: 'connectToFitbit',
  //       })
  //     );
  //   } else {
  //     this.props.history.push('/stay-active');
  //   }
  // };

  redirectToConnectionPage = (e) => {
    e.stopPropagation();
    this.props.history.push('/stay-active');
  };

  render() {
    const { user, iosUser } = this.props;
    return (
      <OuterContainer>
        <ActivityTrackingContainer onClick={() => {}}>
          <div className="flex items-center justify-between">
            <div className="p-20px">
              <Header>
                <HeaderName>Hi {user.userName}!</HeaderName>
                {/* <Inspect>i</Inspect> */}
              </Header>
              <HeaderTag>Sync your steps today</HeaderTag>
              <InfoContainer>
                <img src={BlueTickIcon} alt="tick" className="mr-4px" />
                Track your activity
              </InfoContainer>
              <InfoContainer>
                <img src={BlueTickIcon} alt="tick" className="mr-4px" />
                Stay fit
              </InfoContainer>
              <InfoContainer style={{ fontWeight: '600' }}>
                <img src={BlueTickIcon} alt="tick" className="mr-4px" />
                Earn{' '}
                <span
                  style={{
                    color: '#C6B15F',
                    marginLeft: '3px',
                    fontWeight: '600',
                  }}
                >
                  Rewards
                </span>
              </InfoContainer>
            </div>
            <div className="flex flex-col items-center p-20px">
              <img src={heroIcon} alt="hero-icon" />
            </div>
          </div>
          <div className="flex w-full">
            {/* <Button className="w-6/12 " onClick={this.connectToFitness}>
              <FitnessLogo
                src={iosUser ? AppleHealthLogo : GoogleFitnessLogo}
                width="30px"
                height="30px"
              />
              <p style={{ fontWeight: '600', fontSize: '14px' }}>
                {iosUser ? 'Sync Apple Health' : 'Sync Google Fit'}
              </p>
            </Button>
            <div className="border-r border-txtlight-shades4 mt-12px"></div>
            <Button className="w-6/12" onClick={this.connectToFitbits}>
              <FitnessLogo src={FitbitIcon} width="30px" height="30px" />
              <p style={{ fontWeight: '600', fontSize: '14px' }}>Sync FitBit</p>
            </Button> */}
            <Button onClick={this.redirectToConnectionPage}>
              <p style={{ fontWeight: '600', fontSize: '14px' }}>
                Sync Fitness Data
              </p>
            </Button>
          </div>
        </ActivityTrackingContainer>
      </OuterContainer>
    );
  }
}

export default withRouter(ActivityTracker);
