const config = {
  pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
  pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
  rootUrl: 'https://tata-aig.getvisitapp.net/',
  // apiBaseUrl: 'https://api.samuraijack.xyz/edith',
  // apiBaseUrl: 'http://localhost:5001',
  //apiBaseUrl: 'https://api.getvisitapp.xyz/goku',
  apiBaseUrl: 'https://insurance-uat.getvisitapp.net/odin',
  surveyUrl: 'https://az1.survey2connect.com/R/a287119d?langKey=eng_us',
  opdPurchaseUrl: 'https://opd.samuraijack.xyz',
  websiteBaseUrl: 'https://samuraijack.xyz/',
  pharmaServer: 'https://api.getvisitapp.net/absol',
  version: '161',
  amplitudeApiKey: '2bfbcb774f5fd6372197d0e43fbda536',
  opentokApiKey: '45467122',
  clevertapId: '585-5KR-KR6Z',
  tataApiUrl: 'https://uathealthvas.tataaig.com',
  tataToken: 'Basic Z2V0X3Zpc2l0OkZoNjh2JHdqaHU4WWd3NiQ=',
  apiBaseUrlOld: 'https://api.samuraijack.xyz/v3',
  ticketingBaseUrl: 'https://help.getvisitapp.xyz/v4',
  freeVerticalsList: [11, 23, 24],
  localStorageEncryptionKey: 'visit-pwa@1.1',
  localStorageEncryptionSalt: '87e7c119e0abb08197327867035bd22e',
};
export default config;
