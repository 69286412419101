import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ShieldText from './ShieldText';
import OnboardingImage from '../../images/health-content/onboarding.svg';
import OnboardingBackground from '../../images/health-content/onboarding-background.svg';
import {
  getMyFitnessQueries,
  submitAnswerToQuery,
} from '../../services/health-content';
import ScalePicker from './ScalePicker';

const OuterContainer = styled.div`
  background: white;
`;

const InnerContainer = styled.div`
  overflow-y: scroll;
  background-image: url(${OnboardingBackground});
`;

export default function MyFitnessOnboarding() {
  const { userName, authToken } = useSelector((state) => state.user);
  const [query, setQuery] = useState(null);
  const [totalQueries, setTotalQueries] = useState(0);

  const history = useHistory();

  function updateQueryStatus(data) {
    setQuery(data.details);
    setTotalQueries(data.totalQueries);
  }

  async function navigateToQuestions() {
    const data = await getMyFitnessQueries(authToken);
    updateQueryStatus(data);
  }

  function Questions({ query = {}, totalQueries }) {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [scaleValue, setScaleValue] = useState(0);
    const [scalePoints, setScalePoints] = useState({
      start: 0,
      end: 0,
      unit: '',
    });

    useEffect(() => {
      if (query.queryType === 'scale' && selectedAnswers.length === 0) {
        if (query.options?.length) {
          const [{ optionId }] = query.options;
          setSelectedAnswers([optionId]);
        }
      }
      // eslint-disable-next-line
    }, [query]);

    useEffect(() => {
      if (selectedAnswers.length && query.queryType === 'scale') {
        const option = query.options.find(
          (q) => q.optionId === selectedAnswers[0]
        );
        setScaleValue(Math.floor(option.median));
        setScalePoints({
          unit: option.unit,
          start: Number(option.floor),
          end: Number(option.ceil),
        });
      }
      // eslint-disable-next-line
    }, [selectedAnswers]);

    async function submitAnswer() {
      try {
        const payload = {
          queryId: query.queryId,
          optionIds: selectedAnswers,
        };
        query.queryType === 'scale' && (payload.value = scaleValue);
        const nextQuery = await submitAnswerToQuery(authToken, payload);
        if (nextQuery?.message === 'planCreated') {
          return history.replace('step-weekly-plan');
        }
        updateQueryStatus(nextQuery);
      } catch (err) {
        console.log(err);
      }
    }

    function handleAnswerClick(optionId, type) {
      if (['single', 'scale'].includes(type)) {
        return setSelectedAnswers([optionId]);
      }

      /**
       * If Query is type of Multi
       * on multi, So toggling option in possible
       */
      let answers;
      if (selectedAnswers.includes(optionId)) {
        answers = selectedAnswers.filter((option) => option !== optionId);
      } else {
        answers = [...selectedAnswers, optionId];
      }
      setSelectedAnswers(answers);
    }

    function QueryOptions({ type, options }) {
      options = options.filter((o) => o.unit !== 'feet');

      if (['single', 'multi'].includes(type)) {
        return options.map((option) => (
          <div
            key={option.optionId}
            onClick={() => handleAnswerClick(option.optionId, type)}
            className={`rounded-lg my-4 shadow-card border border-2 flex justify-between cursor-pointer ${
              selectedAnswers.includes(option.optionId)
                ? 'border-primary-button'
                : 'border-txtlight-shades4'
            }`}
          >
            <div className="h-60px pl-15 flex flex-col justify-center">
              <h1 className="text-body-l font-semibold">{option.title}</h1>
              {option.label && (
                <p className="text-chip text-sub">({option.label})</p>
              )}
            </div>
            {option.icon && (
              <img src={option.icon} alt={option.title} className="w-1/3"></img>
            )}
          </div>
        ));
      }
      if (type === 'scale') {
        return (
          <div className="w-120">
            <div className="w-210 flex flex-row justify-center m-auto text-button-s font-medium flex text-button-l py-7">
              {options.map((option) => {
                return (
                  <div
                    key={option.optionId}
                    className={`rounded-xl px-9 py-3 ml-10 cursor-pointer capitalize ${
                      selectedAnswers.includes(option.optionId)
                        ? 'bg-primary text-textbox'
                        : 'bg-txtlight-shades4 text-sub'
                    }`}
                    onClick={() => handleAnswerClick(option.optionId, type)}
                  >
                    {option.unit}
                  </div>
                );
              })}
            </div>
            <div className="text-center my-10">
              <span className="text-header-xl font-bold">{scaleValue}</span>
              <span>{scalePoints.unit}</span>
            </div>
            <ScalePicker
              start={scalePoints.start}
              end={scalePoints.end}
              value={scaleValue}
              onChange={(value) => setScaleValue(value)}
            />
          </div>
        );
      }
      return <></>;
    }

    return (
      <div className="p-10">
        <div className="w-20 bg-customE5E9FE h-1 rounded-full">
          <div
            className="bg-primary h-1 rounded-full"
            style={{ width: `${(query.queryId / totalQueries) * 100}%` }}
          ></div>
        </div>
        <h1 className="font-bold text-title-leading mt-20">{query.query}</h1>
        {query.queryType !== 'scale' && (
          <p className="text-tab font-medium text-sub mt-10">
            We'll personalize your recommendations based on your goals
          </p>
        )}
        <div className="overflow-y-auto h-80 mt-30">
          <QueryOptions type={query.queryType} options={query.options} />
        </div>
        {Boolean(selectedAnswers.length) && (
          <button
            onClick={submitAnswer}
            className="bg-primary text-textbox w-100 rounded-lg py-3 text-caption-l font-semibold mt-50"
          >
            Next &nbsp;&gt;
          </button>
        )}
      </div>
    );
  }

  function IntroScreen() {
    return (
      <div className="flex flex-col justify-center items-center">
        <h1 className="font-bold text-title-xl mb-3">Hi {userName},</h1>
        <p className="text-body-s font-medium text-center text-secondaryText p-5">
          Before you begin, We have some <br /> questions to understand you
          better
        </p>
        <div className="border-b border-black p-8 pb-0 w-60 mb-50">
          <img src={OnboardingImage} alt="onboarding" className="w-100"></img>
        </div>

        <button
          className="w-100 bg-primary py-3 rounded-lg font-semibold font-body-l text-textbox"
          onClick={navigateToQuestions}
        >
          Start
        </button>
        <ShieldText />
      </div>
    );
  }

  return (
    <OuterContainer className="w-100 h-100">
      <InnerContainer className="px-12 py-28 w-100">
        {query ? (
          <Questions query={query} totalQueries={totalQueries} />
        ) : (
          <IntroScreen />
        )}
      </InnerContainer>
    </OuterContainer>
  );
}
