import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Tappable from 'react-tappable/lib/Tappable';

import { submitUserDetails } from '../../actions/index';
import { Text, BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';

import contactGraphic from '../../images/onboarding/one-last-step.svg';
import maleIcon from '../../images/onboarding/male.png';
import femaleIcon from '../../images/onboarding/female.png';
import DobInput from '../../components/common/DobInput';
import otherIcon from '../../images/onboarding/others.png';
const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
`;

const InputContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-top: 1.625rem;
  margin-bottom: ${(props) => (props.addBottomMargin ? '1.625rem' : '0')};
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const StretchedInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid black !important;
  padding: 0.33rem 0;
`;

const StyledDobInput = styled(DobInput)`
  flex: 1;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1 0 auto;
`;

const LabelText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 0.75rem;
`;

const GendersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const GenderContainer = styled(Tappable)`
  border: 1px solid #b8b8b8;
  border-color: ${(props) => (props.active ? '#3F8CFF' : '#B8B8B8')};
  border-radius: 12px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-top: 8px;
  @media (max-width: 768px) {
    padding: 8px;
    margin-right: 12px;
  }
  &.Tappable-active {
    opacity: 0.5;
  }
`;

const GenderIcon = styled.img`
  width: 1.56rem;
  margin-right: 0.625rem;
  @media (max-width: 768px) {
  width: 1rem;
  }
`;

const GenderText = styled(Text)`
  font-size: 14px;
  color: ${(props) => (props.active ? '#3F8CFF' : '#808080')};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

class OneLastStep extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      nameValue: props.userName || '',
      loading: false,
      dob: props.dateOfBirth || '',
      gender: (props.userGender && props.userGender.toLowerCase()) || '',
    };
  }

  componentDidMount = () => {
    const { history, loginState, authToken } = this.props;
    console.log(history);
    if (loginState === 'LOGGED_OUT' || !authToken) {
      history.push('/');
    } else if (loginState === 'LOGGED_IN') {
      history.replace('/home');
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      window.gtag('event', 'screen_view', {
        screen_name: 'One Last Step Screen',
      });
    }
  };

  componentDidUpdate = () => {
    // otp error then change loading back to false
    const { loading } = this.state;
    const { hasError } = this.props.appError;
    if (loading && hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  submitUserDetails = () => {
    this.setState({
      loading: true,
    });
    const { dispatch } = this.props;
    const history = { ...this.props.history };
    const { gender, nameValue, dob } = this.state;
    dispatch(submitUserDetails(nameValue, dob, gender, history));
  };

  onDobSet = (dob) => {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    this.setState({
      dob: (new Date(dob - tzoffset)).toISOString().split('T')[0],
    });
  };

  render() {
    const nameValueLink = Link.state(this, 'nameValue');
    const { gender, nameValue, dob } = this.state;
    const disabled =
      nameValue.length === 0 || dob.trim().length === 0 || gender.length === 0;
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon src={contactGraphic} alt="Phone" />
          </HeaderContainer>
          <TitleText>One Last Step</TitleText>
          <InputContainer>
            <StretchedInputBox
              type="text"
              className="visit-input"
              valueLink={nameValueLink}
              placeholder="Name"
            />
          </InputContainer>
          <InputContainer>
            <StyledDobInput onChange={this.onDobSet} dob={dob} />
          </InputContainer>
          <InputContainer>
            <AgeContainer>
              <LabelText>Gender</LabelText>
              <GendersContainer>
                <GenderContainer
                  active={this.state.gender === 'male'}
                  onTap={() => this.setState({ gender: 'male' })}
                >
                  <GenderIcon src={maleIcon} />
                  <GenderText active={this.state.gender === 'male'}>
                    Male
                  </GenderText>
                </GenderContainer>
                <GenderContainer
                  active={this.state.gender === 'female'}
                  onTap={() => this.setState({ gender: 'female' })}
                >
                  <GenderIcon src={femaleIcon} />
                  <GenderText active={this.state.gender === 'female'}>
                    Female
                  </GenderText>
                </GenderContainer>
                <GenderContainer
                  active={this.state.gender === 'other'}
                  onTap={() => this.setState({ gender: 'other' })}
                >
                  <GenderIcon src={otherIcon} />
                  <GenderText active={this.state.gender === 'other'}>
                    Others
                  </GenderText>
                </GenderContainer>
              </GendersContainer>
            </AgeContainer>
          </InputContainer>

          <div className="text-body-l text-txtlight font-semibold mb-32px">
            <span> By continuing, you agree to our</span>
            <span
              className="text-primary cursor-pointer"
              onClick={() => {
                window.location.href = 'https://getvisitapp.com/privacy.html';
              }}
            >
              {' '}
              Terms of Use and Privacy Policy
            </span>
          </div>
          <PrimaryButton
            disabled={disabled}
            onTap={this.submitUserDetails}
            loading={this.state.loading}
          >
            <StyledButtonText>continue</StyledButtonText>
          </PrimaryButton>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
  authToken: state.user.authToken,
  userName: state.user.userName,
  userGender: state.user.userGender,
  dateOfBirth: state.user.dateOfBirth,
});

export default connect(mapStateToProps)(OneLastStep);
