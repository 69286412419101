import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  FaChevronRight,
  FaChevronLeft,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';

import Header from '../../components/common/Header';
import StyledTappable from '../../components/common/StyledTappable';
import {
  Text,
  ButtonText,
  BoldText,
  RegularText,
} from '../../components/common/Text';
import ProgressBar from '../../components/common/ProgressBar';
import { fontSize } from '../../utils';
import { PrimaryButton } from '../../components/common/Buttons';
import LoadingComponent from '../../components/common/LoadingComponent';
import MealSelectContainer from './MealSelectContainer';

import mealLogIcon from '../../images/meal-log/meallogicon.png';
import carbsIcon from '../../images/meal-log/carbs.png';
import fatsIcon from '../../images/meal-log/fats.png';
import proteinsIcon from '../../images/meal-log/proteins.png';
import breakfastIcon from '../../images/meal-log/breakfast.svg';
import morningsnacksIcon from '../../images/meal-log/morningsnacks.svg';
import lunchIcon from '../../images/meal-log/lunch.svg';
import eveningsnacksIcon from '../../images/meal-log/eveningsnacks.svg';
import trashIcon from '../../images/lab-test/trash2.svg';
import dinnerIcon from '../../images/meal-log/dinner.svg';
import Tick from '../../images/common/caution.svg';
import AddIcon from '../../images/meal-log/add-icon.svg';
import { getNutritionDetails, removeMeal } from '../../services';
import { logEvent } from '../../utils/logEvent';
import Modal from '../common/Modal';
import {
  FlyInTransition,
  FadeInTransition,
} from '../../components/transitions/transitions';
import ConfirmModal from '../../components/consultations/new-flow/ConfirmModal';
import TotalIcon from '../../images/meal-log/total-icon.svg';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  background: #ffffff;
  padding: 20px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 16px;
  padding-top: 0;
`;

const ArrowIcon = styled(FaChevronLeft)`
  color: #212121;
`;

const DateSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

const DateText = styled(Text)`
  font-size: 14px;
  margin-right: 0.5rem;
`;

const TotalCalorieContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
`;

const TotalCalorieText = styled(BoldText)`
  font-size: 20px;
  color: #2a2a2a;
  font-weight: bold;
  margin: 8px 0;
`;

const RemainingCalorieText = styled(RegularText)`
  font-size: 14px;
  color: #2a2a2a;
`;

const NutritionContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

const MealLogIcon = styled.img`
  width: 30px;
  height: 38px;
`;

const NutrientContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
`;

const NutrientIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const NutrientText = styled(BoldText)`
  font-size: 14px;
  color: #2a2a2a;
  margin-top: 16px;
`;

const NutrientLabel = styled(RegularText)`
  font-size: 14px;
  color: #2a2a2a;
  margin-top: 10px;
`;

const StyledButton = styled(PrimaryButton)`
  background: #ffffff;
  margin: 32px auto;
  border-radius: 54px;
  padding: 10px 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;

const LogTitleText = styled(BoldText)`
  font-size: 16px;
  text-align: center;
  margin-bottom: 14px;
`;

const MealContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  border-top: 1px solid #7d7d80;
  padding: 12px;
`;

const LogLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const LogTitle = styled(Text)`
  font-size: 14px;
`;

const LogIcon = styled.img`
  width: 18px;
  height: 13px;
  margin-right: 15px;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CalorieText = styled(RegularText)`
  font-size: 14px;
  color: #808080;
`;

const ExpandIcon = styled(FaChevronUp)`
  color: #3c3c3c;
  font-size: 12px;
  margin-left: 10px;
`;

const MealText = styled(RegularText)`
  font-size: 14px;
  color: #4c4c4b;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const Sub1 = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: #585969;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f3f6;
`;

const Buttons = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #0f0b28;
  cursor: pointer;
`;

const getImageforMeal = (meal) => {
  switch (meal) {
    case 'Breakfast':
      return breakfastIcon;
    case 'Morning Snacks':
      return morningsnacksIcon;
    case 'Lunch':
      return lunchIcon;
    case 'Evening Snacks':
      return eveningsnacksIcon;
    case 'Dinner':
      return dinnerIcon;
    default:
      return breakfastIcon;
  }
};

const getColorforMacro = (macro) => {
  switch (macro) {
    case 'totalCarbs':
      return 'red';
    case 'totalFat':
      return 'blue';
    case 'totalProtein':
      return 'orange';
  }
};

class MealLogContainer extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.state = {
      loading: true,
      // selectedDateTimestamp: this.props.selectedDateTimestamp,
      mealData: [],
      selectedMeal: '',
      totalCals: 0,
      remainingCals: 0,
      totalProgress: 0,
      totalCarbs: 0,
      carbsGoal: 0,
      carbsProgress: 0,
      totalProtein: 0,
      proteinGoal: 0,
      proteinProgress: 0,
      totalFats: 0,
      fatsGoal: 0,
      fatsProgress: 0,
      showMealSelectModal: false,
      confirmModalDetails: { showModal: false, mealLogId: '', mealName: '' },
    };
  }

  componentDidMount() {
    logEvent('Meal Tracking Dashboard Card Detail Screen');
    this.getNutritionData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedDateTimestamp !== this.props.selectedDateTimestamp ||
      (prevState.confirmModalDetails.showModal &&
        !this.state.confirmModalDetails.showModal)
    ) {
      this.getNutritionData();
    }
  }

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  hideModal = () => {
    this.setState({
      showMealSelectModal: false,
      confirmModalDetails: { showModal: false, mealLogId: '' },
    });
  };

  getNutritionData = () => {
    const { userId, authToken } = this.props;
    const { selectedDateTimestamp } = this.props;
    this.setState({ loading: true });
    getNutritionDetails(selectedDateTimestamp, userId, authToken)
      .then((res) => {
        console.log(res);
        this.setState({
          loading: false,
          mealData: res.meals,
          totalCals: res.totalToday,
          remainingCals: res.remainingCals,
          totalProgress: res.userProgress,
          totalCarbs: res.totalMacroNutrients.totalCarbohydrates,
          carbsGoal: res.carbsGoal,
          carbsProgress: res.carbsProgress,
          totalProtein: res.totalMacroNutrients.totalProtein,
          proteinGoal: res.proteinGoal,
          proteinProgress: res.proteinProgress,
          totalFats: res.totalMacroNutrients.totalFat,
          fatsGoal: res.fatsGoal,
          fatsProgress: res.fatsProgress,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setPreviousDate = () => {
    const { selectedDateTimestamp } = this.state;
    this.setState(
      {
        selectedDateTimestamp: selectedDateTimestamp - 1000 * 60 * 60 * 24,
      },
      () => {
        this.getNutritionData();
      }
    );
  };

  setNextDate = () => {
    const { selectedDateTimestamp } = this.state;
    this.setState(
      {
        selectedDateTimestamp: selectedDateTimestamp + 1000 * 60 * 60 * 24,
      },
      () => {
        this.getNutritionData();
      }
    );
  };

  expandMeal(mealTitle) {
    this.setState({
      selectedMeal: mealTitle,
    });
  }

  removeMeal = () => {
    const { authToken } = this.props;
    removeMeal(this.state.confirmModalDetails.mealLogId, authToken).then(() =>
      this.setState({
        confirmModalDetails: { showModal: false, mealLogId: '' },
      })
    );
  };

  logMeal = () => {
    const { selectedDateTimestamp } = this.state;
    const { history } = this.props;
    // history.push('/logmeal/select', {
    //   dayTimestamp: selectedDateTimestamp,
    // });
    this.setState({ showMealSelectModal: true });
  };

  render() {
    const {
      loading,
      //   selectedDateTimestamp,
      totalCals,
      remainingCals,
      totalProgress,
      totalCarbs,
      carbsGoal,
      carbsProgress,
      totalProtein,
      proteinGoal,
      proteinProgress,
      totalFats,
      fatsGoal,
      fatsProgress,
      mealData,
    } = this.state;
    const { selectedDateTimestamp } = this.props;
    const renderDateText = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      switch (today.valueOf() - selectedDateTimestamp) {
        case 0:
          return 'Today';
        case 1000 * 60 * 60 * 24:
          return 'Yesterday';
        default: {
          const date = new Date(selectedDateTimestamp);
          const options = {
            day: 'numeric',
            month: 'long',
          };
          return `${date.toLocaleDateString('nu', options)}`;
        }
      }
    };

    const renderMealItem = (ele) => {
      return (
        <LogLine>
          <MealText>{`${ele.dishName} x ${ele.quantity}`}</MealText>
          <CalorieText>{`${ele.totalCalories} Cal`}</CalorieText>
        </LogLine>
      );
    };

    const renderMeal = (ele) => {
      //   const isExpanded = this.state.selectedMeal === ele.title;
      //   console.log(ele);
      return (
        // <MealContainer>
        //   <LogLine as={StyledTappable} onTap={() => this.expandMeal(ele.title)}>
        //     <RowContainer>
        //       <LogIcon src={getImageforMeal(ele.title)} />
        //       <LogTitle>{ele.title}</LogTitle>
        //     </RowContainer>
        //     <RowContainer>
        //       <CalorieText>{`${ele.total} Cal`}</CalorieText>
        //       {isExpanded ? <ExpandIcon /> : <ExpandIcon as={FaChevronDown} />}
        //     </RowContainer>
        //   </LogLine>
        //   {isExpanded &&
        //     (ele.items.length === 0 ? (
        //       <LogLine>
        //         <MealText>No Meals Logged yet</MealText>
        //       </LogLine>
        //     ) : (
        //       ele.items.map(renderMealItem)
        //     ))}
        // </MealContainer>
        <>
          {ele.items.length > 0 ? (
            <div
              className="rounded-12  mb-16px p-20px"
              style={{ boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <div className="flex justify-between items-center font-bold text-txtlight mb-8px text-title-s">
                <p>{ele.title}</p>
                <p>
                  {ele.total} <span className="font-regular">kcal</span>
                </p>
              </div>
              <div
                className="flex flex-wrap text-body-s mb-8px"
                style={{ color: '#808080' }}
              >
                {ele.items.map((item) => (
                  <div className="flex flex-wrap mr-8px">
                    <p className="mr-4px">
                      {item.dishName} x {item.quantity}
                    </p>
                    <img
                      src={trashIcon}
                      alt="delete"
                      width={'18px'}
                      height={'18px'}
                      className="cursor-pointer"
                      onClick={() =>
                        this.setState({
                          confirmModalDetails: {
                            showModal: true,
                            mealLogId: item.mealLogId,
                            mealName: `${item.dishName} x ${item.quantity}`,
                          },
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className="flex items-center text-body-s"
                style={{ color: '#808080' }}
              >
                {Object.entries(ele.macros).map(([key, value]) => (
                  <div className="flex items-center mr-8px">
                    <div
                      style={{
                        width: '6px',
                        height: '3px',
                        background: (() => {
                          return getColorforMacro(key);
                        })(),
                        marginRight: '4px',
                      }}
                    ></div>
                    <p>{value} g</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="bg-txtsecondary-shades4 rounded-12 border border-solid border-txtsecondary-shades4 mb-16px p-20px">
              <div className="flex justify-between items-center font-bold text-txtlight text-title-s">
                <p>{ele.title}</p>
                <p>
                  {ele.total === '-' ? 0 : ele.total}
                  <span className="font-regular ml-8px">kcal</span>
                </p>
              </div>
            </div>
          )}
        </>
      );
    };
    return (
      <OuterContainer>
        <BodyContainer>
          {loading ? (
            <LoadingComponent />
          ) : (
            <>
              <NutritionContainer style={{ justifyContent: 'center' }}>
                <div className="flex flex-col justify-center items-center mb-8px">
                  <img src={TotalIcon}></img>
                  <TotalCalorieContainer>
                    {/* <TotalCalorieText>{`${totalCals} of ${
                      totalCals + remainingCals
                    } Cals`}</TotalCalorieText> */}
                    <TotalCalorieText>{`${totalCals} Calories Consumed`}</TotalCalorieText>
                    <RemainingCalorieText as="span">
                      {`${remainingCals}`} left for the day
                    </RemainingCalorieText>
                  </TotalCalorieContainer>
                </div>
              </NutritionContainer>
              <ProgressBar width={`${totalProgress * 100}%`} height="6px" />
              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #F0F3F6',
                  marginTop: '32px',
                  marginBottom: '20px',
                }}
              ></div>
              <NutritionContainer>
                <NutrientContainer>
                  {/* <NutrientIcon src={carbsIcon} /> */}
                  {/* <NutrientText>{`${totalCarbs}/${carbsGoal}g`}</NutrientText> */}
                  <NutrientText>
                    {totalCarbs}g{' '}
                    <span className="font-regular text-sub">Carbs</span>
                  </NutrientText>
                  <NutrientText
                    style={{
                      marginTop: '0px',
                      fontWeight: '400',
                      color: '#767787',
                      fontSize: '12px',
                    }}
                  >{`${carbsGoal}g Goal`}</NutrientText>
                  <ProgressBar
                    height="3px"
                    marginTop="8px"
                    width={`${carbsProgress * 100}%`}
                    color="#FF7268"
                  />
                </NutrientContainer>
                <NutrientContainer>
                  {/* <NutrientIcon src={proteinsIcon} /> */}
                  {/* <NutrientText>{`${totalProtein}/${proteinGoal}g`}</NutrientText> */}
                  <NutrientText>
                    {`${totalProtein}g`}{' '}
                    <span className="font-regular text-sub">Protein</span>
                  </NutrientText>
                  <NutrientText
                    style={{
                      marginTop: '0px',
                      fontWeight: '400',
                      color: '#767787',
                      fontSize: '12px',
                    }}
                  >{`${proteinGoal}g Goal`}</NutrientText>
                  <ProgressBar
                    height="3px"
                    width={`${proteinProgress * 100}%`}
                    marginTop="8px"
                    color="#244CB2"
                  />
                </NutrientContainer>
                <NutrientContainer>
                  {/* <NutrientIcon src={fatsIcon} /> */}
                  {/* <NutrientText>{`${totalFats}/${fatsGoal}g`}</NutrientText> */}
                  <NutrientText>
                    {`${totalFats}g`}{' '}
                    <span className="font-regular text-sub">Fats</span>
                  </NutrientText>
                  <NutrientText
                    style={{
                      marginTop: '0px',
                      fontWeight: '400',
                      color: '#767787',
                      fontSize: '12px',
                    }}
                  >{`${fatsGoal}g Goal`}</NutrientText>
                  <ProgressBar
                    height="3px"
                    width={`${fatsProgress * 100}%`}
                    marginTop="8px"
                    color="#FFA800"
                  />
                </NutrientContainer>
              </NutritionContainer>
              <StyledButton onTap={this.logMeal}>
                <div className="flex justify-center items-center">
                  <img src={AddIcon} alt="add" />
                  <ButtonText
                    style={{ padding: 0, marginLeft: '8px', color: 'black' }}
                  >
                    Log Meal
                  </ButtonText>
                </div>
              </StyledButton>
              <div
                style={{
                  borderBottom: '1px solid #EDEDF0',
                  width: '100%',
                  marginBottom: '16px',
                }}
              ></div>
              {mealData.map(renderMeal)}
            </>
          )}
          {this.state.showMealSelectModal && (
            <Modal>
              <div className="modalContainer" onClick={this.handleOutsideClick}>
                <FlyInTransition
                  in={this.state.showMealSelectModal}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <div className="modalBody">
                    <div className="modalDetails">
                      <MealSelectContainer
                        selectedDateTimestamp={this.props.selectedDateTimestamp}
                        history={this.props.history}
                      />
                    </div>
                  </div>
                </FlyInTransition>
              </div>
            </Modal>
          )}
          {this.state.confirmModalDetails.showModal && (
            <Modal>
              <ModalContainer onClick={this.handleOutsideClick}>
                <FadeInTransition
                  in={this.state.confirmModalDetails.showModal}
                  mountOnEnter
                  appear
                  timeout={200}
                >
                  <div
                    className="bg-textbox flex flex-col items-center rounded-12 mx-12px"
                    ref={(node) => (this.node = node)}
                  >
                    <div className="p-24px pb-16px px-0">
                      <div className="flex flex-row px-24px">
                        <img
                          src={Tick}
                          style={{ borderRadius: '4px', marginRight: '4px' }}
                          width={'16px'}
                          height={'16px'}
                          alt="delete"
                        />
                        <p>Confirm Delete</p>
                      </div>
                      <Sub1 className="px-24px">
                        {`Are you sure you want to remove "${this.state.confirmModalDetails.mealName}" from meal log`}
                      </Sub1>
                      <div className="flex flex-row justify-around items-center mt-12px px-24px">
                        <Buttons onClick={this.hideModal}>Cancel</Buttons>
                        <div
                          style={{
                            borderRight: '2px solid #f0f3f6',
                            height: '28px',
                          }}
                        ></div>
                        <Buttons
                          onClick={() => {
                            this.removeMeal(
                              this.state.confirmModalDetails.mealLoginId
                            );
                          }}
                        >
                          Confirm
                        </Buttons>
                      </div>
                    </div>
                  </div>
                </FadeInTransition>
              </ModalContainer>
            </Modal>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(MealLogContainer);
