import React, { Component } from 'react';
import Modal from '../../containers/common/Modal';
import { FadeInTransition } from '../transitions/transitions';
import styled from 'styled-components';
import cautionIcon from '../../images/common/caution.svg';

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

export class MessageModal extends Component {
  state = {
    showUploadOption: true,
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };
  hideModal = () => {
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  render() {
    const { showUploadOption } = this.state;
    return (
      <Modal>
        <ModalContainer onClick={this.handleOutsideClick}>
          <FadeInTransition
            in={showUploadOption}
            mountOnEnter
            appear
            timeout={200}
          >
            <div
              className="bg-textbox flex flex-col items-center rounded-12"
              ref={(node) => (this.node = node)}
              style={{ width: '90%', maxWidth: '500px' }}
            >
              <div className="p-24px mb-16px">
                <div className="mb-12px flex flex-row">
                  <img
                    src={cautionIcon}
                    alt="caution"
                    className="mr-8px"
                    height={'18px'}
                    width={'18px'}
                  />
                  <p className="text-title-m font-semibold text-txtlight">
                    {this.props.header || 'Important'}
                  </p>
                </div>

                <p className="text-body-l font-regular text-brochure">
                  {this.props.message}
                </p>
              </div>
              <div
                className="border-t border-txtsecondary-shades4 hover w-full text-center pt-16px pb-16px text-title-s font-semibold text-txtlight"
                onClick={this.hideModal}
              >
                Got it
              </div>
            </div>
          </FadeInTransition>
        </ModalContainer>
      </Modal>
    );
  }
}

export default MessageModal;
