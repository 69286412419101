import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

export function getNutritionDetails(dayTimestamp, userId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/fitness/views/detailed-activity`, {
      headers,
      params: {
        t: 'nutrition',
        f: 'day',
        st: dayTimestamp,
        userId,
        json: true,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      const responseCode = resp.status;
      if (responseCode === 200 || responseCode === 304) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDishSuggestions(text, mealType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/nutrition/dishes-new/search/${text}`, {
      headers,
      params: {
        mealType,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'resultsFound') {
        return responseBody;
      } else if (responseBody.message === 'noResults') {
        responseBody.suggestions = [];
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveMeal(dayTimestamp, dishes, mealType, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    dishes,
    timestampInMillis: dayTimestamp,
    mealType,
  };

  return axios
    .post(`${config.apiBaseUrl}/nutrition/meal`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'Success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function removeMeal(mealLogId, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    mealLogId,
  };

  return axios
    .post(`${config.apiBaseUrl}/nutrition/remove-meal`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'mealLogRemoved') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
