import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'valuelink';

import { BoldText, ButtonText } from '../../components/common/Text';
import ProgressBar from '../../components/common/ProgressBar';
import { PrimaryButton } from '../../components/common/Buttons';
import { fontSize } from '../../utils';
import {
  getHraForm,
  submitHraResponse,
  goToPreviousHra,
  resetHra,
} from '../../services';
import MultipleSelect from '../../components/hra/MultipleSelect';
import SingleSelect from '../../components/hra/SingleSelect';
import StringInput from '../../components/hra/StringInput';
import StartScreen from './HraStartContainer';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import backIcon from '../../images/hra/back.svg';
import nextIcon from '../../images/hra/next.svg';
import arrowLeft from '../../images/common/arrow-left.svg';
import { connect } from 'react-redux';
import {
  fetchHraScoreAndClass,
  updateHraAndGoogleFitDetailsToTata,
} from '../../services/tata-aig';
import LoadingComponent from '../../components/common/LoadingComponent';
import {
  updateHraScore,
  updateHraTaken,
  updateHraToTataAig,
} from '../../actions/index';
import Tooltip from '../../components/common/Tooltip';
import SurveyModal from '../consultation/SurveyModal';
import config from '../../config';
import FeedBack from '../../images/feedback.svg';
import { trueGray } from '@tailwindcss/postcss7-compat/colors';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const HeaderTitle = styled(BoldText)`
  font-size: ${fontSize(2)};
  margin-bottom: 10px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const QuestionText = styled(BoldText)`
  font-size: ${fontSize(1)};
  margin: 14px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px;
  width: 100%;
`;

const HraColor = {
  'Low Risk': 'green',
  'Moderate Risk': 'yellow',
  'High Risk': 'red',
};

class HraQuestionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentQuestion: null,
      end: false,
      userResponse: null,
      stringResponse: '',
      showInfo: false,
      age: '',
      gender: '',
      updatingScoreToTataAig: false,
      userAge: this.props.userAge,
      showTooltip: false,
      showSurveyModal: false,
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { history, hraDetails, hraUpdatedToTataAig, hraTaken } = this.props;
    if (this.props.config.showUpdatedHra) {
      this.setState({
        showInfo: true,
      });
    }
    console.log('here');
    if (hraDetails && !hraUpdatedToTataAig && hraTaken) {
      this.setState({ loading: true });
      if (window.Android && window.Android.pendingHraUpdation) {
        window.Android.pendingHraUpdation();
      } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
        window.webkit.messageHandlers.visitIosView.postMessage(
          JSON.stringify({
            method: 'pendingHraUpdation',
          })
        );
      }
      window.updateHraToAig = () => {
        this.setState({ updatingScoreToTataAig: true });
        console.log(
          'updating the hra score to Tata Aig after failure and user relogin',
          hraDetails
        );
        this.updateHraAndGoogleFitDetails(hraDetails);
      };
    } else {
      getHraForm()
        .then((res) => {
          if (res.message === 'success') {
            console.log('man');
            this.setState({
              currentQuestion: res.question,
            });
            if (res.age && res.question.questionId === 2 && !res.userResponse) {
              console.log('man1');

              this.setState({
                stringResponse: String(res.age),
              });
            }
            if (
              res.gender &&
              res.question.questionId === 1 &&
              !res.userResponse
            ) {
              console.log('man2');

              this.setState({
                userResponse: String(res.gender),
              });
            }
          } else if (res.message === 'end') {
            console.log('ending-1');
            this.setState({ showSurveyModal: true });
            history.replace('/hra/end');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  hideSurveyModal = () => {
    console.log('survey modal is closed');
    this.setState({
      showSurveyModal: false,
    });
    const editedModuleName = `${this.props.moduleName}_vas_visit`;
    const type = 'Booking';
    const url = `${config.surveyUrl}&mobileno=${this.props.mobileNumber}&policyno=${this.props.policyNumber}&feature=${editedModuleName}&type=${type}`;

    if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'openExternalLink',
          link: url,
        })
      );
    } else if (window.Android) {
      window.Android.openExternalLink(url);
    } else {
      window.open(url);
    }
  };
  findOptionIndex(option) {
    const { userResponse } = this.state;
    console.log(userResponse, option);
    if (!userResponse || !Array.isArray(userResponse)) {
      return -1;
    }
    return userResponse.findIndex((ele) => ele === option);
  }

  setResponse = (res) => {
    const { type } = this.state.currentQuestion;
    const { userResponse } = this.state;
    console.log(type, res);
    switch (type) {
      case 'multiple_select': {
        const index = this.findOptionIndex(res);
        if (index === -1) {
          console.log('-1');
          // Add the response
          if (res === 'None of the above') {
            this.setState({
              userResponse: [res],
            });
          } else if (Array.isArray(userResponse)) {
            const updatedResponses = userResponse.filter(
              (ele) => ele !== 'None of the above'
            );
            this.setState({
              userResponse: updatedResponses.concat([res]),
            });
          } else {
            this.setState({
              userResponse: [res],
            });
          }
        } else {
          // Remove the response
          console.log('Remove the response');
          const updatedUserResponse = Array.from(userResponse);
          updatedUserResponse.splice(index, 1);
          console.log(updatedUserResponse);
          this.setState({
            userResponse: updatedUserResponse,
          });
        }
        break;
      }
      case 'single_select': {
        this.setState({
          userResponse: res,
        });
        break;
      }
      default:
        break;
    }
  };

  submitResponse = (res) => {
    const { userResponse, stringResponse, currentQuestion } = this.state;
    const { history, userAge, userGender } = this.props;
    if (currentQuestion.questionId === 3) {
      if (!(Number(stringResponse) >= 5 && Number(stringResponse) <= 250)) {
        this.setState({ showTooltip: true });
        return;
      }
    }
    if (currentQuestion.questionId === 4) {
      if (!(Number(stringResponse) >= 10 && Number(stringResponse) <= 500)) {
        this.setState({ showTooltip: true });
        return;
      }
    }
    this.setState({
      userResponse: '',
      stringResponse: '',
      showTooltip: false,
    });
    const body = {
      questionId: currentQuestion.questionId,
      questionOrder: currentQuestion.questionOrder,
      choice:
        res ||
        (currentQuestion.type === 'text_input'
          ? currentQuestion.questionId === '2'
            ? userAge
            : stringResponse
          : currentQuestion.questionId === '1'
          ? userGender
          : userResponse),
      type: currentQuestion.type,
      parentOrder: currentQuestion.parentOrder,
      conditional: currentQuestion.conditional,
      title: currentQuestion.title,
    };

    submitHraResponse(body)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            currentQuestion: res.question,
            userResponse: res.userResponse || '',
            stringResponse:
              res.question.type === 'text_input' && res.userResponse
                ? res.userResponse
                : '',
          });
          if (res.age && res.question.questionId === 2 && !res.userResponse) {
            this.setState({
              stringResponse: String(res.age),
            });
          }
          if (
            res.gender &&
            res.question.questionId === 1 &&
            !res.userResponse
          ) {
            this.setState({
              userResponse: String(res.gender),
            });
          }
          this.hraQuestionAnswered(
            res.question.questionId - 1,
            res.question.totalQuestions
          );
        } else if (res.message === 'end') {
          this.setState({ loading: true });
          setTimeout(() => {
            console.log('After 2 seconds');
            this.hraCompletedHandler();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  goToPrevious = () => {
    const { parentOrder, questionOrder } = this.state.currentQuestion;
    const { history } = this.props;
    this.containerRef.current.scrollTo(0, 0);
    goToPreviousHra(parentOrder, questionOrder)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            currentQuestion: res.question,
            userResponse: res.userResponse,
            stringResponse:
              res.question.type === 'text_input' && res.userResponse
                ? res.userResponse
                : '',
          });
        } else if (res.message === 'end') {
          console.log('ending-2');
          history.replace('/hra/end');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetHra = () => {
    const { history } = this.props;
    resetHra()
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            end: false,
            currentQuestion: res.question,
            userResponse: '',
            stringResponse: '',
          });
        } else if (res.message === 'end') {
          console.log('ending-3');
          history.replace('/hra/end');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeViewForHraFailed = () => {
    if (window.Android && window.Android.closeView) {
      window.Android.closeView(true);
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'closeView',
          tataUser: true,
        })
      );
    }
  };
  updateHraAndGoogleFitDetails = (body, flag = true) => {
    console.log(body, 'hereeee');
    updateHraAndGoogleFitDetailsToTata(body)
      .then(() => {
        console.log('Hra Completed and syncing to Tata Android');
        if (window.Android !== undefined && window.Android.hraCompleted) {
          window.Android.hraCompleted();
        } else if (
          window.webkit &&
          window.webkit.messageHandlers.visitIosView
        ) {
          window.webkit.messageHandlers.visitIosView.postMessage(
            JSON.stringify({
              method: 'hraCompleted',
            })
          );
        }
        this.props.dispatch(updateHraToTataAig(true));
      })
      .catch((err) => {
        this.setState({ loading: false, updatingScoreToTataAig: false });
        this.props.dispatch(updateHraToTataAig(false));
        console.log(err, 'errrrr');
        if (window.Android !== undefined && window.Android.hraInComplete) {
          window.Android.hraInComplete(JSON.stringify(body), false);
        } else if (
          window.webkit &&
          window.webkit.messageHandlers.visitIosView
        ) {
          window.webkit.messageHandlers.visitIosView.postMessage(
            JSON.stringify({
              method: 'hraInComplete',
              data: JSON.stringify(body),
              flag: false,
            })
          );
        }
      })
      .finally(() => {
        this.setState({ loading: false, updatingScoreToTataAig: false });
        if (!flag) {
          console.log('ending-4');
          this.setState({ showSurveyModal: true });
          this.props.history.replace('/hra/end', { showSurvey: true });
        } else {
          this.closeViewForHraFailed();
        }
      });
  };

  hraCompletedHandler = () => {
    fetchHraScoreAndClass()
      .then((response) => {
        console.log(response);
        const color = HraColor[response.HRARisk];
        const body = {
          member_id: this.props.memberId,
          hra_details: {
            color,
            score: response.HRAScore,
          },
        };
        this.props.dispatch(updateHraTaken(true));
        this.props.dispatch(updateHraScore(body));
        this.updateHraAndGoogleFitDetails(body, false);
      })
      .catch((err) => {
        console.log(err, 'errrrr');
        this.setState({ loading: false });
      });
  };

  hraQuestionAnswered = (currentCount, totalCount) => {
    console.log(
      'So far the user answered these many questions',
      currentCount,
      totalCount
    );
    if (window.Android !== undefined && window.Android.hraQuestionAnswered) {
      window.Android.hraQuestionAnswered(
        Number(currentCount),
        Number(totalCount)
      );
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'hraQuestionAnswered',
          current: currentCount,
          total: totalCount,
        })
      );
    }
  };

  render() {
    const {
      currentQuestion,
      userResponse,
      showInfo,
      loading,
      updatingScoreToTataAig,
    } = this.state;
    console.log(this.props.userGender, this.props.userAge);
    if (updatingScoreToTataAig) {
      return (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div>
            <LoadingComponent />
          </div>
          <p className="ml-12px text-body-l text-txtlight font-semibold pt-12px">
            Updating your HRA score, Please wait...
          </p>
        </div>
      );
    }
    if (loading) {
      return <LoadingComponent />;
    }
    if (!currentQuestion) {
      return false;
    }
    const {
      currentOrder,
      questionOrder,
      parentOrder,
      title,
      text,
      type,
      options,
      totalQuestions,
      info,
      questionId,
    } = currentQuestion;
    if (questionOrder === 1 && parentOrder === 0) {
      return <StartScreen submitResponse={this.submitResponse} />;
    }
    console.log(userResponse);
    const responseLink = Link.state(this, 'stringResponse');
    const progress = ((currentOrder - 1) / totalQuestions) * 100;
    const { userGender, userAge } = this.props;
    const ageValue = Link.state(this, 'userAge');
    const renderResponse = () => {
      switch (type) {
        case 'single_select':
          return (
            <SingleSelect
              value={questionId === 1 ? userGender : userResponse}
              options={options}
              setResponse={this.setResponse}
              disabled={questionId === 1}
            />
          );
        case 'multiple_select':
          return (
            <MultipleSelect
              value={userResponse}
              options={options}
              setResponse={this.setResponse}
            />
          );
        case 'text_input':
          console.log();
          return (
            <StringInput
              value={questionId === 2 ? ageValue : responseLink}
              numeric={true}
              disabled={questionId === 2 && userAge}
            />
          );
        default:
          return;
      }
    };
    return (
      <div
        className="font-pop overflow-y-scroll custom-scrollbar"
        ref={this.containerRef}
      >
        <div>
          <div
            className="p-24px relative"
            style={{
              background: '#0b2f5e',
            }}
          >
            <img
              src={arrowLeft}
              height={'32px'}
              width={'32px'}
              alt={'back'}
              style={{ filter: 'invert(100%)', cursor: 'pointer' }}
              onClick={() => {
                console.log('closeView');
                if (window.Android && window.Android.closeView) {
                  window.Android.closeView(true);
                } else if (
                  window.webkit &&
                  window.webkit.messageHandlers.visitIosView
                ) {
                  window.webkit.messageHandlers.visitIosView.postMessage(
                    JSON.stringify({
                      method: 'closeView',
                      tataUser: true,
                    })
                  );
                }
              }}
            />
            {this.props.config.hraLogo && this.props.config.showUpdatedHra ? (
              <img
                src={this.props.config.hraLogo}
                style={{
                  height: '60px',
                  borderRadius: '10px',
                  position: 'absolute',
                  right: '24px',
                }}
              />
            ) : (
              ''
            )}
            <div className="flex flex-row justify-between pt-76px">
              <p className="text-title-xl font-semibold text-textbox mb-16px">
                {title}
              </p>
            </div>
            <ProgressBar
              width={`${progress}%`}
              height="10px"
              gradient={['#fff', '#fff']}
            />
          </div>
          <BodyContainer className="p-24px pt-36px overflow-y-auto">
            <p className="text-title-m font-semibold text-txtlight mb-24px">
              {text}
            </p>
            {renderResponse()}
            {this.state.showSurveyModal && (
              <SurveyModal
                hideModal={this.hideSurveyModal}
                modalHeading={`How was your experience ?`}
                modalTitle={`Please rate our services. This would help us make your experience even better`}
                buttonText={'Share Feedback'}
                modalImg={FeedBack}
              />
            )}
            {this.state.showTooltip && (
              <Tooltip
                showTooltip={this.state.showTooltip}
                content="The value that you have put is out of range"
              ></Tooltip>
            )}
            {info ? (
              <div className="mt-24px">
                <div
                  className="text-body-l font-medium btn p-0"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="info"
                  onClick={() => {
                    this.setState({
                      showInfo: !showInfo,
                    });
                  }}
                  style={{
                    color: '#0b2f5e',
                    fontWeight: '700',
                  }}
                >
                  See Information
                </div>
                {showInfo ? (
                  <div className="collapse mt-12px" id="info">
                    <p className="text-txtlight font-medium text-body-l">
                      {info}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="mt-24"></div>
            )}
          </BodyContainer>
        </div>
        <div
          className=" w-100"
          style={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <ButtonsContainer>
            <PrimaryButton
              color=" #e2edff"
              onTap={this.goToPrevious}
              className="hra-button"
            >
              <div className="flex flex-row  items-center">
                {' '}
                <img
                  src={backIcon}
                  alt="back"
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />
                <p className="ml-12px text-body-l text-txtlight font-semibold">
                  Back
                </p>
              </div>
            </PrimaryButton>
            <PrimaryButton
              color="#0b2f5e"
              onTap={() => {
                if (
                  userResponse ||
                  (this.state.stringResponse &&
                    this.state.stringResponse.trim().length > 0)
                ) {
                  this.submitResponse();
                  this.containerRef.current.scrollTo(0, 0);
                } else {
                  return null;
                }
              }}
              className={`${
                userResponse ||
                (this.state.stringResponse &&
                  this.state.stringResponse.trim().length > 0)
                  ? ''
                  : 'opacity-50'
              } hra-button`}
              style={{ borderRadius: '40px !important' }}
            >
              <div className={`flex flex-row text-textbox items-center`}>
                <p className="mr-12px text-body-l text-textbox font-semibold">
                  Next
                </p>
                <img
                  src={nextIcon}
                  alt="back"
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />{' '}
              </div>
            </PrimaryButton>
          </ButtonsContainer>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.app.config,
    memberId: state.app.memberId,
    hraDetails: state.tataAig.hraDetails,
    hraUpdatedToTataAig: state.tataAig.hraUpdatedToTataAig,
    hraTaken: state.tataAig.hraTaken,
    userGender: state.user.userGender,
    userAge: state.user.userAge,
    policyNumber: state.user.policyNumber,
    mobileNumber: state.user.userPhone,
    moduleName: state.app.moduleName,
  };
};

export default connect(mapStateToProps)(HraQuestionContainer);
