import React from 'react';
import Weight from '../../images/health-content/weight.svg';
import ActiveWeight from '../../images/health-content/active-weight.svg';
import BloodPressure from '../../images/health-content/blood-pressure.svg';
import ActiveBloodPressure from '../../images/health-content/active-blood-pressure.svg';
import BloodSugar from '../../images/health-content/blood-sugar.svg';
import ActiveBloodSugar from '../../images/health-content/active-blood-sugar.svg';
import hbA1c from '../../images/health-content/hbA1c.svg';
import ActiveHbA1c from '../../images/health-content/active-hbA1c.svg';
import { useHistory } from 'react-router-dom';

const NavigationBar = ({ active }) => {
  const history = useHistory();

  return (
    <div className="w-full flex px-32px p-8px justify-between">
      <Icon
        onClick={() => history.replace('/health-content/weight-data')}
        active={active === 'weight'}
        text="Weight"
      >
        {active !== 'weight' && <img src={Weight} />}
        {active === 'weight' && <img src={ActiveWeight} />}
      </Icon>
      <Icon
        onClick={() => history.replace('/health-content/blood-pressure-data')}
        active={active === 'blood-pressure'}
        text="Blood Pressure"
      >
        {active !== 'blood-pressure' && <img src={BloodPressure} />}
        {active === 'blood-pressure' && <img src={ActiveBloodPressure} />}
      </Icon>
      <Icon
        onClick={() => history.replace('/health-content/blood-sugar-data')}
        active={active === 'blood-sugar'}
        text="Blood Sugar"
      >
        {active !== 'blood-sugar' && <img src={BloodSugar} />}
        {active === 'blood-sugar' && <img src={ActiveBloodSugar} />}
      </Icon>
      <Icon
        onClick={() => history.replace('/health-content/hbA1c-data')}
        active={active === 'hbA1c'}
        text="hbA1c"
      >
        {active !== 'hbA1c' && <img src={hbA1c} />}
        {active === 'hbA1c' && <img src={ActiveHbA1c} />}{' '}
      </Icon>
    </div>
  );
};

const Icon = ({ children, active, text, onClick }) => (
  <button
    className="p-12px py-10px rounded-12 flex items-center justify-center text-body-s font-semibold"
    style={{
      background: active ? 'white' : 'rgba(0, 0, 0, 0.2)',
      padding: active ? '8px auto' : '',
      boxShadow: active ? ' 0px 5px 20px rgba(0, 0, 0, 0.1)' : '',
    }}
    onClick={onClick}
  >
    {children}
    {active && <span className="ml-6px">{active ? text : null}</span>}
  </button>
);

export default NavigationBar;
