import styled from 'styled-components';

import ArrowRight from '../../images/health-content/arrow-right.svg';
import PageIcon from '../../images/health-content/page-icon.svg';
import arrowBack from '../../images/arrow_back.svg';
import WorkoutCard from './WorkoutCard';
import WatchIcon from '../../images/health-content/watch-icon.svg';
import WeightIcon from '../../images/health-content/weight-sub-icon.svg';
import ArcheryIcon from '../../images/health-content/archery-icon.svg';
import { useEffect, useState } from 'react';
import { getWeeklyPlan } from '../../services/health-content';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Card = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
`;

function TargetCard({ title, targets }) {
  return (
    <Card className="overflow-hidden mb-9">
      <div className="bg-green-shades2 bg-opacity-10 p-4 px-4">
        <h3 className="text-sub text-body-s">
          <img src={ArcheryIcon} alt="archery-arrow" className="inline" />
          &nbsp;Goal:&nbsp;
          <span className="text-black font-medium">{title}</span>
        </h3>
      </div>
      <div className="p-4 text-caption-l">
        {targets.map((target) => (
          <p className="mb-2">
            <img
              height="16px"
              width="16px"
              src={target.icon}
              alt="heat"
              className="inline mr-2"
            />
            {target.title}: {target.value}
          </p>
        ))}
      </div>
    </Card>
  );
}

function WorkoutTracker({ tracks }) {
  return tracks.map((track, index) => (
    <div className="my-4 flex">
      <div className="flex flex-col mr-15 w-6 items-center">
        <div
          className={`border-4 border-${
            track.current ? 'primary' : 'customC4C4C4'
          } rounded-full `}
          style={{ height: '20px', width: '18.594px' }}
        ></div>
        {tracks.length - 1 !== index && (
          <div
            className={`h-100 rounded border border-${
              tracks[index + 1].current ? 'primary' : 'customC4C4C4'
            } w-0 mt-2`}
          ></div>
        )}
      </div>
      <WorkoutCard
        index={index}
        heading={track.title}
        label={track.label}
        targets={track.targets}
      />
    </div>
  ));
}

export default function StepWeeklyPlan() {
  const history = useHistory();
  function continueOnboarding() {
    history.push('/health-content/fitness-home');
  }

  const { authToken } = useSelector((state) => state.user);

  const [plan, setPlan] = useState([]);
  const [targetCard, setTargetCard] = useState(null);
  const [loading, setLoading] = useState(true);

  const getPlan = async () => {
    const data = await getWeeklyPlan(authToken);
    setPlan(data.planDetails.timeLine);
    setTargetCard(data.planDetails.goalCard);
    setLoading(false);
  };

  useEffect(() => {
    getPlan();
  }, []);

  return (
    <div className="flex flex-col w-100 bg-textbox overflow-y-auto">
      {!loading && (
        <div>
          <header className="border-b border-customECECEC w-100 text-txtlight text-title-m font-semibold flex mt-20 px-10 pl-16px py-4">
            <img
              src={arrowBack}
              className="cursor-pointer"
              onClick={history.goBack}
              style={{ width: '36px' }}
              alt="go-back"
            />
            <div className="flex items-center">
              <img src={PageIcon} alt="page" className="mr-2" />
              Your workout plan
            </div>
          </header>
          <div className="px-6 py-4 overflow-y-auto">
            <TargetCard {...targetCard} />
            <WorkoutTracker tracks={plan} />
          </div>
          <footer className="px-10 py-4">
            <button
              className="bg-primary w-100 text-textbox text-body-l font-semibold rounded-xl py-4"
              onClick={continueOnboarding}
            >
              Continue&nbsp;
              <span className="bg-textbox px-1.5 rounded-full">
                <img
                  src={ArrowRight}
                  alt="arrow-right"
                  className="bg-textbox inline mb-1"
                />
              </span>
            </button>
          </footer>
        </div>
      )}
    </div>
  );
}
