import axios from 'axios';
import { showError } from '../actions';
import config from '../config';

import { store } from '../store';

export const getMemberBalanceForTataUser = (memberId) => {
  const headers = {
    Authorization: `${config.tataToken}`,
  };
  return axios
    .get(`${config.tataApiUrl}/points-balance/${memberId}`, {
      headers,
    })
    .then((result) => {
      const responseBody = result.data;
      if (responseBody.action === 'SUCCESS') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.desc = responseBody;
      throw err;
    })
    .catch((err) => {
      console.log(err);
      showError(err);
      throw err;
    });
};

export const fetchHraScoreAndClass = () => {
  const { authToken } = store.getState().user;
  const headers = {
    Authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/health-risk-assessment/get-users-hra-score`, {
      headers,
    })
    .then((response) => {
      const responseBody = response.data;
      if (responseBody.message === 'success') {
        console.log(responseBody, 'main');
        return responseBody.body;
      } else {
        const err = new Error('Invalid response');
        err.data = response.body;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
};
export const updateHraAndGoogleFitDetailsToTata = (body) => {
  console.log('called with body', body);
  const headers = {
    Authorization: `${config.tataToken}`,
  };
  return axios
    .put(`${config.tataApiUrl}/hra-fitness-details`, body, {
      headers,
    })
    .then((result) => {
      console.log(result, 'called tata AIG');
      const responseBody = result.data;
      if (responseBody.action === 'SUCCESS') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.desc = responseBody;
      throw err;
    })
    .catch((err) => {
      console.log(err);
      // showError(err);
      throw err;
    });
};

export const bookingStatusCallForTataAig = (body) => {
  const headers = {
    Authorization: `${config.tataToken}`,
  };

  return axios
    .post(`${config.tataApiUrl}/consultation`, body, {
      headers,
    })
    .then((result) => {
      console.log(result, 'called consultation API for Tata AIG');
      const responseBody = result.data;
      if ((responseBody.action = 'SUCCESS')) {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.desc = responseBody;
      throw err;
    })
    .catch((err) => {
      console.log(err);
      showError(err);
      throw err;
    });
};
