import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';
import LoadingComponent from '../../components/common/LoadingComponent';
import { Text } from '../../components/common/Text';
import { FlyInTransition } from '../../components/transitions/transitions';
import arrowBack from '../../images/arrow_back.svg';
import { getAllWorkouts } from '../../services/health-content';
import Modal from '../common/Modal';
import Player from './FitnessPlayer';
import MyFitness from './MyFitness';
import { updateFitnessActiveTab } from '../../actions';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  background: white;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  padding: 0px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f3f6;
`;

const HeaderText = styled(Text)`
  margin-left: 0.625rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #464548;
`;

const BodyContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
`;

const HeaderNew = styled.div`
  padding: 30px 01px 0px 0px;
  display: flex;
  background: white;
  align-self: stretch;
  align-items: center;
`;

const SectionHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #0f0b28;
  margin-left: 4px;
`;

const CardContent = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  width: 180px;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 24px 16px;
`;

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0f0b28;
`;

const CardDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #585969;
  margin-top: 2px;
`;

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
`;

const AngleRight = styled(FaChevronRight)`
  color: #413958;
  width: 8px;
  height: 8px;
`;

class FitnessHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      allWorkouts: [],
      showPlayerModal: false,
      selectedAlbum: null,
      activeTab: this.props?.fitnessActiveTab,
      program: false,
      showFitness: false,
      showProgram: false,
    };
  }

  componentDidMount() {
    this.getAllWorkouts();
    if (
      typeof window.Android !== 'undefined' &&
      window.Android &&
      window.Android.podcastPage
    ) {
      return window.Android.podcastPage();
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'podcastPage',
        })
      );
    }
  }

  getAllWorkouts() {
    getAllWorkouts(this.props.authToken)
      .then((res) => {
        this.setState({
          allWorkouts: res.cards,
          program: res.program,
          showProgram: res.program,
          showFitness: res.showFitnessProgram,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onAlbumClick = (album) => {
    if (album.showAudioPlayer) {
      this.setState({ selectedAlbum: album, showPlayerModal: true });
    } else {
      //it is a album container
      this.props.history.push('/health-content/sub-fitness', {
        albumId: album.albumId,
      });
    }
  };

  hideModal = () => {
    this.setState({ showPlayerModal: false });
  };

  render() {
    const { allWorkouts, showPlayerModal, selectedAlbum, loading } = this.state;
    if (loading) {
      return <LoadingComponent />;
    }
    const renderWorkoutCards = (cards) => {
      return cards.map((card) => {
        const albums = card.albums;
        return (
          <div
            style={{ marginTop: '25px' }}
            className="px-10px"
            key={card.title}
          >
            <div className="flex justify-between px-16px">
              <div className="flex">
                <img
                  src={card.sectionIcon}
                  alt="section-icon"
                  width={'16px'}
                  style={{ height: '16px' }}
                />
                <SectionHeader>{card.title}</SectionHeader>
              </div>
              <div
                className="flex items-center pr-12px cursor-pointer"
                onClick={() =>
                  this.props.history.push(
                    `/health-content/fitness-home/${card.type}`,
                    {
                      card,
                    }
                  )
                }
              >
                <p className="text-body-s font-semibold text-purpleText">
                  See all
                </p>
                <div className="p-5 bg-primary-shades1 rounded-full ml-8px">
                  <AngleRight />
                </div>
              </div>
            </div>
            {albums && albums.length > 0 && (
              <div className="flex overflow-x-auto justify-start mt-16px px-12px">
                {albums.map((album) => {
                  return (
                    <button
                      onClick={() => this.onAlbumClick(album)}
                      className="text-left mr-16px relative"
                      key={album.albumId}
                    >
                      {album.sessionCount && (
                        <p
                          className="absolute px-8px py-2px text-chip font-semibold text-textbox rounded-12 top-12px left-12px"
                          style={{
                            background: '#0F0B2833',
                          }}
                        >
                          {album.sessionCount} Videos
                        </p>
                      )}
                      <img
                        src={album.imageUrl}
                        style={{
                          width: '185px',
                          height: '185px',
                          borderTopLeftRadius: '12px',
                          borderTopRightRadius: '12px',
                        }}
                      />
                      <CardContent>
                        <CardTitle>{album.albumTitle}</CardTitle>
                        <CardDescription>By {album.coachName}</CardDescription>
                      </CardContent>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    };

    const HeaderTab = () => {
      const handleHeaderToggle = (activeTab) => {
        this.setState({ activeTab });
        this.props.dispatch(updateFitnessActiveTab(activeTab));
      };

      return (
        <div className="w-full px-6 mt-6">
          <div className="bg-txtlight-shades4 bg-txtlight-shades4 flex justify-between rounded-lg p-1 h-10">
            {this.state.showFitness && (
              <button
                className={`hover:opacity-75 text-body-s font-semibold rounded-lg w-full h-full ${
                  this.state.activeTab === 'fitness-library' && 'bg-textbox'
                }`}
                onClick={() => handleHeaderToggle('fitness-library')}
              >
                Library
              </button>
            )}
            {/* {this.state.showProgram && ( */}
            <button
              className={`hover:opacity-75 text-body-s font-semibold rounded-lg w-full ${
                this.state.activeTab === 'my-fitness' && 'bg-textbox'
              }`}
              onClick={() => handleHeaderToggle('my-fitness')}
            >
              My Program
            </button>
            {/* )} */}
          </div>
        </div>
      );
    };

    return (
      <OuterContainer>
        <HeaderContainer>
          <HeaderNew>
            <img
              src={arrowBack}
              className="cursor-pointer"
              onClick={this.props.history.goBack}
              style={{ width: '36px' }}
              alt="go-back"
            />
            <HeaderText>Fitness</HeaderText>
          </HeaderNew>
          <HeaderTab />
        </HeaderContainer>
        <BodyContainer>
          {this.state.activeTab === 'fitness-library' ? (
            <div>{renderWorkoutCards(allWorkouts)}</div>
          ) : (
            <MyFitness onboarded={!this.state.program} />
          )}
          {/* <div style={{ marginTop: '25px' }}>
            {renderPodcastCards(personalGrowthAlbums)}
          </div>
          <div style={{ marginTop: '25px' }}>
            {renderPodcastCards(workAlbums)}
          </div> */}
        </BodyContainer>
        {showPlayerModal && (
          <Modal>
            <ModalContainer onClick={this.hideModal}>
              <FlyInTransition
                in={showPlayerModal}
                mountOnEnter
                unmountOnExit
                appear
              >
                <Player
                  album={selectedAlbum}
                  authToken={this.props.authToken}
                  hideModal={this.hideModal}
                />
              </FlyInTransition>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  fitnessActiveTab: state.app.fitnessActiveTab,
});

export default connect(mapStateToProps)(FitnessHome);
