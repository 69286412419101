import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ShieldText from './ShieldText';
import WeightIcon from '../../images/health-content/weight-icon.svg';
import BPIcon from '../../images/health-content/bp-icon.svg';
import BloodPressureIcon from '../../images/health-content/blood-pressure-icon.svg';
import BloodSugarIcon from '../../images/health-content/blood-sugar-icon.svg';
import HbA1cIcon from '../../images/health-content/hbA1c-icon.svg';
import GlassIcon from '../../images/health-content/glass-icon.svg';
import ClockIcon from '../../images/health-content/clock-icon.svg';
import MealIcon from '../../images/health-content/meal-icon.svg';
import closeIcon from '../../images/health-content/close-modal.svg';
import warningIcon from '../../images/health-content/warning-icon.svg';
import {
  getCurrentWeight,
  getFitnessProgram,
  postCurrentWeight,
  postVideoLogTime,
  postCurrentBP,
  postCurrentBS,
  postCurrentHbA1c,
} from '../../services/health-content';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AddIcon from '../../images/health-content/add-icon.svg';
import React from 'react';
import Modal from '../common/Modal';
import Player from './FitnessPlayer';
import { FlyInTransition } from '../../components/transitions/transitions';
import ScalePicker from './ScalePicker';
import LeftArrow from '../../images/header/left-arrow.svg';

const OuterContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: white;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Card = styled.button`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  text-align: left;
  width: 49%;
`;

const Button = styled.button`
  width: 100%;
  background-color: #f0f3f6;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  cursor: pointer;
`;

const WeightModalContainer = styled.div`
  display: flex;
  height: 500px;
  width: 440px;
  flex-direction: column;
  background: rgba(255, 255, 255);
  overflow: hidden;
  //   cursor: pointer;
  border-radius: 12px 12px 0 0;
`;

const BPInputBox = styled.input`
  width: 50%;
  border-bottom: 1px solid black;
  font-size: 48px;
  font-weight: 700;
  color: #01040d;
  text-align: center;
  ::placeholder {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
  }
`;

const BloodSugarInputBox = styled.input`
  width: 20%;
  border-bottom: 1px solid black;
  font-size: 48px;
  font-weight: 700;
  color: #01040d;
  text-align: center;
  ::placeholder {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
  }
`;

const BloodSugarLabel = styled.div`
  padding: 12px 16px;
  border-radius: 12px;
  background: ${(props) =>
    props.value === props.logTime ? '#303E4F' : '#F0F3F6'};
  color: ${(props) => (props.value === props.logTime ? '#FFFFFF' : '#767787')};
  cursor: pointer;
`;

function ProgressBar({
  total,
  current,
  barColor = '#1EB865',
  className = '',
  height = 1,
  rounded = 'full',
  bgColor = 'txtlight-shades4',
}) {
  return (
    <div
      className={`${className} w-100 bg-${bgColor} h-${height} rounded-${rounded}`}
    >
      <div
        className={`h-full rounded-${rounded}`}
        style={{
          width: `${(current / total) * 100}%`,
          backgroundColor: barColor,
        }}
      ></div>
    </div>
  );
}

function TaskCards({ isOpenModal, setIsOpenModal }) {
  const { authToken } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState({
    showWeightModal: false,
    showBPModal: false,
    showSugarModal: false,
    showHbA1cModal: false,
  });
  const [weightCard, setWeightCard] = useState(null);
  const [bpCard, setBpCard] = useState(null);
  const [bsCard, setBsCard] = useState(null);
  const [hbA1cCard, setHbA1cCard] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const getProgram = async () => {
    const data = await getCurrentWeight(authToken);
    console.log(data);
    data.healthData.forEach((task) => {
      if (task.name === 'WEIGHT') setWeightCard(task);
      if (task.name === 'BP') setBpCard(task);
      if (task.name === 'BS') setBsCard(task);
      if (task.name === 'HB') setHbA1cCard(task);
    });
    setLoading(false);
  };

  useEffect(() => {
    getProgram();
  }, [showModal]);

  function WeightCard() {
    return (
      <Card
        onClick={() =>
          history.push('/health-content/weight-data', {
            from: 'health-data',
          })
        }
        className="w-half p-15 mt-2 flex flex-col"
      >
        <div className="flex justify-between w-full">
          <img src={WeightIcon} alt="weight-icon" />
          <span
            className="rounded-full text-title-m font-semibold px-2 mt-0.5 mr-2"
            style={{ transform: 'scale(.5, 1)' }}
          >
            &gt;
          </span>
        </div>
        <h4 className="text-caption-l font-semibold mt-10">Weight</h4>
        <h1 className="text-title-l font-bold mt-1">
          {weightCard ? weightCard.value1 : 0}
          <span className="text-button-l font-bold">kgs</span>
        </h1>
        {weightCard && (
          <p className="font-medium text-chip text-sub mt-1">
            <img src={ClockIcon} alt="clock" className="inline" />
            &nbsp;{weightCard.measuredOn}
          </p>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowModal((prevState) => ({
              ...prevState,
              showWeightModal: true,
            }));
          }}
          className="flex justify-center items-center w-100 bg-txtlight-shades4 rounded-lg text-caption-l font-semibold py-2.5 mt-10"
        >
          <img src={AddIcon}></img>
          <span className="ml-8px">Update</span>
        </button>
        {(showModal.showWeightModal || isOpenModal) && (
          <WeightModal
            hideModal={(e) => {
              if (e) e.stopPropagation();
              setShowModal((prevState) => ({
                ...prevState,
                showWeightModal: false,
              }));
              setIsOpenModal(false);
            }}
            weightDate={weightCard}
            scaleVal={weightCard ? weightCard.value1 : 0}
          ></WeightModal>
        )}
      </Card>
    );
  }

  function BloodPressureCard() {
    return (
      <Card
        onClick={() =>
          history.push('/health-content/blood-pressure-data', {
            from: 'health-data',
          })
        }
        className="w-half p-15 mt-2 flex flex-col"
      >
        <div className="flex justify-between w-full">
          <img src={BloodPressureIcon} alt="weight-icon" />
          <span
            className="rounded-full text-title-m font-semibold px-2 mt-0.5 mr-2"
            style={{ transform: 'scale(.5, 1)' }}
          >
            &gt;
          </span>
        </div>
        <h4 className="text-caption-l font-semibold mt-10">Blood Pressure</h4>
        <h1 className="text-title-l font-bold mt-1">
          {bpCard ? bpCard.value1 : 0}/{bpCard ? bpCard.value2 : 0}
        </h1>
        {bpCard && (
          <p className="font-medium text-chip text-sub mt-1">
            <img src={ClockIcon} alt="clock" className="inline" />
            &nbsp;{bpCard.measuredOn}
          </p>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowModal((prevState) => ({
              ...prevState,
              showBPModal: true,
            }));
          }}
          className="flex justify-center items-center w-100 bg-txtlight-shades4 rounded-lg text-caption-l font-semibold py-2.5 mt-auto"
        >
          <img src={AddIcon}></img>
          <span className="ml-8px">Log New</span>
        </button>
        {showModal.showBPModal && (
          <BPModal
            // getData={getData}
            hideModal={(e) => {
              if (e) e.stopPropagation();
              setShowModal((prevState) => ({
                ...prevState,
                showBPModal: false,
              }));
            }}
            measuredOn={bpCard ? bpCard.measuredOn : ''}
          ></BPModal>
        )}
      </Card>
    );
  }

  function BloodSugarCard() {
    return (
      <Card
        onClick={() =>
          history.push('/health-content/blood-sugar-data', {
            from: 'health-data',
          })
        }
        className="w-half p-15 mt-2 flex flex-col"
      >
        <div className="flex justify-between w-full">
          <img src={BloodSugarIcon} alt="weight-icon" />
          <span
            className="rounded-full text-title-m font-semibold px-2 mt-0.5 mr-2"
            style={{ transform: 'scale(.5, 1)' }}
          >
            &gt;
          </span>
        </div>
        <h4 className="text-caption-l font-semibold mt-10">Blood Sugar</h4>
        <h1 className="text-title-l font-bold mt-1">
          {bsCard ? bsCard.value1 : 0} mg/dl
        </h1>
        {bsCard && (
          <p className="font-medium text-chip text-sub mt-1">
            <img src={ClockIcon} alt="clock" className="inline" />
            &nbsp;{bsCard.measuredOn}
          </p>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowModal((prevState) => ({
              ...prevState,
              showSugarModal: true,
            }));
          }}
          className="flex justify-center items-center w-100 bg-txtlight-shades4 rounded-lg text-caption-l font-semibold py-2.5 mt-10"
        >
          <img src={AddIcon}></img>
          <span className="ml-8px">Log New</span>
        </button>
        {showModal.showSugarModal && (
          <BloodSugarModal
            // getData={getData}
            hideModal={(e) => {
              if (e) e.stopPropagation();
              setShowModal((prevState) => ({
                ...prevState,
                showSugarModal: false,
              }));
            }}
            measuredOn={bsCard ? bsCard.measuredOn : ''}
          ></BloodSugarModal>
        )}
      </Card>
    );
  }
  function HbA1cCard() {
    return (
      <Card
        onClick={() =>
          history.push('/health-content/hbA1c-data', {
            from: 'health-data',
          })
        }
        className="w-half p-15 mt-2 flex flex-col"
      >
        <div className="flex justify-between w-full">
          <img src={HbA1cIcon} alt="weight-icon" />
          <span
            className="rounded-full text-title-m font-semibold px-2 mt-0.5 mr-2"
            style={{ transform: 'scale(.5, 1)' }}
          >
            &gt;
          </span>
        </div>
        <h4 className="text-caption-l font-semibold mt-10">hbA1c</h4>
        <h1 className="text-title-l font-bold mt-1">
          {hbA1cCard ? hbA1cCard.value1 : 0}
        </h1>
        {hbA1cCard && (
          <p className="font-medium text-chip text-sub mt-1">
            <img src={ClockIcon} alt="clock" className="inline" />
            &nbsp;{hbA1cCard.measuredOn}
          </p>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowModal((prevState) => ({
              ...prevState,
              showHbA1cModal: true,
            }));
          }}
          className="flex justify-center items-center w-100 bg-txtlight-shades4 rounded-lg text-caption-l font-semibold py-2.5 mt-10"
        >
          <img src={AddIcon}></img>
          <span className="ml-8px">Log New</span>
        </button>
        {showModal.showHbA1cModal && (
          <HbA1cModal
            // getData={getData}
            hideModal={(e) => {
              if (e) e.stopPropagation();
              setShowModal((prevState) => ({
                ...prevState,
                showHbA1cModal: false,
              }));
            }}
            measuredOn={hbA1cCard ? hbA1cCard.measuredOn : ''}
          ></HbA1cModal>
        )}
      </Card>
    );
  }
  return (
    <div className="p-7 pt-4">
      <div>
        {!loading && (
          <>
            <div
              className="p-20px text-body-m text-sub rounded-12 py-14px"
              style={{ background: 'rgba(63, 140, 255, 0.1)' }}
            >
              Tap on card to see daily logs
            </div>
            <div className="flex flex-wrap justify-between mt-4px">
              <WeightCard />
              <BloodPressureCard />
              <BloodSugarCard />
              <HbA1cCard />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default function HealthData({ onboarded }) {
  const history = useHistory();
  const { authToken } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [weightCard, setWeightCard] = useState(null);
  const [waterCard, setWaterCard] = useState(null);
  const [nutritionCard, setNutritionCard] = useState(null);

  const [videoCard, setVideoCard] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(
    history.location?.state?.openModal
  );

  console.log(history);
  return (
    <OuterContainer>
      <div
        className="flex p-28px py-24px pb-20px border-b border-txtsecondary-shades4"
        onClick={() => history.push('/weight-management')}
      >
        <img src={LeftArrow} className="cursor-pointer" />
        <p className="ml-14px text-body-m font-semibold">Your Health Data</p>
      </div>
      <TaskCards isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </OuterContainer>
  );
}

const WeightModal = ({ hideModal, weightDate, scaleVal }) => {
  const [scaleValue, setScaleValue] = useState(scaleVal);
  const [loading, setLoading] = useState(false);
  const [weightData, setWeightDate] = useState(weightDate);

  const { authToken } = useSelector((state) => state.user);

  //   const getProgram = async () => {
  //     const data = await getCurrentWeight(authToken);
  //     data.healthData.forEach((health) => {
  //       if (health.name === 'WEIGHT') {
  //         setWeightDate(health);
  //         setScaleValue(health.value1);
  //       }
  //     });
  //     setLoading(false);
  //   };

  //   useEffect(() => {
  //     getProgram();
  //   }, []);

  return (
    <Modal>
      <div
        className="modalContainer"
        style={{
          background: 'rgba(0, 0, 0, 0.2)',
          zIndex: 2,
        }}
        onClick={hideModal}
      >
        {!loading && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="modalBody"
          >
            <div
              style={{
                padding: '16px',
                paddingLeft: '0',
                paddingRight: '0',
                overflowY: 'auto',
                minHeight: '150px',
              }}
            >
              <div className="flex justify-between items-center border-b border-txtlight-shades4">
                <div className="flex items-center p-20px py-16px">
                  <img src={WeightIcon}></img>
                  <p className="ml-12px text-body-m font-semibold">Weight</p>
                </div>
                <img
                  src={closeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    hideModal();
                  }}
                  className="pr-20px"
                />
              </div>
              <div className="flex py-40px justify-between items-center flex-col h-full">
                <p className="text-title-l font-bold">
                  What is your current weight?
                </p>
                <div className="text-center my-4">
                  <span className="text-header-xl font-bold">{scaleValue}</span>
                  <span>Kgs</span>
                </div>
                <ScalePicker
                  start={30}
                  end={200}
                  value={scaleValue}
                  onChange={(value) => setScaleValue(value)}
                />
                <div className="flex flex-col justify-center items-center">
                  <div className="flex mt-52px">
                    <img src={ClockIcon}></img>
                    <p className="text-chip text-sub ml-8px">Last Logged On</p>
                  </div>
                  <p className="text-body-s">
                    {weightData ? weightData.measuredOn : 'nothing logged yet'}
                  </p>
                </div>
                <div className="pb-20px px-20px mt-28px w-full">
                  <button
                    onClick={async () => {
                      await postCurrentWeight(authToken, {
                        weight: scaleValue,
                        measuredOn: new Date().toISOString().slice(0, 10),
                      });
                      hideModal();
                    }}
                    className="flex justify-center w-full bg-primary text-textbox mb-20px rounded-12 text-body-s font-semibold py-14px"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const BPModal = ({ hideModal, measuredOn }) => {
  const [systolicValue, setSystolicValue] = useState('');
  const [diastolicValue, setDiastolicValue] = useState('');
  const [showErrorText, setShowErrorText] = useState(false);
  const { authToken } = useSelector((state) => state.user);

  const validateInput = () => {
    if (
      Number(systolicValue) < 70 ||
      Number(systolicValue) > 300 ||
      Number(diastolicValue) < 40 ||
      Number(diastolicValue) > 200 ||
      Number(systolicValue) < Number(diastolicValue)
    )
      return false;
    else return true;
  };

  return (
    <Modal>
      <div
        className="modalContainer"
        style={{
          background: 'rgba(0, 0, 0, 0.2)',
          zIndex: 2,
        }}
        onClick={(e) => {
          e.stopPropagation();
          hideModal();
        }}
      >
        <div className="modalBody" onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              padding: '16px',
              paddingLeft: '0',
              paddingRight: '0',
              overflowY: 'auto',
              minHeight: '150px',
            }}
          >
            <div className="flex justify-between items-center border-b border-txtlight-shades4">
              <div className="flex items-center p-20px py-16px">
                <img src={BPIcon}></img>
                <p className="ml-12px text-body-m font-semibold">
                  Blood Pressure
                </p>
              </div>
              <img
                src={closeIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  hideModal();
                }}
                className="pr-20px"
              />
            </div>
            <div className="flex py-40px justify-between items-center flex-col h-full">
              <p className="text-title-xl font-bold">Enter the value</p>
              <div className="flex justify-evenly items-center">
                <div className="flex flex-col justify-center items-center">
                  <BPInputBox
                    type="number"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    placeholder="00"
                    id="systolic"
                    onChange={(e) => setSystolicValue(e.target.value)}
                  />
                  <label for="systolic" className="mt-4px">
                    Systolic
                  </label>
                </div>
                <span className="mx-18px my-auto">/</span>
                <div className="flex flex-col justify-center items-center">
                  <BPInputBox
                    type="number"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    placeholder="00"
                    id="diastolic"
                    onChange={(e) => setDiastolicValue(e.target.value)}
                  />
                  <label for="diastolic" className="mt-4px">
                    Diastolic
                  </label>
                </div>
              </div>
              {showErrorText && <ErrorText />}
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col mt-52px">
                  <div className="flex">
                    <img src={ClockIcon}></img>
                    <p className="text-body-s text-sub ml-8px">
                      Last Measured On
                    </p>
                  </div>
                  <p className="text-body-l font-medium text-txtlight self-center">
                    {measuredOn ? measuredOn : 'nothing logged yet'}
                  </p>
                </div>
                <p className="text-body-s"></p>
              </div>
              {systolicValue.length > 0 && diastolicValue.length > 0 && (
                <div className="pb-20px px-20px mt-28px w-full">
                  <button
                    onClick={async (e) => {
                      if (validateInput()) {
                        await postCurrentBP(authToken, {
                          systolic: systolicValue,
                          diastolic: diastolicValue,
                          measuredOn: new Date().toISOString().slice(0, 10),
                        });
                        setShowErrorText(false);
                        hideModal();
                      } else {
                        e.stopPropagation();
                        setShowErrorText(true);
                      }
                    }}
                    className="flex justify-center w-full bg-primary text-textbox mb-20px rounded-12 text-body-s font-semibold py-14px"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const BloodSugarModal = ({ hideModal, measuredOn }) => {
  const [value, setValue] = useState('');
  const [logTime, setLogTime] = useState('Fasting');
  const [showErrorText, setShowErrorText] = useState(false);
  const { authToken } = useSelector((state) => state.user);

  const validateInput = () => {
    if (Number(value) < 50 || Number(value) > 400) return false;
    else return true;
  };

  return (
    <Modal>
      <div
        className="modalContainer"
        style={{
          background: 'rgba(0, 0, 0, 0.2)',
          zIndex: 2,
        }}
        onClick={(e) => {
          e.stopPropagation();
          hideModal();
        }}
      >
        <div className="modalBody" onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              padding: '16px',
              paddingLeft: '0',
              paddingRight: '0',
              overflowY: 'auto',
              minHeight: '150px',
            }}
          >
            <div className="flex justify-between items-center border-b border-txtlight-shades4">
              <div className="flex items-center p-20px py-16px">
                <img src={BloodSugarIcon}></img>
                <p className="ml-12px text-body-m font-semibold">Blood Sugar</p>
              </div>
              <img
                src={closeIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  hideModal();
                }}
                className="pr-20px"
              />
            </div>
            <div className="flex py-40px justify-between items-center flex-col h-full">
              <p className="text-title-xl font-bold">What are you measuring?</p>
              <div className="flex justify-around items-center mt-32px mb-48px">
                <BloodSugarLabel
                  value="Fasting"
                  logTime={logTime}
                  onClick={(e) => {
                    e.stopPropagation();
                    setLogTime('Fasting');
                  }}
                >
                  Fasting
                </BloodSugarLabel>
                <BloodSugarLabel
                  value="Random"
                  logTime={logTime}
                  onClick={(e) => {
                    e.stopPropagation();
                    setLogTime('Random');
                  }}
                  className="mx-12px"
                >
                  Random
                </BloodSugarLabel>
                <BloodSugarLabel
                  value="Post-Meal"
                  logTime={logTime}
                  onClick={(e) => {
                    e.stopPropagation();
                    setLogTime('Post-Meal');
                  }}
                >
                  Post-Meal
                </BloodSugarLabel>
              </div>
              <div className="flex flex-col justify-evenly items-center">
                <BloodSugarInputBox
                  type="number"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  placeholder="00"
                  onChange={(e) => setValue(e.target.value)}
                />
                <label className="mt-4px">mg/del</label>
              </div>
              {showErrorText && <ErrorText />}
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col mt-52px">
                  <div className="flex">
                    <img src={ClockIcon}></img>
                    <p className="text-body-s text-sub ml-8px">
                      Last logged On
                    </p>
                  </div>
                  <p className="text-body-l font-medium text-txtlight self-center">
                    {measuredOn ? measuredOn : 'nothing logged yet'}
                  </p>
                </div>
                <p className="text-body-s"></p>
              </div>
              {value.length > 0 && (
                <div className="pb-20px px-20px mt-28px w-full">
                  <button
                    onClick={async (e) => {
                      if (validateInput()) {
                        await postCurrentBS(authToken, {
                          value: value,
                          type: logTime,
                          measuredOn: new Date().toISOString().slice(0, 10),
                          startConversation: false,
                        });
                        hideModal();
                        setShowErrorText(false);
                      } else {
                        e.stopPropagation();
                        setShowErrorText(true);
                      }
                    }}
                    className="flex justify-center w-full bg-primary text-textbox mb-20px rounded-12 text-body-s font-semibold py-14px"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const HbA1cModal = ({ hideModal, measuredOn }) => {
  const [value, setValue] = useState('');
  const [showErrorText, setShowErrorText] = useState(false);
  const { authToken } = useSelector((state) => state.user);

  const validateInput = () => {
    if (Number(value) < 3.5 || Number(value) > 15) return false;
    else return true;
  };

  return (
    <Modal>
      <div
        className="modalContainer"
        style={{
          background: 'rgba(0, 0, 0, 0.2)',
          zIndex: 2,
        }}
        onClick={(e) => {
          e.stopPropagation();
          hideModal();
        }}
      >
        <div className="modalBody" onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              padding: '16px',
              paddingLeft: '0',
              paddingRight: '0',
              overflowY: 'auto',
              minHeight: '150px',
            }}
          >
            <div className="flex justify-between items-center border-b border-txtlight-shades4">
              <div className="flex items-center p-20px py-16px pt-0">
                <img src={HbA1cIcon}></img>
                <p className="ml-12px text-body-m font-semibold">hbA1c</p>
              </div>
              <img
                src={closeIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  hideModal();
                }}
                className="pr-20px pb-16px"
              />
            </div>
            <div className="flex py-40px justify-between items-center flex-col h-full">
              <p className="text-title-xl font-bold">Enter your hbA1c value</p>
              <div className="flex flex-col justify-evenly items-center">
                <BloodSugarInputBox
                  type="number"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  placeholder="00"
                  onChange={(e) => setValue(e.target.value)}
                />
                <label>% percentage</label>
              </div>
              {showErrorText && <ErrorText />}
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col mt-52px">
                  <div className="flex">
                    <img src={ClockIcon}></img>
                    <p className="text-body-s text-sub ml-8px">
                      Last measured On
                    </p>
                  </div>
                  <p className="text-body-l font-medium text-txtlight self-center">
                    {measuredOn ? measuredOn : 'nothing logged yet'}
                  </p>
                </div>
                <p className="text-body-s"></p>
              </div>
              {value.length > 0 && (
                <div className="pb-20px px-20px mt-28px w-full">
                  <button
                    onClick={async (e) => {
                      if (validateInput()) {
                        await postCurrentHbA1c(authToken, {
                          value: value,
                          measuredOn: new Date().toISOString().slice(0, 10),
                        });
                        hideModal();
                        setShowErrorText(false);
                      } else {
                        e.stopPropagation();
                        setShowErrorText(true);
                      }
                    }}
                    className="flex justify-center w-full bg-primary text-textbox mb-20px rounded-12 text-body-s font-semibold py-14px"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ErrorText = () => {
  return (
    <div className="flex mt-8px">
      <img src={warningIcon} />
      <p className="text-body-s ml-4px text-accent2-shades4">
        Please enter a valid value
      </p>
    </div>
  );
};
