import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BoldText } from '../common/Text';

import CoinIconWhite from '../../images/rewards/coin-logo.svg';
import fitcoinIcon from '../../images/common/rewardicon.png';
import { fontSize } from '../../utils';

const FitcoinBadgeContainer = styled.div`
  padding: 0.125rem 0.5625rem;
  display: flex;
  align-items: center;
  float: left;
  // background: linear-gradient(45deg, #ede67b, #a37520);
  background: #005aa6;
  border-radius: 36px;
`;

const EarnText = styled(BoldText)`
  color: white;
  font-size: ${() => `${0.6875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const EarnIcon = styled.img`
  width: 0.6875rem;
  margin: 0 0.25rem;
`;

const Icon = styled.img`
  width: 1rem;
  margin: 0 0.5rem;
`;

const BalanceIcon = styled(Icon)`
  width: 1.1rem;
`;

const BalanceText = styled(EarnText)`
  font-size: ${() => `${fontSize('0.875')}`};
`;

const EarnBadge = ({ className }) => (
  <FitcoinBadgeContainer className={className}>
    <EarnText>Earn</EarnText>
    <EarnIcon src={CoinIconWhite} alt="Fitcoin" />
  </FitcoinBadgeContainer>
);

const FitcoinIcon = () => <Icon src={CoinIconWhite} />;

const BalanceBadge = ({ balance }) => (
  <FitcoinBadgeContainer>
    <BalanceIcon src={CoinIconWhite} alt={balance} />
    <BalanceText>{balance}</BalanceText>
  </FitcoinBadgeContainer>
);

BalanceBadge.propTypes = {
  balance: PropTypes.string.isRequired,
};

export {
  FitcoinBadgeContainer as FitcoinContainer,
  EarnBadge,
  FitcoinIcon,
  BalanceBadge,
};
