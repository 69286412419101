import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IoMdSend } from 'react-icons/io';
import { FaCircleNotch, FaTimes } from 'react-icons/fa';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import StyledTappable from '../common/StyledTappable';
import { Text } from '../common/Text';
import { debounce } from '../../utils';
import { getAutocompleteSuggestions } from '../../services';

import symptomIcon from '../../images/common/symptomicon.png';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
`;

const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px;
  max-height: 280px;
  overflow-y: scroll;
  border-bottom: 1px solid #dadada;
`;

const SuggestionContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0;
  margin-bottom: 4px;
  border-bottom: 1px solid #dadada;
`;

const SuggestionIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

const InputTappable = styled(StyledTappable)`
  padding: 0 0.5rem;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 8px;
`;

const InputBox = styled(Input)`
  padding: 0.75rem;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
`;

const SendIcon = styled(IoMdSend)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: ${(props) => (props.active ? '#303E4F' : 'rgba(0,0,0,0.5)')};
`;

const SelectedItemsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: 60px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SelectedItemContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
  background: #303e4f;
  padding: 8px 14px;
  margin: 4px;
  border-radius: 30px;
  color: white;
`;

class LookupInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
      loading: false,
      suggestions: [],
      selectedSuggestions: [],
    };
    this.getAutocompleteHints = debounce(
      val => this.fetchSuggestions(val),
      600,
    );
  }

  fetchSuggestions(val) {
    const { searchApi, authToken } = this.props;
    getAutocompleteSuggestions(searchApi, val, authToken)
      .then(res => {
        const { suggestions } = res;
        this.setState({
          suggestions,
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  sendMessage = () => {
    const { selectedSuggestions } = this.state;
    const { sendMessage } = this.props;
    const evidences = selectedSuggestions.map(ele => ({
      evidenceId: ele.evidenceId,
      choiceId: 'present',
    }));
    const chatMessage = selectedSuggestions.reduce(
      (acc, val, idx) => (idx === 0 ? val.phrase : `${acc}, ${val.phrase}`),
      '',
    );
    const message = {
      text: chatMessage,
      cardType: 'text',
      flowDirective: {
        evidences,
      },
    };
    sendMessage(message);
  };

  isSuggestionSelected(ele) {
    const { selectedSuggestions } = this.state;
    const index = selectedSuggestions.findIndex(
      ite => ele.evidenceId === ite.evidenceId,
    );
    console.log(index);
    return index !== -1;
  }

  selectSuggestion(ele) {
    const { selectedSuggestions } = this.state;
    console.log(ele);
    if (!this.isSuggestionSelected(ele)) {
      const updatedSelectedSuggestions = Array.from(selectedSuggestions);
      this.setState({
        selectedSuggestions: updatedSelectedSuggestions.concat([ele]),
      });
    }
  }

  removeSuggestion(ele) {
    const { selectedSuggestions } = this.state;
    const index = selectedSuggestions.findIndex(
      ite => ele.evidenceId === ite.evidenceId,
    );
    if (index !== -1) {
      const updatedSelectedSuggestions = Array.from(selectedSuggestions);
      updatedSelectedSuggestions.splice(index, 1);
      this.setState({
        selectedSuggestions: updatedSelectedSuggestions,
      });
    }
  }

  render() {
    const chatMessageLink = Link.state(this, 'chatMessage').onChange(val => {
      if (val.length > 0) {
        this.setState({
          loading: true,
        });
        this.getAutocompleteHints(val);
      }
    });
    const {
      chatMessage,
      suggestions,
      loading,
      selectedSuggestions,
    } = this.state;
    const { numeric } = this.props;
    const renderSuggestion = (ele, idx) => {
      return (
        <SuggestionContainer
          key={ele.evidenceId}
          onTap={() => this.selectSuggestion(ele)}
        >
          <SuggestionIcon src={symptomIcon} />
          <Text>{ele.phrase}</Text>
        </SuggestionContainer>
      );
    };
    const renderSelectedSuggestion = (ele, idx) => {
      return (
        <SelectedItemContainer
          key={ele.evidenceId}
          onTap={() => this.removeSuggestion(ele)}
        >
          <Text>{ele.phrase}</Text>
          <FaTimes />
        </SelectedItemContainer>
      );
    };
    return (
      <OuterContainer>
        {chatMessage.length > 0 && (
          <SuggestionsContainer>
            {suggestions.length > 0 ? (
              suggestions.map(renderSuggestion)
            ) : loading ? (
              <SuggestionContainer disabled>
                <LoadingIcon className="icon-spin" />
                <Text>Loading...</Text>
              </SuggestionContainer>
            ) : (
              <SuggestionContainer disabled>
                <Text>No suggestions found. Please try another keyword</Text>
              </SuggestionContainer>
            )}
          </SuggestionsContainer>
        )}
        {selectedSuggestions.length > 0 && (
          <SelectedItemsContainer>
            {selectedSuggestions.map(renderSelectedSuggestion)}
          </SelectedItemsContainer>
        )}
        <InputContainer>
          <InputBox
            valueLink={chatMessageLink}
            type={numeric ? 'tel' : 'text'}
            className="visit-input"
            placeholder="Type your message here"
          />
          <InputTappable
            onTap={this.sendMessage}
            disabled={chatMessage.trim().length === 0}
          >
            <SendIcon active={chatMessage.trim().length !== 0} />
          </InputTappable>
        </InputContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = state => ({
  authToken: state.app.authToken,
});

export default connect(mapStateToProps)(LookupInput);
