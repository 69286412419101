import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/common/Header';
import PsychologistCard from '../../images/wellness-management/consult-psychologist.png';
import PodcastCard from '../../images/wellness-management/mindfulness-podcast.png';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Card = styled.button`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  text-align: left;
  width: 100%;
`;

const Container = styled.div`
  height: 92vh;
`;

const WellnessManagement = () => {
  const history = useHistory();
  const { authToken } = useSelector((state) => state.user);

  function navigateBack () {
    if (window?.Android?.closeView) {
      window.Android.closeView(true);
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'closeView',
          tataUser: true,
        })
      );
    } else {
      history.goBack();
    }
  }

  return (
    <div className="h-full w-full bg-textbox">
      <Header
        title="Stress Management Program"
        onTap={navigateBack}
      ></Header>
      <Container className="overflow-y-auto">
        <Section
          onClick={() => history.push('/health-content/podcasts-home')}
          heading="Content for your Mental Wellbeing"
        >
          <img src={PodcastCard} alt="podcast-card" />
        </Section>
        <Section
          heading="Connect with a Specialist"
          onClick={() => {
            history.push('/consultation/doctor-select', {
              verticalId: 1,
              showVerticals: false,
              showSearch: false,
            });
          }}
        >
          <img src={PsychologistCard} alt="psychologist-card" />
        </Section>
      </Container>
    </div>
  );
};

const Section = ({ heading, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="p-20px px-24px border-b border-txtlight-shades4 flex flex-col justify-start w-full"
    >
      <p className="text-body-l font-bold">{heading}</p>
      <div className="mt-12px w-full">{children}</div>
    </button>
  );
};

export default WellnessManagement;
