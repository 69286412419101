import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import PageIcon from '../../images/health-content/page-icon.svg';
import ArrowRight from '../../images/health-content/arrow-right.svg';
import WorkoutCard from './WorkoutCard';
import { getWeeklyPlan } from '../../services/health-content';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const OuterContainer = styled.div``;

export default function WeeklyPlan() {
  const history = useHistory();
  const { authToken } = useSelector((state) => state.user);
  const [plan, setPlan] = useState([]);

  function navigateBack() {
    history.goBack();
  }

  const getPlan = async () => {
    const data = await getWeeklyPlan(authToken);
    setPlan(data.planDetails.timeLine);
  };

  useEffect(() => {
    getPlan();
  }, []);

  return (
    <OuterContainer className="w-100 h-screen flex flex-col bg-textbox">
      <div className="border-bottom w-100 text-txtlight text-title-m font-semibold flex mt-20 p-15">
        <img src={PageIcon} alt="page" className="mr-2" />
        Your workout plan
      </div>

      <div className="p-20 pt-0 overflow-y-auto">
        {plan.map((val, index) => (
          <div>
            <h2 className="text-body-l font-semibold my-2">Week {index + 1}</h2>
            <WorkoutCard
              index={index}
              heading={val.title}
              label={val.label}
              targets={val.targets}
            />
          </div>
        ))}
      </div>

      <div className="p-20 mb-52px">
        <button
          className="bg-primary w-100 text-textbox text-body-l font-semibold rounded-xl py-4"
          onClick={navigateBack}
        >
          Go back&nbsp;
          <span className="bg-textbox px-1.5 rounded-full">
            <img
              src={ArrowRight}
              alt="arrow-right"
              className="bg-textbox inline mb-1"
              style={{ transform: 'scale(-1, 1)' }}
            />
          </span>
        </button>
      </div>
    </OuterContainer>
  );
}
