import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import {
  BoldText,
  ButtonText,
  LinkText,
  RegularText,
  Text,
} from '../../components/common/Text';
import config from '../../config';
import { PrimaryButton } from '../../components/common/Buttons';

import hraIcon from '../../images/hra/hraicon-1.png';
import completeIcon from '../../images/hra/completeicon.png';
import { fontSize, showError } from '../../utils';
import StyledTappable from '../../components/common/StyledTappable';
import { getHraPdfUrl, resetHra } from '../../services';
import TataButton from '../../components/common/TataButton';
import axios from 'axios';
import LoadingComponent from '../../components/common/LoadingComponent';
import { updatePdfLink } from '../../actions';
import SurveyModal from '../consultation/SurveyModal';
import FeedBack from '../../images/feedback.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Image = styled.img`
  max-width: 100px;
  margin: 20px 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled(BoldText)`
  font-size: ${fontSize(2)};
`;

const CaptionText = styled(RegularText)`
  margin-top: 12px;
  font-size: ${fontSize(0.75)};
`;

const RetakeLink = styled(LinkText)`
  font-size: ${fontSize(0.875)};
  margin-top: 10px;
`;

const NewButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #303e4f;
  box-shadow: 1px 1px 0px 0px #1c46ad;
  border-radius: 23px;
`;

const EndScreen = ({
  history,
  userId,
  sponsorId,
  authToken,
  googleFitPermission,
  dispatch,
  updatedPdfLink,
  moduleName,
  mobileNumber,
  policyNumber,
}) => {
  const [showFitnessButton, setShowFitnessButton] = useState(false);
  const [iosUser, setIosUser] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [atOnce, setAtOnce] = useState(false);
  const location = useLocation();

  console.log('location is ', location);
  if (
    location &&
    location.state &&
    location.state.showSurvey === true &&
    atOnce === false
  ) {
    setAtOnce(true);
    setShowSurveyModal(true);
  }
  useEffect(() => {
    if (window.Android !== undefined && window.Android.inHraEndPage) {
      window.Android.inHraEndPage();
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'inHraEndPage',
        })
      );
    }
    window.showConnectToGoogleFit = (value) => {
      setShowFitnessButton(value);
    };
    window.isIosUser = (value) => {
      setIosUser(value);
    };

    if (!updatedPdfLink) {
      getHraReportPdf();
    } else {
      setLoading(false);
    }
  }, []);
  const hideSurveyModal = () => {
    console.log('survey modal is closed');
    setShowSurveyModal(false);
    const editedModuleName = `${moduleName}_vas_visit`;
    const type = 'Booking';
    const url = `${config.surveyUrl}&mobileno=${mobileNumber}&policyno=${policyNumber}&feature=${editedModuleName}&type=${type}`;

    if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'openExternalLink',
          link: url,
        })
      );
    } else if (window.Android) {
      window.Android.openExternalLink(url);
    } else {
      window.open(url);
    }
  };
  const closeModal = () => {
    setShowSurveyModal(false);
  };
  const getHraReportPdf = () => {
    let gotLink = false;
    const pull = setInterval(() => {
      if (!gotLink) {
        getHraPdfUrl()
          .then((result) => {
            if (result.message === 'success') {
              gotLink = true;
              dispatch(updatePdfLink(result.url));
              setPdfLink(result.url);
              setLoading(false);
              console.log(result.url);
            }
          })
          .catch((err) => {
            console.log(err);
            showError(err);
            clearInterval(pull);
          });
      } else {
        clearInterval(pull);
      }
    }, 3000);
  };

  const linkRef = useRef();
  return (
    <OuterContainer className="font-pop">
      <BodyContainer className="p-32px">
        <img
          src={hraIcon}
          className="mb-24px"
          style={{ height: '184px', width: '184px' }}
        />

        <ColumnContainer className="font-pop">
          <p className="text-title-xl font-bold" style={{ color: '#0b2f5e' }}>
            Health Risk Assessment
          </p>
          <Image src={completeIcon} />
          <p className="text-body-l mt-16x font-medium text-txtlight">
            You have completed your Health Risk Assessment successfully.
          </p>
        </ColumnContainer>
        <ColumnContainer>
          {loading ? (
            <>
              <LoadingComponent />{' '}
            </>
          ) : (
            <TataButton
              onTap={() => {
                const userAgent = navigator.userAgent || navigator.vendor;
                if (window.Android && window.Android.downloadHraLink) {
                  window.Android.downloadHraLink(updatedPdfLink);
                } else if (
                  window.webkit &&
                  window.webkit.messageHandlers.visitIosView
                ) {
                  window.webkit.messageHandlers.visitIosView.postMessage(
                    JSON.stringify({
                      method: 'openPDF',
                      url: updatedPdfLink,
                    })
                  );
                } else if (/android/i.test(userAgent)) {
                  const anchor = document.createElement('a');
                  anchor.setAttribute(
                    'href',
                    `${
                      config.apiBaseUrl
                    }/health-risk-assessment/${userId}/form/generate?auth=${
                      authToken.split(' ')[1]
                    }`
                  );
                  anchor.setAttribute('target', '_blank');
                  anchor.click();
                  return;
                } else {
                  const anchor = document.createElement('a');
                  anchor.setAttribute('href', updatedPdfLink);
                  // anchor.setAttribute('downlaod', 'hra_report.pdf');
                  anchor.setAttribute('target', '_blank');
                  anchor.click();
                }
              }}
              buttonText={'Download Report'}
              enabled={true}
              padding={'10px 14px 10px 14px'}
            >
              <a ref={linkRef}></a>
            </TataButton>
          )}

          <TataButton
            className={'m-12px'}
            onTap={() => history.push('/stay-active')}
            buttonText={`Connect to ${iosUser ? 'Health Kit' : 'Google Fit'}`}
            enabled={true}
            padding={'10px 14px 10px 14px'}
          ></TataButton>

          <StyledTappable
            onTap={() => {
              resetHra()
                .then(() => {
                  history.replace('/hra/question');
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            <RetakeLink style={{ color: '#0b2f5e' }}>
              ↺ Take HRA again
            </RetakeLink>
          </StyledTappable>
          <p className="text-body-l mt-16x font-medium text-txtlight">
            *A PDF file of the report has been sent to your registered email
            address
          </p>
        </ColumnContainer>
        {showSurveyModal && (
          <SurveyModal
            hideModal={hideSurveyModal}
            onClose={closeModal}
            modalHeading={`How was your experience ?`}
            modalTitle={`Please rate our services. This would help us make your experience even better`}
            buttonText={'Share Feedback'}
            modalImg={FeedBack}
          />
        )}
      </BodyContainer>
    </OuterContainer>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  sponsorId: state.user.sponsorId,
  authToken: state.user.authToken,
  googleFitPermission: state.app.fitnessPermissionGranted,
  updatedPdfLink: state.user.updatedPdfLink,
  policyNumber: state.user.policyNumber,
  mobileNumber: state.user.userPhone,
  moduleName: state.app.moduleName,
});

export default connect(mapStateToProps)(EndScreen);
