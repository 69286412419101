import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/common/Header';
import OfflineCard from '../../images/op-benefits/offline-card.png';
import ElderlyOfflineCard from '../../images/op-benefits/elderly-offline.png';
import PharmacyCard from '../../images/op-benefits/pharmacy-card.png';
import LabsCard from '../../images/op-benefits/labs-card.png';

import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

const Card = styled.button`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  text-align: left;
  width: 100%;
`;

const Container = styled.div`
  height: 92vh;
`;

const OPBenefits = (props) => {
  const history = useHistory();
  const { authToken } = useSelector((state) => state.user);

  function navigateBack() {
    if (window?.Android?.closeView) {
      window.Android.closeView(true);
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'closeView',
          tataUser: true,
        })
      );
    } else {
      history.goBack();
    }
  }

  return (
    <div className="h-full w-full bg-textbox">
      <Header title="Discounted Services" onTap={navigateBack}></Header>
      <Container className="overflow-y-auto pb-52px">
        <Section
          heading="Offline Consultations"
          onClick={() => {
            props.dispatch({
              type: 'SET_FREE_VERTICALS',
              payload: false,
            });
            history.push('/consultation/preview');
          }}
        >
          <img src={OfflineCard} alt="offline-card" />
        </Section>
        <Section
          heading="Pharmacy Services"
          onClick={() => {
            history.push('/pharmacy');
          }}
        >
          <img src={PharmacyCard} alt="pharmacy-card" />
        </Section>
        <Section
          heading="Lab tests"
          onClick={() => {
            history.push('/lab-test');
          }}
        >
          <img src={LabsCard} alt="labs-card" />
        </Section>
      </Container>
    </div>
  );
};

const Section = ({ heading, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="p-20px px-24px border-b border-txtlight-shades4 flex flex-col justify-start w-full"
    >
      <p className="text-body-l font-bold">{heading}</p>
      <div className="mt-12px w-full">{children}</div>
    </button>
  );
};

const mapStateToProps = (state) => ({
  elderlyPlan: state.user.elderlyPlan,
});

export default connect(mapStateToProps)(OPBenefits);
