import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import { FaArrowLeft, FaSearch, FaTimes } from 'react-icons/fa';

import { ButtonText, RegularText, Text } from '../../components/common/Text';
import TappableCard from '../../components/common/TappableCard';
import StyledTappable from '../../components/common/StyledTappable';
import LoadingComponent from '../../components/common/LoadingComponent';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import { fontSize, debounce } from '../../utils';
import { getDishSuggestions, saveMeal } from '../../services';

import mealIcon from '../../images/meal-log/meallogiconblack.png';
import mealCount from '../../images/meal-log/mealnumber.png';
import AddMealContainer from './AddMealContainer';
import { PrimaryButton } from '../../components/common/Buttons';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  background: #ffffff;
`;

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background: #56c02c;
  padding: 10px;
`;

const HeaderTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButton = styled(PrimaryButton)`
  background: ${(props) => (props.active ? 'white' : 'rgba(255,255,255,0.1)')};
  border-radius: 38px;
  padding: 8px 24px;
`;

const SaveText = styled(ButtonText)`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  color: ${(props) => (props.active ? '#42cc3b' : 'white')};
`;

const BackContainer = styled(StyledTappable)`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: 14px;
  color: white;
`;

const HeaderText = styled(Text)`
  font-size: 14px;
  color: white;
`;

const SearchBoxContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  background: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  padding: 0.625rem 0.75rem;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  margin-left: 1rem;
`;

const SelectedDishesTitleText = styled(RegularText)`
  opacity: 0.5;
  margin: 10px 0;
  color: white;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const BodyTitleText = styled(Text)`
  font-size: 14px;
  color: #7e7e7e;
  margin: 8px 0;
`;

const MealItemContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
`;

const MealIcon = styled.img`
  width: 16px;
  margin-right: 20px;
`;

const MealText = styled(Text)`
  flex: 1;
  font-size: 14px;
  color: #363636;
  align-self: stretch;
  padding: 14px 0;
  border-bottom: 1px solid RGBA(71, 71, 71, 0.4);
`;

const SelectedDishesContainer = styled.div`
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin-right: -10px;
  margin-left: -10px;
  & > :first-child {
    margin-left: 10px;
  }
  & > :last-child {
    margin-right: 10px;
  }
`;

const SelectedDishCard = styled(TappableCard)`
  background: white;
  max-width: 80%;
  flex-direction: column;
  align-items: stretch;
  margin: 0 5px;
  padding: 8px;
  border-radius: 12px;
`;

const SelectedDishTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectedDishTitle = styled(MealText)`
  padding: 0;
  margin-bottom: 10px;
  border: none;
  word-break: break-word;
`;

const CloseContainer = styled(StyledTappable)`
  margin-left: 6px;
  padding: 6px;
  padding-top: 0;
`;

const RemoveIcon = styled(FaTimes)`
  opacity: 0.5;
  font-size: 11px;
`;

const SelectedDishMeta = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedDishInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const SelectedDishMetaIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const SelectedDishMetaText = styled(Text)`
  font-size: 12px;
  color: #808080;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

class LogMealContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: true,
      results: [],
      showModal: false,
      addDishVisible: false,
      selectedDish: {
        id: -1,
        name: '',
        amount: 0,
        calories: 0,
        protein: 0,
        proteinRatio: 0,
        carbs: 0,
        carbsRatio: 0,
        fats: 0,
        fatsRatio: 0,
      },
      selectedDishes: [],
      searchText: '',
    };
    const { state } = props.location;
    const today = new Date();
    today.setHours(0, 0, 0);
    this.dayTimestamp = (state && state.dayTimestamp) || today.valueOf();
    this.mealType = (state && state.mealType) || 'Breakfast';
    this.debounceSearch = debounce((val) => this.getDishSuggestions(val), 800);
  }

  componentDidMount() {
    this.getDefaultSuggestions();
    logEvent('Meal Tracking Select Meal Dish Screen');
  }

  getDefaultSuggestions() {
    this.getDishSuggestions('common_suggestions').then((res) => {
      this.defaultDishes = res.suggestions;
    });
  }

  getDishSuggestions(searchText) {
    const { authToken } = this.props;
    this.setState({
      searching: true,
    });
    return getDishSuggestions(searchText, 'breakfast', authToken)
      .then((res) => {
        this.setState({
          searching: false,
          results: res.suggestions,
        });
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  selectDish(dish) {
    this.setState({
      showModal: true,
      addDishVisible: true,
      selectedDish: {
        id: dish.id,
        name: dish.name,
        amount: dish.baseAmount,
        calories: dish.calories,
        protein: dish.protein,
        proteinRatio: dish.proteinsRatio,
        carbs: dish.carbohydrates,
        carbsRatio: dish.carbohydratesRatio,
        fats: dish.fat,
        fatsRatio: dish.fatsRatio,
        measureUnit: dish.measureUnit,
      },
    });
  }

  addDish = (quantity) => {
    const { selectedDish, selectedDishes } = this.state;
    const findIndex = selectedDishes.findIndex(
      (ele) => ele.id === selectedDish.id
    );
    console.log(selectedDish, quantity);
    if (findIndex === -1) {
      this.setState({
        selectedDishes: selectedDishes.concat({
          ...selectedDish,
          selectedCount: quantity,
        }),
      });
    } else {
      const updatedSelectedDishes = Array.from(selectedDishes);
      updatedSelectedDishes[findIndex].selectedCount += quantity;
      this.setState({
        selectedDishes: updatedSelectedDishes,
      });
    }
    this.setState({
      searchText: '',
    });
    this.onModalClose();
  };

  removeDish = (dishId) => {
    logEvent('Meal Tracking Remove');
    const { selectedDishes } = this.state;
    const findIndex = selectedDishes.findIndex((ele) => ele.id === dishId);
    if (findIndex !== -1) {
      const updatedSelectedDishes = Array.from(selectedDishes);
      updatedSelectedDishes.splice(findIndex, 1);
      this.setState({
        selectedDishes: updatedSelectedDishes,
      });
    }
  };

  saveMeal = () => {
    const { dayTimestamp, mealType } = this;
    const { selectedDishes } = this.state;
    const { authToken, history } = this.props;
    const dishes = selectedDishes.map((ele) => ({
      dishId: ele.id.toString(),
      quantity: ele.selectedCount,
    }));
    return saveMeal(dayTimestamp, dishes, mealType, authToken)
      .then(() => {
        history.goBack();
        logEvent('Meal Tracking Logged');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onModalClose = () => {
    this.setState({
      addDishVisible: false,
    });
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { history } = this.props;
    const {
      searching,
      results,
      searchText,
      selectedDishes,
      showModal,
      addDishVisible,
      selectedDish,
    } = this.state;
    const searchTextLink = Link.state(this, 'searchText').onChange((val) => {
      if (val.length === 0) {
        this.setState({
          searching: false,
          results: this.defaultDishes,
        });
        return;
      }
      this.setState({
        results: [],
      });
      if (val.length >= 3) {
        this.debounceSearch(val);
      }
    });
    const saveButtonActive = selectedDishes.length !== 0;
    const renderMealResult = (ele) => {
      return (
        <MealItemContainer key={ele.id} onTap={() => this.selectDish(ele)}>
          <MealIcon src={mealIcon} />
          <MealText>{ele.name}</MealText>
        </MealItemContainer>
      );
    };
    const renderSelectedDish = (ele, idx) => {
      return (
        <SelectedDishCard key={idx}>
          <SelectedDishTitleContainer>
            <SelectedDishTitle>{ele.name}</SelectedDishTitle>
            <CloseContainer onTap={() => this.removeDish(ele.id)}>
              <RemoveIcon />
            </CloseContainer>
          </SelectedDishTitleContainer>
          <SelectedDishMeta>
            <SelectedDishInfoContainer>
              <SelectedDishMetaIcon src={mealCount} />
              <SelectedDishMetaText>{`${ele.selectedCount} ${ele.measureUnit}`}</SelectedDishMetaText>
            </SelectedDishInfoContainer>
            <SelectedDishInfoContainer>
              <SelectedDishMetaIcon src={mealIcon} />
              <SelectedDishMetaText>{`${ele.calories} Cals`}</SelectedDishMetaText>
            </SelectedDishInfoContainer>
          </SelectedDishMeta>
        </SelectedDishCard>
      );
    };
    return (
      <OuterContainer>
        <HeaderContainer>
          <HeaderTopContainer>
            <HeaderTitleContainer>
              <BackContainer onTap={history.goBack}>
                <StyledArrow />
              </BackContainer>
              <HeaderText>{`Logging ${this.mealType}`}</HeaderText>
            </HeaderTitleContainer>
            <SaveButton
              active={saveButtonActive}
              disabled={!saveButtonActive}
              onTap={this.saveMeal}
            >
              <SaveText active={saveButtonActive}>Save</SaveText>
            </SaveButton>
          </HeaderTopContainer>
          <SearchBoxContainer>
            <FaSearch />
            <StyledInputBox
              valueLink={searchTextLink}
              className="search-input"
              placeholder="Search for meal items"
            />
          </SearchBoxContainer>
          {selectedDishes.length === 0 ? (
            <SelectedDishesTitleText>
              No foods added yet
            </SelectedDishesTitleText>
          ) : (
            <SelectedDishesContainer>
              {selectedDishes.map(renderSelectedDish)}
            </SelectedDishesContainer>
          )}
        </HeaderContainer>
        <BodyContainer>
          {searching ? (
            <LoadingComponent />
          ) : (
            <>
              <BodyTitleText>
                {searchText.length > 0
                  ? searchText.length < 3
                    ? 'Continue typing to get suggestions'
                    : 'Search Results'
                  : 'Commonly Searched'}
              </BodyTitleText>
              {results.length === 0 ? (
                <div>No result for the search term</div>
              ) : (
                results.map(renderMealResult)
              )}
            </>
          )}
        </BodyContainer>
        {showModal && (
          <Modal>
            <div className="modalContainer" onClick={this.handleOutsideClick}>
              <FlyInTransition
                in={addDishVisible}
                mountOnEnter
                unmountOnExit
                onExited={this.hideModal}
                appear
              >
                <AddMealContainer
                  dish={selectedDish}
                  onClose={this.onModalClose}
                  onAdd={this.addDish}
                  setRef={(node) => (this.node = node)}
                  mealType={this.mealType}
                />
              </FlyInTransition>
            </div>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(LogMealContainer);
