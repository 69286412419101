import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LoadingComponent from '../../components/common/LoadingComponent';
import {
  getAllPodcastsByAlbumId,
  updateLikeStatus,
} from '../../services/health-content';
import DownIcon from '../../images/health-content/podcasts/down-button.svg';
import PlayIcon from '../../images/health-content/podcasts/play-button.svg';
import BulletIcon from '../../images/health-content/circle-small.svg';
import { FlyInTransition } from '../../components/transitions/transitions';
import Modal from '../../containers/common/Modal';
import Player from './PodcastPlayer';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  background: white;
`;

const ImageHeader = styled.div`
  height: 324px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  position: relative;
  object-fit: cover;
`;

const BodyContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const AlbumHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #413958;
`;

const By = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #a5a0b3;
`;

const CoachName = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #676179;
  line-height: 12px;
  margin-left: 4px;
`;

const SubHeaders = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  color: #413958;
  padding-bottom: 8px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #413958;
  margin-top: 12px;
`;

const SessionCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SessionCount = styled.div`
  font-weight: 600;
  font-size: 8px;
  display: flex;
  align-items: center;
  padding: 3px 8px 3px 8px;
  background: #edf0ff;
  color: #5a68e7;
  margin-left: 6px;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const SessionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
`;

const SessionVideoPreview = styled.img`
  width: 72px;
  height: 48px;
  position: relative;
  border-radius: 8px;
`;

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

class PodcastAlbumPage extends Component {
  constructor(props) {
    super(props);
    console.log(props, 'heyyy');
    this.state = {
      loading: false,
      albumId: null,
      breifData: null,
      showPlayerModal: false,
      selectedAlbum: null,
      likeStatusArray: [],
    };
    const { state } = props.location;
    if (state) {
      this.state.albumId = state.albumId;
      this.state.loading = true;
    }
  }
  componentDidMount() {
    this.getPodcastsUnderAlbums();
  }

  generateLikeStatusArray = (sessions) => {
    const data = [];
    sessions.map((session) => {
      data.push({
        sessionId: session.sessionId,
        likedStatus: session.likedStatus,
      });
    });
    this.setState({ likeStatusArray: data });
  };

  getPodcastsUnderAlbums() {
    getAllPodcastsByAlbumId(this.props.authToken, this.state.albumId)
      .then((res) => {
        console.log(res, 'the sub podcasts under album');
        this.setState({ breifData: res.albumDetails });
        this.generateLikeStatusArray(res.albumDetails.sessions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  playSession = (album) => {
    console.log(album);
    this.setState({ selectedAlbum: album, showPlayerModal: true });
  };

  hideModal = () => {
    this.setState({ showPlayerModal: false });
  };

  handleLikeStatus = (session) => {
    const status = this.state.likeStatusArray
      .filter((val) => val.sessionId === session.sessionId)
      .shift();
    const likeStatus = status.likedStatus;
    updateLikeStatus(this.props.authToken, status.sessionId, !likeStatus).then(
      (res) => {
        console.log(res);
        const beforeStatusArray = this.state.likeStatusArray;
        const updatedStatusArray = beforeStatusArray.map((val) =>
          val.sessionId === status.sessionId
            ? { ...val, likedStatus: !likeStatus }
            : val
        );
        this.setState({ likeStatusArray: updatedStatusArray });
      }
    );
  };

  render() {
    const { breifData, loading, showPlayerModal, selectedAlbum } = this.state;
    if (loading) {
      return <LoadingComponent />;
    }

    const renderSubAlbums = (sessions) => {
      const bannerPlace = Math.round(sessions.length / 2);
      return sessions.map((session, idx) => {
        const status = this.state.likeStatusArray
          .filter((val) => val.sessionId === session.sessionId)
          .shift();
        return (
          <div>
            <SessionContainer>
              <div className="flex flex-row">
                <div
                  className="relative"
                  style={{ width: '72px', height: '48px' }}
                >
                  <SessionVideoPreview src={session.thumbnailUrl} />
                  <img
                    src={PlayIcon}
                    style={{
                      width: '24px',
                      height: '24px',
                      left: '30%',
                      top: '25%',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.playSession(session)}
                    className="absolute"
                  />
                </div>
                <div>
                  <SubHeaders
                    style={{
                      margin: 0,
                      padding: 0,
                      marginLeft: '14px',
                    }}
                  >
                    {session.title}
                  </SubHeaders>
                  <Description
                    style={{
                      marginTop: '1px',
                      marginLeft: '14px',
                      fontSize: '10px',
                    }}
                  >
                    {session.duration}
                  </Description>
                </div>
              </div>
              <div>
                <button
                  className="heart"
                  style={
                    status.likedStatus
                      ? { background: 'red' }
                      : { background: 'grey' }
                  }
                  onClick={() => this.handleLikeStatus(session)}
                ></button>
              </div>
            </SessionContainer>
            <Description
              className="pb-16px"
              style={
                idx !== sessions.length - 1 || bannerPlace === idx - 1
                  ? {
                      borderBottom: '1px solid #F5F5F5',
                    }
                  : {
                      borderBottom: 'none',
                    }
              }
            >
              {session.description}
            </Description>
            {bannerPlace === idx && (
              <div
                style={{
                  width: '90%',
                  border: '1px solid #F5F5F5',
                  marginTop: '16px',
                  marginLeft: '5%',
                  borderRadius: '8px',
                }}
              >
                <img
                  src={breifData.albumSponsorUrl}
                  style={{ height: '90px', borderRadius: '8px' }}
                />
              </div>
            )}
          </div>
        );
      });
    };
    return (
      <OuterContainer>
        <BodyContainer>
          <ImageHeader image={breifData.imageUrl}>
            <button
              className="absolute left-6 top-12"
              onClick={this.props.history.goBack}
            >
              <img src={DownIcon} style={{ width: '32px', height: '32px' }} />
            </button>
          </ImageHeader>
          <div style={{ padding: '24px', paddingBottom: '0' }}>
            <div className="pb-4" style={{ borderBottom: '1px solid #F8F8F8' }}>
              <AlbumHeader>{breifData.title}</AlbumHeader>
              <div className="flex flex-row mt-4px">
                <By>By</By>
                <CoachName>{breifData.coachName}</CoachName>
              </div>
            </div>
            <div className="pt-4">
              <SubHeaders>About the program</SubHeaders>
              <Description>{breifData.description}</Description>
            </div>
            {breifData.benefits && breifData.benefits.length > 0 && (
              <div className="pt-6">
                <SubHeaders>Benefits</SubHeaders>
                <div className="mt-9px">
                  {breifData.benefits.map((benefit) => {
                    return (
                      <div className="flex items-center">
                        <img
                          src={BulletIcon}
                          alt="bullet"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginTop: '0.5px',
                          }}
                        />
                        <Description style={{ marginTop: '3px' }}>
                          {' '}
                          {benefit}
                        </Description>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              className="pt-6"
              style={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: '1px solid #f8f8f8',
              }}
            >
              <SubHeaders>Sessions</SubHeaders>
              <SessionCount>{breifData.sessionsCount} sessions</SessionCount>
            </div>
            <div>{renderSubAlbums(breifData.sessions)}</div>
          </div>

          <div
            className="py-8 flex items-center justify-center"
            style={{ borderTop: '1px solid #f8f8f8' }}
          >
            <SubHeaders
              style={{ cursor: 'pointer' }}
              className="text-primary"
              onClick={() =>
                this.props.history.push('/health-content/podcasts-home')
              }
            >
              Check out other workout plans
            </SubHeaders>
          </div>
          {showPlayerModal && (
            <Modal>
              <ModalContainer onClick={this.hideModal}>
                <FlyInTransition
                  in={showPlayerModal}
                  mountOnEnter
                  unmountOnExit
                  appear
                >
                  <Player
                    album={selectedAlbum}
                    authToken={this.props.authToken}
                    hideModal={this.hideModal}
                    albumSponsorUrl={breifData.albumSponsorUrl}
                  />
                </FlyInTransition>
              </ModalContainer>
            </Modal>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(PodcastAlbumPage);
